import { useCallback, useEffect, useState } from "react";
import Card from 'react-bootstrap/esm/Card';
import Container from 'react-bootstrap/esm/Container';

import { ISalesForceStreamingClientInformation } from "../../api/domain/client-information";
import { baseUri } from "../../api/fetch";
import { getClientInformation, getMessages, getModules } from "../../api/logger";
import { IDropdown } from "../../core/idropdown";
import { InstanceColor } from "../../core/streaming-log/instance-color";

import { OrderFormDropdown } from '@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form-dropdown';
import { UserSignInOrDisplaySignedIn } from '@plazarre/phoenix-ux-order/lib/esm/ux/molecules/user-sign-in-or-display-signed-in';

const instanceColorArray : InstanceColor[] = [];
const colorsArray : string[] = [ 'red', 'blue', 'orange', 'black', 'green', 'pink'];

const messagesMap = ((object: any, value : number) => {
    const keyValue = `${object.name}${object.id}`;
    const insertDateTime = new Date(object.insertDate);
    const insertDateTimeString = `${insertDateTime.toLocaleDateString()} ${insertDateTime.toLocaleTimeString()}`;
    const instanceId = (object.instanceId ?? 'XXXX').slice(-4);
    let instanceColor = instanceColorArray.findIndex(e => e.instanceId === instanceId);
    if (instanceColor === -1) {
        instanceColorArray.push(new InstanceColor(instanceId, colorsArray[instanceColorArray.length-1]));
        instanceColor = instanceColorArray.length;
    }

    return <div key={ keyValue }>
        <b style={{color: colorsArray[instanceColor]}}>
            {insertDateTimeString} [{instanceId}]
        </b> ({object.name}):
        &nbsp;
        {
            object.message
        }
           </div>;
        
});

const getChannel = (message: string) : string => {
    let channel = '';
    const startIndex = message.indexOf(',"channel":"');
    if (startIndex >= 0) {
        channel = message.substring(startIndex + 12);
        const endIndex = channel.indexOf('"');
        if (endIndex >= 0) {
            channel = channel.substring(0, endIndex);
        }
    }

    return channel;
}

const getReplayId = (message: string): string => {
    let replayId = '';
    const startIndex = message.indexOf('"replayId":');
    if (startIndex >= 0) {
        replayId = message.substring(startIndex + 11);
        const endIndex = replayId.indexOf(',');
        if (endIndex >= 0) {
            replayId = replayId.substring(0, endIndex);
        }
    }

    return replayId;
}

const mapModuleToDropdown = (name: string, index: number) : IDropdown => {
    return { index, name };
}

const streamMessagesMap = ((object: any, value: number) => {
    console.log(object);

    const message : string = object.message;
    const replayId = getReplayId(message);
    const channel = getChannel(message);
    const headerValue = replayId ? `${channel} : ${replayId}` : '';

    const keyValue = `message${object.id}`;
    return <Card key={keyValue}>
        <Card.Header>{headerValue}</Card.Header>
            {object.message}
        </Card>;

});

export interface IStreamLogProps {
};

export const StreamingLogPage = (props: IStreamLogProps): JSX.Element => {
    const [clientInformation, setClientInformation] = useState<ISalesForceStreamingClientInformation[] | null>();
    const [messages, setMessages] = useState<Array<object> | null>();
    const [moduleValues, setModuleValues] = useState<string[]>([]);
    const [moduleName, setModuleName] = useState('');
    const [updateCounter, setUpdateCounter] = useState(0);
    
    const getMessagesCallback = useCallback(async () =>
    {
        const messagesResult = await getMessages();
        if (messagesResult.isSuccess) {
            setMessages(messagesResult.value);
        }
    }, []);

    useEffect(() => {
        // Only get the client information if not already set
        if (clientInformation) {
            return;
        }

        const getClientInformationCallback = async () => 
        {
            const clientInfoResult = await getClientInformation();
            if (clientInfoResult.isSuccess) {
                setClientInformation(clientInfoResult.value);
            }
        };

        const getModulesCallback = async () => 
        {
            const modulesResult = await getModules();
            if (modulesResult.isSuccess) {
                const modules = modulesResult.value as string[]; 
                setModuleValues(modules);
    
                if (!moduleName) {
                    setModuleName(modules[0]);
                }
            }
        };
    
        getClientInformationCallback();
        getModulesCallback();
    }, [clientInformation, moduleName]);

    useEffect(() => {
        const timeoutFunction = async () => {
            await getMessagesCallback();
            setUpdateCounter(updateCounter + 1);
        }
        
        const timeout = setTimeout(timeoutFunction, 2000);
        return () => {
            clearTimeout(timeout);
        };
    }, [updateCounter, getMessagesCallback]);

    const isStreamListenerModule = moduleName === 'webapi.Services.StreamListener';
    // let messagesJsx : JSX.Element[] = [<div>Please wait loading...</div>];
    //if (moduleName === messageModule) {
    const messagesJsx = messages ? messages.map(isStreamListenerModule ? streamMessagesMap : messagesMap) : [<div key="loading">Please wait loading...</div>];
    //}

    let modules: IDropdown[] = [];
    if (moduleValues.length > 0) {
        modules = moduleValues.map(mapModuleToDropdown);
    }

    const isClientInformation = clientInformation && clientInformation[0];
    let clientInformationJsx: JSX.Element[] = [];
    if (isClientInformation) {
        const { info } = clientInformation[0];
        Object.entries(info.topics).forEach((value) => {
            clientInformationJsx.push(
                <div key={value[0]}>
                    {`${value[0]}`}
                </div>
            );
        });

        clientInformationJsx.push(<div>Base uri: {baseUri}</div>);
    }

    return (
        <Container>
            <UserSignInOrDisplaySignedIn />

            <div key="client-info">
                { clientInformationJsx }
            </div>
            <br/><br/>
            <OrderFormDropdown label="Modules" dropdownValues={modules} value={moduleName} colLg={3} setValue={setModuleName} />
            <div key="messages" style={{ textAlign: 'left' }}>
            {
                messagesJsx
            }
            </div>
        </Container>
        
        );
}
