import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { EDDMRoute } from "../../../core/maps/domain/eddm-route";
import { EDDM_ROUTES } from "../../../data/eddm/routes";

interface IMailingListEDDMProps {
}

export enum EDDMMessageType { 'unknown' = -1, 'eddm_routes_selected', 'eddm_route_mouseclick', 'eddm_mouseover', 'eddm_route_change' };
export interface EDDMMessage {
	messageType: EDDMMessageType,
	data: any,
}

let selectedRoutes : EDDMRoute[] = [];
let lastRoute : EDDMRoute | null = null;
let isClicked : boolean = false;
let googleMap : google.maps.Map | null = null;

const render = (status: Status): React.ReactElement => {
	if (status === Status.LOADING) return <h3>{status} ..</h3>;
	if (status === Status.FAILURE) return <h3>{status} ...</h3>;
	return <>Error</>
};

interface IGoogleMapProps {
	center: google.maps.LatLngLiteral,
	zoom: number,
};

enum RouteSelectedType { Selected, Unselected, Highlighted };
interface IRouteDrawAttributes {
	type: RouteSelectedType,
	strokeColor: string,
	strokeOpacity: number
}

const routeDrawAttributes : IRouteDrawAttributes[] = [
	{ type: RouteSelectedType.Selected, strokeColor: '#0000AA', strokeOpacity: 0.75 },
	{ type: RouteSelectedType.Unselected, strokeColor: '#777777', strokeOpacity: 0.4 },
	{ type: RouteSelectedType.Highlighted, strokeColor: '#FF0000', strokeOpacity: 1.0 }
];

const setRouteColor = (route: EDDMRoute, routeDrawAttributeName: RouteSelectedType) => {
	route.pathPolylines.forEach((pathPolyline) => {
		if (!pathPolyline || !pathPolyline.polyline) {
			console.error(`Error pathPolyline no found`);
			return;
		}

		const drawAttribute = routeDrawAttributes.find(e => e.type === routeDrawAttributeName);
		if (!drawAttribute) 
			throw new Error(`Error setRouteColor: unable to find draw attribute ${routeDrawAttributeName}`);
		
		pathPolyline.polyline.setOptions({ strokeColor: drawAttribute.strokeColor, strokeOpacity: drawAttribute.strokeOpacity });
	});
}

const MyMapComponent: React.FC<IGoogleMapProps> = (props) => {
	const { center, zoom } = props;
	const ref = useRef<HTMLDivElement | null>(null);
	

	// const [lastRoute, setLastRoute] = useState<IEDDMRoute | null>(null);
	//const [map, setMap] = useState<google.maps.Map | null>();
	
	// Must use isClicked as the basis for this or the map will keep refreshing
	const [isMouseClicked, setIsMouseClicked] = useState(isClicked);
	const [isInitializationComplete, setIsInitializationComplete] = useState(false);

	const onMouseOver = useCallback((route: EDDMRoute, eddmList: boolean) => {
		if (isClicked && !eddmList)
			return;

		if (lastRoute) {
			// Is last route in selected, if so, then skip
			const selectedRoute = selectedRoutes.find(e => e.zip === lastRoute?.zip && e.cridId === lastRoute?.cridId);
			const routeAttributeName = selectedRoute ? RouteSelectedType.Selected : RouteSelectedType.Unselected;
			setRouteColor(lastRoute, routeAttributeName);
		}

		if (route.pathPolylines.length === 0) {
			const routeHighlight = EDDM_ROUTES.find(e => e.zip === route.zip && e.cridId === route.cridId);
			if (!routeHighlight) {
				throw new Error('Error - unable to find polyline');
			}			

			// setRouteColor(routeHighlight, 'Highlighted');
			const clonedSelectedRoutes = selectedRoutes.map((clonedRoute) => { 
				return { ...clonedRoute, pathPolylines: [] }
			});


			const message: EDDMMessage = { messageType: EDDMMessageType.eddm_routes_selected, data: [ clonedSelectedRoutes, null, { ...routeHighlight, pathPolylines: [] } ] };
			window.postMessage(message);
			//return;
		} 


		setRouteColor(route, RouteSelectedType.Highlighted);
		const message: EDDMMessage = { messageType: EDDMMessageType.eddm_route_change, data: { ...route, pathPolylines: [] } };
		lastRoute = route;
		window.postMessage(message);
	}, []);
	
	const handleMessage = useCallback((event: any) => {
		const eddmRoutesSelectedMessageHandler = (event: any) => {
			let deletedRoute : EDDMRoute | null;
			let highlightedRoute : EDDMRoute | null;

			[ selectedRoutes, deletedRoute, highlightedRoute ] = event?.data?.data ?? [ [], null, null ];

			const selectedRoutesForUpdate = [...selectedRoutes];

			if (deletedRoute != null) {
				selectedRoutesForUpdate.push(deletedRoute);
			}

			selectedRoutesForUpdate.forEach((selectedRoute) => {
				const route = EDDM_ROUTES.find(e => e.zip === selectedRoute.zip && e.cridId === selectedRoute.cridId);
				if (!route) {
					return;
				}
	
				const selectedRouteIndex = selectedRoutes.findIndex(e => e.zip === selectedRoute.zip && e.cridId === selectedRoute.cridId);
				const isHighlightedRoute = selectedRoute.zip === highlightedRoute?.zip && selectedRoute.cridId === highlightedRoute?.cridId;
				const drawAttributeName = selectedRouteIndex >= 0 ? (isHighlightedRoute ? RouteSelectedType.Highlighted : RouteSelectedType.Selected) : RouteSelectedType.Unselected;
				setRouteColor(route, drawAttributeName);
			});
		}
	
		const mouseClickMessageHandler = (event: any) => {
			isClicked = !isClicked;
			setIsMouseClicked(isClicked);
		}

		//console.log(event?.);
		let messageType : EDDMMessageType = event?.data?.messageType ?? 'unknown';
		if (messageType === EDDMMessageType.unknown && event?.domEvent?.type === 'click') {
			messageType = EDDMMessageType.eddm_route_mouseclick;
		}

		if (messageType === EDDMMessageType.unknown) {
			return;
		}
			
		// Only accept value message types
		if (messageType === EDDMMessageType.eddm_routes_selected) {
			eddmRoutesSelectedMessageHandler(event);
			return;
		} else if (messageType === EDDMMessageType.eddm_route_mouseclick) {
			mouseClickMessageHandler(event);
		} else if (messageType === EDDMMessageType.eddm_mouseover) {
			const route = event?.data?.data;
			if (!route) {
				throw new Error('Error eddm_mouseover: unable to get route.');
			}

			onMouseOver(route, true);
		}
		
	}, [onMouseOver]);

	useEffect(() => {
		if (!isInitializationComplete)
			return;

		// Add the event listener
		if (googleMap) {
			googleMap.addListener("click", handleMessage);
			console.log('google map mouseclick event handler added.')
		}			
	}, [isInitializationComplete, handleMessage]);

	useEffect(() => {
		

        window.addEventListener("message", handleMessage, false);
        console.log('Added message listener for eddm_route_update.');
		setIsInitializationComplete(true);

        return () => {
            // clean up the event listener
            console.log('Removing message listener for eddm_route_update.');
            window.removeEventListener("message", handleMessage);
        };
    }, [handleMessage]);

	

	useEffect(() => {
		if (!ref || !ref.current)
			return;

		googleMap = new window.google.maps.Map(ref.current, {
			center,
			zoom,
			mapId: 'a5ab2d576dace302',
			streetViewControl: false,
			mapTypeControl: false,
			fullscreenControl: false
		});

		
		/*
		const src = 'https://amesgroupchicago-phoenixre-core31.azurewebsites.net/api/map/PostalCodeKML/60614';
		//const src = 'https://developers.google.com/maps/documentation/javascript/examples/kml/westcampus.kml';
		const kmlLayer = new google.maps.KmlLayer({
			
  			suppressInfoWindows: true,
  			preserveViewport: false,
  			map: googleMap,
			url: src,
			
			});

			setTimeout(() => {
				console.debug("metadata_changed", kmlLayer.getMetadata());
			}, 1000);
			
			google.maps.event.addListener(googleMap, "metadata_changed", (e : any) => {
				console.debug("metadata_changed", e);
			});

			kmlLayer.addListener("metadata_changed", (e: any) => {
				console.debug("metadata_changed", e);
			});

			// const metaData = kmlLayer.getMetadata();
			// console.log('metadata', metaData);
			// kmlLayer.setMap(googleMap);
			*/
		EDDM_ROUTES.forEach((route) => {
			route.pathPolylines.forEach((pathPolyline : any) => {
				const decodedPath = google.maps.geometry.encoding.decodePath(pathPolyline.path);
				const options: google.maps.PolylineOptions = {
					path: decodedPath,
					strokeColor: "#777777",
					strokeOpacity: 0.4,
					strokeWeight: 5,
				};
				pathPolyline.polyline = new google.maps.Polyline(options);

				pathPolyline.polyline.setMap(googleMap);
				pathPolyline.polyline.addListener('mouseover', (e: google.maps.MapMouseEvent) => onMouseOver(route, false));
				pathPolyline.polyline.addListener('click', handleMessage);
				// pathPolyline.polyline.addListener('mouseout', (e: google.maps.MapMouseEvent) => onMouseOut(route, e, index));
			});
		});

		// TODO: This is to outline zip codes and put in a label (11/8/2022 - pel)
		// Do we need this?
		//var decodedPath = google.maps.geometry.encoding.decodePath('}~kvHmzrr@ba\\hnc@jiu@r{Zqx~@hjp@pwEhnc@zhu@zflAbxn@fhjBvqHroaAgcnAp}gAeahAtqGkngAinc@_h|@r{Zad\\y|_D}_y@swg@ysg@}llBpoZqa{@xrw@~eBaaX}{uAero@uqGadY}nr@`dYs_NquNgbjAf{l@|yh@bfc@}nr@z}q@i|i@zgz@r{ZhjFr}gApob@ff}@laIsen@dgYhdPvbIren@'); 

		/*
		const priceTag = document.createElement('div');
  		priceTag.className = 'price-tag';
  		priceTag.textContent = '$2.5M';

  		const markerView = new window.google.maps.marker.AdvancedMarkerView({
    		map: googleMap,
    		position: { lat: 42.1051955, lng: -87.7360715  },
    		content: priceTag,
  			});
		*/
	  

	}, [center, zoom, onMouseOver, handleMessage]);



	return (
		<div>
			<div ref={ref} id="map" style={{ height: '55vh', width: '90%' }} />
			{isMouseClicked ? <span style={{color: 'red'}}><b>Route selection is locked. Click your mouse to unlock.</b></span> :
					<span style={{color: 'black'}}>Click your mouse to lock the route.</span>}
		</div>
	);
}

export const MailingListEDDM: React.FC<IMailingListEDDMProps> = (props) : JSX.Element => {
	const zoom = 14;

	const wrapper = useMemo(() => {
		return (
			<Wrapper key="wrapper1" apiKey="AIzaSyDMBGg-Uo-mxphquRRmtQxWNC0tNDKlKcQ" render={render} version="beta" libraries={['geometry', 'marker']}>
				<MyMapComponent key="map1" center={{ lat: 42.1051955, lng: -87.7360715 }} zoom={zoom} />
			</Wrapper>
		);
	}, []);
	return (
		wrapper 
	);
}


  


  