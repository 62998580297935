import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Card, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap"

import { Contact } from "../../core/postcard/domain/contact";

interface IAddressCardRemovedProps {
    contact: Contact,
    onClickRestore: (key: string) => void
};

export const AddressCardRemoved: React.FC<IAddressCardRemovedProps> = (props) => {
    const { contact, onClickRestore } = props;

    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
          Restore contact
        </Tooltip>
      );

    return (
        <Card key={contact.id} style={{ width: '20em' }}>
            <Card.Header style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Container fluid>
                    <Row className="align-items-center">
                        <Col xxl={10}>
                            {contact.name}
                        </Col>
                        <Col xxl={2}>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                                >

                                <Button 
                                    variant="outline-dark" 
                                    style={{ paddingLeft: 4, paddingRight: 4, paddingTop: 0, paddingBottom: 0 }}
                                    onClick={(e) => onClickRestore(contact.id)}
                                    >
                                    <FontAwesomeIcon color="darkgray" className="pull-right" icon="rotate-left" />
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                </Container>
            </Card.Header>
        </Card>
    );
}