import { useCallback } from "react";

import { OrderStepStateEnum } from "@plazarre/phoenix-ux-order/lib/esm/core/data-types/order-step-state-enum";
import { OrderButtons } from "@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-buttons";
import { applyStepStateAdjustment, OrderForm } from "@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form";

import { PostcardCustomOrder } from "../../core/postcard/domain/postcard-custom-order";
import { AddressCardRemoved } from "../molecules/address-card-removed";
import { MailingList } from "../organisms/mailing-list";
import { IPostCardCustomOrderProps } from "../pages/order-postcard";
import { Contact } from "../../core/postcard/domain/contact";
import { FetchCall } from "@plazarre/phoenix.ux.fetch/lib/esm/core/domain/fetch-call";

export const TemplateOrderPostCardAddresses : React.FC<IPostCardCustomOrderProps> = (props) => {
    const { id, order, setOrder, updateSlug } = props;

    const onClickRestore = (key: string) => {
        const index = order.removedContacts.findIndex(e => e.id === key) ?? -1;
        if (index === -1) {
            console.error(`Error MailingList: can not find item to restore: ${key}`);
        }

        const newRemovedContacts = [...order.removedContacts];
        newRemovedContacts.splice(index,1);
        newRemovedContacts.sort(Contact.sortContactByNameAsc);

        const newContacts = [...order.contacts]; 
        newContacts.push(order.removedContacts[index]);
        newContacts.sort(Contact.sortContactByNameAsc);
        updateContactsAndRemovedContacts(newContacts, newRemovedContacts);
    }

    const mapRemovedContactsToJsx = (contact: Contact) => {
        return (
            <AddressCardRemoved key={contact.id} contact={contact} onClickRestore={onClickRestore} />
        );
    }

    const setContactAddressIndex = useCallback((contactId: string, index: number) => {
        const newContacts = [...order.contacts]; 
        const contact = newContacts.find(e => e.id === contactId);
        if (contact == null) {
            throw new Error(`setContactAddressIndex Error: Unable to find contact id: ${contactId}`);
        }

        contact.selectedAddressIndex = index;
        const newOrder = JSON.parse(JSON.stringify(order)) as unknown as PostcardCustomOrder;
        newOrder.contacts = newContacts;
        setOrder(newOrder);
    }, [order, setOrder]);

    const setOrderFields = (stepAdjustment: number, state: OrderStepStateEnum) => {
        const newOrder = JSON.parse(JSON.stringify(order)) as unknown as PostcardCustomOrder;
        newOrder.stepStates[newOrder.step] = state;
    
        newOrder.step += applyStepStateAdjustment(newOrder.step, stepAdjustment, newOrder.stepStates);
        //newOrder.orderEV.requestShopTV = requestShopTV;
        //newOrder.orderEV.headline = requestShopTV ? headline : '';
        //const json = JSON.stringify(newOrder);
        //localStorage.setItem('evc-order', json);
        setOrder(newOrder);
        updateSlug(id, newOrder);
    };

    const updateContactsAndRemovedContacts = useCallback((contactsUpdated: Contact[], removedContactsUpdated: Contact[]) => {
        const newOrder = JSON.parse(JSON.stringify(order)) as unknown as PostcardCustomOrder;
        newOrder.contacts = contactsUpdated;
        newOrder.removedContacts = removedContactsUpdated;
        setOrder(newOrder);
    }, [setOrder, order]);

    const buttonsJsx = (
        <OrderButtons
            submitLabel="Next"
            submitDisabled={order.contacts.length === 0}
            skipLabel="Skip (for now)"
            backLabel="Back"
            SetOrderFields={setOrderFields}
        />
    );

    const exampleJsx = order.removedContacts.length > 0 ? (
        <div style={{height: '65.5vh', overflowY: 'auto'}}>
            Removed name(s): <br/><br/>
            {order.removedContacts.map(mapRemovedContactsToJsx) ?? <></>}
        </div>
        ) : <></>;
    
    const fetchContacts : FetchCall<Contact[]> = FetchCall.Success(order.contacts);

    return (
        <>
            <OrderForm id="postcard" name="postcard" buttonsJsx={buttonsJsx} exampleJsx={exampleJsx} SetOrderFields={setOrderFields}>
                <MailingList 
                    contacts={fetchContacts} 
                    updateContactsAndRemovedContacts={updateContactsAndRemovedContacts} 
                    removedContacts={order.removedContacts} 
                    setContactAddressIndex={setContactAddressIndex} 
                    />
            </OrderForm>

        </>
    );
}