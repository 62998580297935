import { useEffect, useState } from "react";

export interface WindowDimensions {
    width: number,
    height: number
}

/**
 * @description get the window dimensions
 * @return {windowDimensions} width and height
 */
export default function useWindowDimensions() : WindowDimensions {
    const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(getWindowDimensions());

    useEffect(() => {
        /**
         * @description handles windows resize
         */
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

/**
 * @description getWindowDimensions
 * @return {number} returns windows width and height
 */
export function getWindowDimensions(): WindowDimensions {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}


/**
 * @description get the screen dimensions
 * @return {screenDimensions} width and height
 */
export function useScreenDimensions(): WindowDimensions {
    const [screenDimensions, setScreenDimensions] = useState<WindowDimensions>(getScreenDimensions());

    useEffect(() => {
        /**
         * @description handles windows resize
         */
        function handleResize() {
            setScreenDimensions(getScreenDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return screenDimensions;
}

/**
 * @description getScreenDimensions
 * @return {number} returns screen width and height
 */
export function getScreenDimensions(): WindowDimensions {
    const { width, height } = window.screen;
    return {
        width,
        height,
    };
};