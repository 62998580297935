import { ISalesForceStreamingClientInformation } from "./domain/client-information";
import { baseUri, getBearerHeader } from "./fetch";
import { Result } from "./result";

export const getClientInformation = async () : Promise<Result<ISalesForceStreamingClientInformation[]>> =>
{
    const requestInit = getBearerHeader();
    const uri = `${baseUri}api/loggermessages/getClientInformation`;
    const response = await fetch(uri, requestInit);
    if (!response.ok) {
        var errorMessage = `Error running getClientInformation: ${response.statusText}`;
        return Result.Error(errorMessage);
    }

    const data : ISalesForceStreamingClientInformation = await response.json();
    return Result.Success(data);
}

export const getMessages = async () : Promise<Result<any>> => 
{
    const controller = new AbortController();
    const signal = controller.signal;
    const requestInit = getBearerHeader({signal});
    const uri = `${baseUri}api/loggermessages/getMessages`;
    const response = await fetch(uri, requestInit);
    if (!response.ok) {
        var errorMessage = `Error running getMessages: ${response.statusText}`;
        return Result.Error(errorMessage);
    }

    const data = await response.json();
    return Result.Success(data);
}

export const getModules = async () : Promise<Result<Array<string>>> => 
{
    const requestInit = getBearerHeader();
    const uri = `${baseUri}api/loggermessages/getModules`;
    const response = await fetch(uri, requestInit);
    if (!response.ok) {
        const errorMessage = `Unable to run ${uri}, status code = ${response.statusText}`;
        return Result.Error(errorMessage);
    }

    const data : Result<Array<string>> = await response.json();
    return Result.Success(data);
}

