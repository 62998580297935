import { EDDMRoute } from "../../core/maps/domain/eddm-route";

export const EDDM_ROUTES: EDDMRoute[] = [
	{
		zip: '60093', cridId: 'C006', medianAge: 45, medianIncome: 246942, residentCount: 144, businessCount: 0, totalCount: 144, type: 'C', pathPolylines: [
			{ path: 'itb`Gt|ovO`Ak@JCF?FF`@tAHj@pArF' },
			{ path: 'ii``GzcnvOiDfC_Ar@k@b@GDa@XGFa@d@?gJ' },
			{ path: '_z``GbvnvOm@`@_@V_@^]b@[n@_@v@^~CV|ADv@?~@D|@\\rC?~BKzBa@dDGr@Bh@n@Bl@El@Qn@e@|@s@h@k@ByCkAZ' },
			{ path: 'gaa`Gp~nvOSVUXSJYDSDURa@y@SEO?WJK?KIe@y@' },
			{ path: '}ua`GrgovO{ApCORSFUB[DUBWPWVSZKRER?V@V?\\CXGRSTOPKR[Qe@K]SEYY_C' },
			{ path: 'w`a`G~}nvOOP_BwEOFKGEQ@IBILAFBDH?X' },
			{ path: 'eu``GljovOEzHCr@' },
			{ path: 'eu``GljovOBeI@{HBqACk@?OE_@Ue@f@mA' },
			{ path: '_z``GbvnvO\\_@h@y@Rc@JSZy@' },
			{ path: 'su``Gj|ovOAj@?`@DvA' },
			{ path: 'g`a`Gh}nvOOT' },
			{ path: 'qx``Gd}nvOU}@eAaC' },
			{ path: 'e_a`GrcpvOLgA?y@qH?{BC' },
			{ path: 'su``Gj|ovO?s@' },
			{ path: 'ipb`Gb~ovOpAcAt@e@ZEn@@NENOP]LKJEN@JDVXFNBR@`@nAxB@e@De@FYJ]H]F]De@D]FW' },
			{ path: 'uda`GlnovO@_ACy@CMeAuAIWC]Bm@NOR_@LSNW' },
			{ path: '{ja`GffovOUTSJO@Q?WEYM[SUSY]OQQOQAM?OLMTKXS`@Wd@' },
			{ path: 'kga`GjdovOOFWHQBSHQLOL' },
			{ path: 'ob``Gl~mvOyElD' },
		]
	},
	{
		zip: '60093', cridId: 'C004', medianAge: 45, medianIncome: 248353, residentCount: 150, businessCount: 0, totalCount: 150, type: 'C', pathPolylines: [
			{ path: 'gf``G~vnvOiB{EUB[JVeAPg@TsA`EB`D?B_DGQkCuE' },
			{ path: '{c``GxtnvOkAdAgFxDfBfFyDzC}@l@eB@' },
			{ path: 'aw_`G~snvOoH|FhB`F' },
			{ path: 'u}_`GtonvOOY?e@BY@eA' },
			{ path: 'om``Gx|nvOcClBK@aBA' },
			{ path: 'u}_`GtonvOeEbDj@`B|@`CuHbGjBdFgEhDwAdA{@t@KBgBG' },
			{ path: 'aj_`GnenvOk@o@{@m@i@GQ?W@QFoD~BsAfAoBpB]`@Dh@YZ' },
			{ path: 'ck``GrpnvOMPu@r@qChBYFkA?' },
			{ path: 'a}_`GnmnvOMKQG' },
			{ path: '{h_`GpgnvOe@aA' },
			{ path: 'ci``GpjnvOCWA}D' },
			{ path: 'qi``GbpnvOYu@' },
		]
	},
	{
		zip: '60093', cridId: 'C024', medianAge: 46, medianIncome: 119846, residentCount: 251, businessCount: 0, totalCount: 251, type: 'C', pathPolylines: [
			{ path: 'mr}_GprvvO@xGmJO?uA' },
			{ path: 'or}_G`lwvOArF`JDClF}I@_HE' },
			{ path: 'mr}_GtcwvOcJ@?yA@yBAa@Ge@En@Ad@?zAA~A' },
			{ path: 'og}_GhaxvO?v@?rF?bD' },
			{ path: 'wr}_GpjxvO?uF{FC' },
			{ path: 'm{}_GzqxvOxG@CkFoHI' },
			{ path: 'mr}_GtcwvOAjGvIJ' },
			{ path: 'sg}_GzcwvOyIE@iGzJB' },
			{ path: 'k}|_GbtwvOcIG' },
			{ path: 'o`}_GfoxvO@iFCQIa@Ui@e@g@e@Qg@CiA?gJEDoF?uFsHAk@??aB?mC?qC?yB' },
			{ path: 'og}_GtjxvOgJC' },
			{ path: '_~}_GjywvOB[Da@?e@Bg@?k@S?' },
			{ path: 'sg}_G~|wvO?u@' },
			{ path: 'or}_G`lwvOaJ@' },
			{ path: 'ok}_GbrxvOcFE' },
		]
	},
	{
		zip: '60093', cridId: 'C046', medianAge: 43, medianIncome: 213337, residentCount: 182, businessCount: 0, totalCount: 182, type: 'C', pathPolylines: [
			{ path: 'wx~_GtrovO_@e@kEwB?qJ' },
			{ path: 'aa_`G|fqvOsFA?`CEnQ' },
			{ path: 'm`_`Gn`pvOGbLArLItJAnU' },
			{ path: 'gp_`GvvrvOpA@JkX' },
			{ path: 'ih_`GjopvOIvJ' },
			{ path: 'yg_`GflovOxADfAJrA\\IvQ' },
			{ path: 'uh_`G|jqvOuEGk@@GPB|A' },
			{ path: 'oh_`G||pvOE|H' },
			{ path: 'ch_`Gn`pvOEzK' },
			{ path: 'ak_`GzvrvOsAA' },
		]
	},
	{
		zip: '60093', cridId: 'C003', medianAge: 43, medianIncome: 217849, residentCount: 169, businessCount: 1, totalCount: 170, type: 'C', pathPolylines: [
			{ path: 'q_~_GxilvOxCkDHIf@AtEBbD?R`@^f@Fr@ApD{EEwEA' },
			{ path: 'kr}_G|wlvOE`I}ECqFC' },
			{ path: 'wr}_G~hmvOD_FtEB@l@aCdB' },
			{ path: 'kr}_G|wlvOaFAiFC' },
			{ path: 'yk}_GpcmvOrEoDDaEwE@sECAmIAiH' },
			{ path: 'g~|_G~mlvOsECBqECSi@mAeFAFgHDuK|@Jp@?|@Yx@S' },
			{ path: 'y~|_Gf{kvOkFG}EAaKC' },
			{ path: '_~|_G`xlvO_FABcIuEEEjIC`I' },
			{ path: '}l}_GfnkvOy@sB' },
			{ path: 'ef}_GfdlvO?gH' },
		]
	},
	{
		zip: '60093', cridId: 'C022', medianAge: 50, medianIncome: 89670, residentCount: 313, businessCount: 25, totalCount: 338, type: 'C', pathPolylines: [
			{ path: '{}|_Gr{tvOGjF' },
			{ path: 'yo{_GdstvOHlAGxAqCAINC\\fB?V?r@@v@H' },
			{ path: 's}z_GjztvOa@?e@?cA?kBAa@?I?' },
			{ path: '}y{_GvytvOiA?iACqEAsCAm@?S?i@A_@?_@D_ANg@D?h@fAO' },
			{ path: 'ou}_Gt~uvO~A]dOeDdQaEVC' },
			{ path: 'ox|_GpjuvODiNd@CvAK~@M' },
			{ path: 'co|_Gj~tvOYDi@AOHm@@' },
			{ path: 'go|_GxeuvOZBb@JZDzA?JCf@[^GjB?fBHR?p@MVIHENUBcBGc@KQUI[@u@Jq@VS@c@GOIKKGOEOI}@gAA' },
			{ path: 'ax|_GnmtvOGvL' },
			{ path: 'wq|_Gx~tvO?jBCBI?eAAGAGQ?gDNI~@M' },
			{ path: 'ce|_GpytvOArA' },
			{ path: 'wt{_GxytvOS?qCA' },
		]
	},
	{
		zip: '60093', cridId: 'C015', medianAge: 39, medianIncome: 245288, residentCount: 189, businessCount: 0, totalCount: 189, type: 'C', pathPolylines: [
			{ path: 'c`_`GvmovOjEvB^d@zA`A|Ah@v@RC|HE`L' },
			{ path: '}a~_GlwovO]AqBUm@Cw@BHkS' },
			{ path: '}p~_GfbovOGlS`BDnCEC|HnF@' },
			{ path: 'oz}_GhsovOg@?i@LeBdBu@N' },
			{ path: 'iq~_Gr`pvOpF?CdL' },
			{ path: 'yx~_Gp`pvO@{LDkO' },
		]
	},
	{
		zip: '60093', cridId: 'C042', medianAge: 50, medianIncome: 110165, residentCount: 248, businessCount: 34, totalCount: 282, type: 'C', pathPolylines: [
			{ path: 'm|}_GpzsvOIrF?rFrJ?BmFjJ@ApFbD@' },
			{ path: 'yn|_GlxsvOxG@TBHJFR?bAcCnAcEpBeGvDYLoGlD{IbFCsIxD@' },
			{ path: 'cq}_GhxtvOA}E@qFrDB' },
			{ path: 'w|}_GdstvOa@AODUPI\\Gv@AHEPSPOFoDBg@D?fG' },
			{ path: 'ev}_GrztvOOOGYB}F_EC@mF' },
			{ path: 'm|}_GvysvO?XpJFjJ@CpF|GB' },
			{ path: '_x|_G~gtvO@yKqLI' },
			{ path: '}{}_Gn~rvO?eB?iE@mD' },
			{ path: 'w|}_GxvtvO?sBA}@' },
			{ path: 's{}_Gx}qvO@[BmBBmB?sA?cD?_@?UBiG?Y@yA@c@?eCBeD?oB' },
			{ path: 'w|}_GxvtvOAxC' },
			{ path: 'ug~_GpxtvO]FSFgAx@KLCNDjC' },
			{ path: 'ev}_GrztvOPBLBFC`B}@VOvDwB' },
			{ path: 'gc|_GrzsvOo@b@' },
			{ path: '{p}_GxzsvOCpFwJE' },
			{ path: '{n|_Gd{svOaH?' },
			{ path: 'eq}_GjqtvOsD?' },
			{ path: 'ip}_Gn{rvOsJE' },
		]
	},
	{
		zip: '60093', cridId: 'C011', medianAge: 43, medianIncome: 152246, residentCount: 192, businessCount: 120, totalCount: 312, type: 'C', pathPolylines: [
			{ path: 'kx~_G|wnvOAoDzBFl@?' },
			{ path: 'kx~_G|wnvOAhDlFB@zCGlS' },
			{ path: 'kx~_G|wnvOwFC?jIpFBBaD' },
			{ path: 'kx~_G|wnvOxBAvB@rFD' },
			{ path: 'c`_`GvmovO?qJqF??eB_FdE' },
			{ path: '}x~_GrgnvOBxABb@FzAZCn@AjDECzHElD' },
			{ path: 'ug_`G~~nvO?aCzCeCb@a@p@_@?bB' },
			{ path: 'ug_`GdbovOAzEAdBEhG' },
			{ path: 'c`_`GttnvO~AgAtBaB^]?X?dB' },
			{ path: 'qt~_GzwnvO?eD' },
			{ path: '}p~_GfbovOsF@' },
		]
	},
	{
		zip: '60093', cridId: 'C026', medianAge: 46, medianIncome: 122652, residentCount: 248, businessCount: 4, totalCount: 252, type: 'C', pathPolylines: [
			{ path: 's{``GfjxvOuCyGdBcBn@q@^]j@i@|@u@r@e@JnB' },
			{ path: '}g_`GjhxvO?`D~@ArDD@kF' },
			{ path: 'wj_`GfyvvO@lHCZ?nGDJFBJ@hBg@dAQJJN@HK?ICGGEM?IHAJ' },
			{ path: 'em~_GxbxvO?z@CXKPSFkEGArFI|K' },
			{ path: 'mka`G~lxvOONiAjA' },
			{ path: 'ut~_G|axvO?{MBoJ?wBuAZaCt@c@BIJELBPFDR?NWB]' },
			{ path: 'im``GlwwvOp@MNOFwBCq@bKgDCpIH`@LVNH\\DnFiB?zCGb@k@xEuDNc@JM\\EdA?zAFn@JTTH`@FzBAl@a@hED' },
			{ path: 'ut~_G|axvOsJCJgJwG@' },
			{ path: 'ut~_G`fxvO?cC' },
			{ path: 'uj_`GtbwvO_@JcA^gAZ}M~E' },
			{ path: 'mt~_GfzvvOC{@?_@@_@DYFQp@u@fAkA' },
			{ path: 'ed_`GjtvvOX?pC@?YFQ^[jOcHXCLBJDb@p@' },
			{ path: 'ky~_GpmwvOL`D@FHDzB?' },
			{ path: 'wt~_GtmxvOqJC' },
			{ path: 'm{~_Gh{lvOmAoB' },
			{ path: 'caa`GfoxvOoCiGhAgAKa@ME_@_AEAs@C' },
			{ path: 'ow_`GbmwvO`AjGfAa@ZSh@m@Ne@Hi@DaAA{G' },
			{ path: 'i`a`GlaxvO_BfB' },
			{ path: 'sea`G|fxvO_BdByAzA' },
			{ path: 'yv_`GxjxvO^y@Fo@B}@C_@Ka@c@e@' },
			{ path: 'qt~_GxcwvOBqHmDjAeBn@m@P{A`@aB^aCf@eAT' },
			{ path: 'at~_G~tvvOOGYCmHE' },
			{ path: 'ck``GdqwvOu@Xs@Zs@\\}@l@uBjA' },
			{ path: 'i`_`GxaxvOcMA' },
		]
	},
	{
		zip: '60093', cridId: 'C032', medianAge: 48, medianIncome: 145199, residentCount: 147, businessCount: 7, totalCount: 154, type: 'C', pathPolylines: [
			{ path: 'mj~_Gx}qvOwCA_BAeFEwFAwF?mC?kGA' },
			{ path: 'cv{_GvjrvO[M}@E@wJ`HF?|UGx@Q^]JaABcCOuAUBsEJ{@XyADgA' },
			{ path: '_a}_G~}qvO{D??wFmSE' },
			{ path: 'gi|_GxwrvOjCAB_C?mCSoAk@q@cA_@oDE' },
			{ path: '_h{_Gp~qvOKjSEdBMt@Ej@CxAFVPHVAbAS~AEbA@nBLA|B' },
			{ path: 'ouz_G|~qvOIaCQmEc@aK' },
			{ path: '{uz_GdyrvOaFA?}CAQGCcJQ' },
			{ path: 'ouz_G|~qvOgEEsCEsF?wC?aB@' },
			{ path: '{f}_G~}qvOcICiDAeB?cA?}@?qAA' },
			{ path: '}q~_GjlqvOGhPAdIAPIDi@JKLELAlA' },
			{ path: '{f}_GfvqvO?iA' },
			{ path: 'mz{_GtwrvOmI@' },
			{ path: 'cl}_GjnqvOeNA' },
			{ path: 'qe|_Gh~qvOwE?}@?' },
			{ path: '{x{_Gj~qvOwIA}@?' },
			{ path: 'avz_GbosvOC|B' },
			{ path: 'ouz_G|~qvO?lEEzHaB@yBE' },
			{ path: '_c~_Gv}qvOmF@' },
			{ path: '}|z_GbyrvOApB' },
			{ path: '{uz_Gv{rvO?qAD}H' },
		]
	},
	{
		zip: '60093', cridId: 'C035', medianAge: 50, medianIncome: 90815, residentCount: 195, businessCount: 161, totalCount: 356, type: 'C', pathPolylines: [
			{ path: 'i_~_GtvuvOo@?o@FgBAcAEo@AgCAHuE' },
			{ path: 'q}}_GrvuvOw@@' },
			{ path: 'gc}_GveuvO?qEK]r@o@^QZIZCj@Cb@A~AGl@Ad@CvAK~@M' },
			{ path: 'is~_Gd`vvO@wHlA@p@?x@@' },
			{ path: 'qd}_Gd_uvOkC`Cc@^q@n@k@h@{@t@mArAcAdA_B`BwAtA_@\\m@d@ML[N' },
			{ path: 'el}_GphuvOYo@' },
			{ path: 'eb~_GdpuvOCvE' },
			{ path: 'qd}_Gd_uvONn@AnDD|ECXKFsCr@EAE?CIAI@mHOm@' },
			{ path: 'sc}_Gf~tvO]\\' },
			{ path: 'ug~_GtvuvOCaI' },
			{ path: 'gs~_GlvuvOo@A]?O@' },
		]
	},
	{
		zip: '60093', cridId: 'C034', medianAge: 41, medianIncome: 224876, residentCount: 105, businessCount: 0, totalCount: 105, type: 'C', pathPolylines: [
			{ path: 'kk``Gb}qvO_BCaG?D{F_BDG`G?bBFjBGtBEt@IJIFOBaBGOAKCGG?GAMFcCEwFDaFBGDG\\AhCD' },
			{ path: 'yv``G|zrvOBsHFoLyA@' },
			{ path: 'mv``GxcrvO?yE' },
			{ path: 'kq``G|tqvO{CDDaN?oA?mD' },
			{ path: 'uv``GhqrvO|B?hB?D?D?B@BBDB@J@JAn@KJCJ@HDFF@DE@I?KGO' },
			{ path: 'uf``Gd}qvOaCAG?K?' },
		]
	},
	{
		zip: '60093', cridId: 'C020', medianAge: 45, medianIncome: 183241, residentCount: 141, businessCount: 0, totalCount: 141, type: 'C', pathPolylines: [
			{ path: 'kk``Gb}qvO_BCArI' },
			{ path: 'wk``G~hrvOHaF@yCJ?ByFwAC' },
			{ path: 'uu_`Gj}qvO_@?yGEBcOFsQBmB?aGBiGBiECqD' },
			{ path: '{h_`Gl}qvOmC?kGAClW' },
			{ path: 'c_``GlzpvOnBp@zAn@x@n@b@L^D^?j@Mt@UlCe@xBK' },
			{ path: 'qp_`G`gqvOFaDMoBYgBSmBGuG' },
			{ path: '{j``GjrqvO?T?fA' },
			{ path: 'o_``Gd}qvOwC?mA?aCAG?' },
			{ path: 'uf``G`urvOAeH?U@_M' },
			{ path: 'o_``Gd}qvOOvX' },
		]
	},
	{
		zip: '60093', cridId: 'C028', medianAge: 49, medianIncome: 86280, residentCount: 405, businessCount: 54, totalCount: 459, type: 'C', pathPolylines: [
			{ path: 'sh}_G|b{vOgADuBFi@BaABuAFy@BqENaEHwAHsADcENyEP?uD@aB?cD?{CBaA' },
			{ path: 'o}|_G~a{vOW@cDL_ABg@D_AB?zCCZBj@DBlARB{C' },
			{ path: 'o}|_G~a{vOhBC`AErAEzBM' },
			{ path: 'em}_Gp|{vO?wA?wA~H@?yA@{AwEC?kB{BE' },
			{ path: 'uc}_Gdm|vO@iB?mBqHA?oB?oBxHH@aB@wA@wA' },
			{ path: 'cn}_Gdj{vOvB?RBCrBpACVZjB@@iB?uA' },
			{ path: 'mm}_G~p|vODcFtAA?jB' },
			{ path: 'wh}_Gth{vOQPM`@' },
			{ path: 'cm}_Gdt{vO?{A]I' },
			{ path: 'iz~_Gzf{vO[BS@c@@_@FUBUBSJUFSLUNWPWPWRcAp@gCdB' },
			{ path: 'ku~_Gjf{vOW@@yD' },
			{ path: 'gc}_Gzy{vO}HA' },
			{ path: 'em}_G`w{vO@{A|HB' },
			{ path: 'sc}_Gzi|vO}EA' },
			{ path: 'cc}_Glq{vOAuA' },
			{ path: 'cc}_Glk{vO{AS' },
			{ path: 'em}_Gjf|vOAnB' },
			{ path: 'em}_Gj_|vO?yAzH@' },
			{ path: 'kc}_Gt_|vOCnBuHI' },
			{ path: '{i}_Ghq{vOgB?' },
			{ path: 'oc}_Gdc|vOCfB' },
			{ path: 'sq|_GlyzvOyBAe@ACe@Ec@Mg@?UDmAVgAb@}@NYb@d@?|@' },
			{ path: 'iu|_GlnzvOm@aAMEYD' },
			{ path: 'mq|_GxkzvOe@Ma@GKk@I_AGk@VcA' },
			{ path: 'cv~_Glf{vOkBJY@' },
			{ path: 'us|_GbkzvOc@nA' },
		]
	},
	{
		zip: '60093', cridId: 'C018', medianAge: 43, medianIncome: 207006, residentCount: 117, businessCount: 1, totalCount: 118, type: 'C', pathPolylines: [
			{ path: 'm||_GxfmvO`AQvAQr@Kr@MvB]pASx@OfBe@REvBk@' },
			{ path: 'cn|_GpmnvOk@PkCD}@eC]_AUu@I_@Ea@tEL~CBf@?p@oCEgA_AsBK{@?qBDgA@q@Kq@Bi@Bw@Jm@Ry@n@{BLS' },
			{ path: '_d{_GpknvO]MQwA[c@_@S{B}@Wk@Gy@v@sNCsDKkAOw@a@}@c@OIe@g@\\aG\\mBGSCkAqGe@Lm@P' },
			{ path: '}m|_Gh|nvOq@mBYcAaAuCo@wB' },
			{ path: '}m|_Gh|nvOPp@l@c@l@Y~@QnAIoAsHo@wDWw@o@`A{@p@' },
			{ path: '{r|_GdvmvO|A_@tBAF?' },
			{ path: 'ql{_GzvlvO}DhAwDbA_Cp@o@P' },
			{ path: 'od{_G~slvOwA`@iDx@' },
			{ path: '{_|_GnmovOc@KUm@Uc@Qo@E]Cc@BCn@Mj@Q`@_Af@_@lF@JJFHOvLc@tCHvAP|@BnE' },
			{ path: '_d{_GpknvOfBFPDEfSI`AIL_B?WBERDpAEzOQn@kBdAk@f@_@dAOdB' },
			{ path: '_f|_GnfmvOCKi@s@UWM]Me@' },
			{ path: 'wj|_G|invOGgA?wA' },
			{ path: '_f|_G`{nvOnAPfB\\Zf@Rx@XfB' },
			{ path: '}w|_GjdnvOAy@@_C' },
			{ path: 'yh{_GfzlvOGnAQjAe@x@}@lAg@b@' },
			{ path: '}o|_GdumvOEyG' },
			{ path: 'um{_GtemvOq@G' },
		]
	},
	{
		zip: '60093', cridId: 'C029', medianAge: 46, medianIncome: 235322, residentCount: 138, businessCount: 0, totalCount: 138, type: 'C', pathPolylines: [
			{ path: '_v|_GnipvO?TNb@R\\^Hl@Eh@Od@Lf@@b@?AfDCjGqLEU@QAEIGiDQ{BFgDb@qEQYWAwEE' },
			{ path: 'en|_G`xqvO{FCyD?' },
			{ path: '{f}_G~}qvOzD?jGD' },
			{ path: '_m|_GfdovOArAAtE|@~@x@p@l@Vh@NX@`@MV@LHzApA' },
			{ path: 'oo|_GzxnvOp@lBPp@Fb@B^@d@?b@A|@' },
			{ path: 'g||_GtbpvOVVJ?r@YzAy@b@Mn@GhBZh@D~@IFkOuGD' },
			{ path: '{u|_GtdqvOApHgE@' },
			{ path: '{m|_GxdqvO_GCwHCa@l@DpFM~BCdA@fI' },
			{ path: '}u|_GfnqvOCtH' },
			{ path: 'km|_Gz`pvOEnJ' },
			{ path: 'cm|_GpmovO?|A' },
		]
	},
	{
		zip: '60093', cridId: 'C016', medianAge: 44, medianIncome: 204010, residentCount: 129, businessCount: 1, totalCount: 130, type: 'C', pathPolylines: [
			{ path: 'qa~_G|wnvOjFHnJB@cInJBrJ@rBC' },
			{ path: 'gx|_GrxnvOAfIqA@yGCHkIiJAKfI@vB' },
			{ path: 'wa~_GjbovOnFAdJHjJDItE?rEG~LzA{A' },
			{ path: 'cc}_GjnnvO?cI' },
			{ path: '{z|_G|bovOCxEsBxDUPuC?' },
			{ path: 'on}_G`dnvOCdI' },
			{ path: 'uw_`GjoyvOyACM?mB?sC?uBC' },
			{ path: 'at|_GzbovOgC?' },
			{ path: '}l|_GhbovOqDD' },
			{ path: 'oo|_GzxnvOwGGcJE' },
		]
	},
	{
		zip: '60093', cridId: 'C008', medianAge: 41, medianIncome: 250001, residentCount: 159, businessCount: 0, totalCount: 159, type: 'C', pathPolylines: [
			{ path: 'yl``GntsvOoIGDaIHgNcDDyAC?zFInF|FE' },
			{ path: 'qc``Gx}svODN@xDAPMJIB}IC@}EFOJClIBJD' },
			{ path: 'oy``Gl}tvOB[LyBJ{AH}AHqBBqA@aAJaP@mD' },
			{ path: 'kw``GtysvOaDEiA?U?I?' },
			{ path: '_ba`G~{tvOb@@nFNjCF' },
			{ path: 'ela`GdjsvOpE?pEDKbN' },
			{ path: 'o~``G|prvOG`IwED' },
			{ path: 'ks``G`zsvO_CK' },
			{ path: 'ao``G`etvOAhCGf@SBwF?' },
			{ path: 'yl``GntsvOzE?r@@' },
		]
	},
	{
		zip: '60093', cridId: 'C037', medianAge: 53, medianIncome: 133077, residentCount: 231, businessCount: 3, totalCount: 234, type: 'C', pathPolylines: [
			{ path: 'o~|_G`szvOoAV_@?UCc@OIXKROPUP[Ti@L' },
			{ path: 'co|_Gj~tvO?kA?kA@kB@cC?aC?eC?q@?kA?m@?uABiB?iB@wA?yA@kB?m@@{E@wC?gA?_@@u@?]?gB@wDBgKBqF?oA?u@' },
			{ path: '{s}_GrpyvOmEC@aHBO\\iAb@uB@aBIi@' },
			{ path: '{p|_GxrxvOCpFnEDBvAH\\' },
			{ path: '_q|_GzdyvO?iA?kDsBGCAGOCu@IKMA}MI' },
			{ path: 'gp|_GrvvvOCjEAtC?vEE|JAxFAzC?pAAtFAnB?~B' },
			{ path: 'gp|_GrvvvO?aABuDP{M@cCBcE?kB@_C?mA?M@uDBmF' },
			{ path: 'y}}_GhjzvOGQMGIFEDATBFLH' },
			{ path: 'qj_`Gto{vOmA{DSm@I_@DsHFi@HSJSjEkGzCgELI^ORAhF@nA@RBN@NBRJDDHVJr@t@[XQj@i@l@u@ZAj@FdBRR?^IRGtAY`@?By@Le@|@oAJCv@CNEHULcA' },
			{ path: 'gq|_G|`zvOiD?YdC' },
			{ path: 'mg~_GjhzvOKECM@QJGVCNHDF?\\NpA' },
			{ path: 'mp~_GtfzvOOAUJIF?HDPDDNARGDGDG?K^ANDHHVvAFH' },
			{ path: 'as~_Gj_zvOF@z@KJBDJAbFDBDH' },
			{ path: '{t~_G~oyvO?wIO??Y' },
			{ path: 'if}_GvpyvO_FAGiBUcD?}@DaAb@qDFmA@yG' },
			{ path: 'af~_GhhzvOk@@' },
			{ path: 'ek|_GjujvO?c@@aA?gAAe@EWKW[g@a@a@c@g@[]' },
			{ path: '}c~_GjxyvOE{@KgEcDAuGC' },
			{ path: 'mq|_GdnzvO?kAD{I?}CBsI{JIGxCA~@InAW~CIfB?vC' },
			{ path: 'qb~_GrbzvOCyAUyDQs@_@j@s@\\?jF' },
			{ path: '{u~_GjpzvODqC?kB' },
			{ path: '{o}_GpvzvOR}d@sC?LzN' },
			{ path: '_}|_G`qyvOiHIG|ACrFCd@OfAAn@Bf@F`B?z@If@KfAA`ABv@ZfAJXZ^t@j@' },
			{ path: 'qv|_G|`zvOIc@' },
			{ path: '_m~_Gf_yvODjCITYX}EB' },
			{ path: 'ed|_Gd~xvOIc@O]QU]SSEkBA' },
			{ path: '_q|_GzdyvOAbE' },
			{ path: 'a_~_GrjzvOwAQ' },
			{ path: 'eu~_GpqzvOBsD?eA@aJ' },
			{ path: 'iz}_GnpyvOeIG' },
			{ path: '_q|_Gd}xvO?yA' },
			{ path: '_q|_GpbyvOkPQ' },
			{ path: 'ku~_GfeyvOAxA' },
			{ path: '}p~_G`vzvOGgAc@_BGw@' },
			{ path: 'me_`GvszvO_@]' },
			{ path: 'im}_GtpyvO}@A' },
		]
	},
	{
		zip: '60093', cridId: 'C002', medianAge: 43, medianIncome: 233865, residentCount: 203, businessCount: 19, totalCount: 222, type: 'C', pathPolylines: [
			{ path: '_l|_G`jlvO?y@@mC_JBGHAzB?hD?V?Z?hBnBaB' },
			{ path: '_}|_Gn{kvOCfI[h@Wi@AoIkFGNyLy@R}@Xq@?}@K' },
			{ path: '}t|_Gt{kvOQ??W' },
			{ path: 'ar|_Gt{kvO{A??oC' },
			{ path: 'iz}_GnpyvOeIGcDAuGCqAA' },
			{ path: '_}|_GrikvOs@XAjCClKx@FrABzC@' },
			{ path: '{d}_GzmlvOrEBF}E' },
			{ path: '}_|_GrukvOcCCCbE]jAa@j@m@HgCH@gC@iE?_ABqI|A@rC?hC@' },
			{ path: '_w|_GvhkvOw@AaADeAVBcBMUOEQPGN?vB_Bd@cC?KYwBoD' },
			{ path: 'gr|_G~wkvO?|@DT?`@fE@' },
			{ path: '{h|_GzclvOaBA@yB' },
			{ path: 'wk|_GlukvOtF@?qK' },
			{ path: '{x~_GxoyvOq@?cAAeBC' },
			{ path: 'mu~_G~oyvOg@?e@A_@C' },
			{ path: 'ue}_GdmkvOAq@' },
			{ path: 'gw|_GdnlvO_FEF`I' },
			{ path: '_l|_GfhlvOs@l@' },
			{ path: '_}|_Gn{kvO?{P' },
		]
	},
	{
		zip: '60093', cridId: 'C043', medianAge: 43, medianIncome: 202451, residentCount: 210, businessCount: 3, totalCount: 213, type: 'C', pathPolylines: [
			{ path: '{w|_G|ymvO?rA_H@uDAmE?yK@BoC?w@?{@' },
			{ path: 'ez}_GfxnvOA`IElJApDEnL' },
			{ path: 'ez}_GfxnvOByHiFGEvHElIE`TIfH' },
			{ path: '}y}_G|cnvOeFGyFE' },
			{ path: '}y}_G|cnvOCnIlJE' },
			{ path: '{w|_G|ymvO?_CBgDB_J' },
			{ path: 'ga~_GtkmvOApG' },
			{ path: '}`~_GtzmvOE~GGnI' },
			{ path: 'of}_GlimvO?jB?zCAzI' },
			{ path: 'on}_G`dnvOmJCBiF' },
			{ path: 'on}_G`dnvOjJDdJBAy@@_C@_A' },
		]
	},
	{
		zip: '60093', cridId: 'C023', medianAge: 45, medianIncome: 135552, residentCount: 258, businessCount: 0, totalCount: 258, type: 'C', pathPolylines: [
			{ path: 'ae|_GbsxvOe@?sII' },
			{ path: 'kr}_Gj{vvOAyGDeG`E@D}BBOTa@RO`Kq@^MrAo@lCqA\\fAB`@C~FIb@IXUf@h@j@VJPF`C?' },
			{ path: 'ki|_GrcxvOqCCyABQFqEEEOGAG@ETFLDBDEFQ' },
			{ path: 'gm|_GtkwvO?mAESMAqAC' },
			{ path: 'gp|_GrvvvOyAeAi@[QCOAuCLqBBeAIw@MOEKO?cB' },
			{ path: 'og}_GzswvObIFvKFjHDj@?' },
			{ path: '{p|_GxnxvOqCA' },
			{ path: 'oo|_Gz{uvOs@?a@BgCjA' },
			{ path: 'up|_Gd|wvO|EGJHHNIzED~EEPKBaF?' },
			{ path: 'md|_GbsxvOS?' },
			{ path: '{z|_G|`vvO?vHwC@qKI' },
			{ path: 'kc|_Gv}vvOmGKqCK' },
			{ path: 'mp|_GtawvOuBCkAJk@HgC|@M@{BAiBKy@?' },
			{ path: 'gr}_GjjvvOAsCGYU_@' },
			{ path: 'yv|_GzlvvOeA{@UGe@A' },
		]
	},
	{
		zip: '60093', cridId: 'C036', medianAge: 43, medianIncome: 169997, residentCount: 219, businessCount: 1, totalCount: 220, type: 'C', pathPolylines: [
			{ path: '_`~_GbtmvOi@B@qG|F?EyH@_IiFCuF?C|HnF@E|HoFAD}HoF?' },
			{ path: 'ey}_G`dlvOg@@IHyCjDo@BLx@`@}@' },
			{ path: 'iy}_GtkmvOCdBtE{D' },
			{ path: 'yh~_G~tmvOEjG?b@AdBBx@ErIvF@' },
			{ path: 'kn~_G`fnvOMDKHKLIJILGNo@BsAhA' },
			{ path: 'eh~_GnflvOGfPqFA' },
			{ path: '}p~_GfbovOrF@IjS' },
			{ path: 'ci~_GbnnvOqFACm@?yABS@ODK' },
			{ path: 'ei~_GbxnvOsFEB{H' },
			{ path: 'ei~_GbxnvOCdIpF@' },
			{ path: 'or}_GddlvOuEC?jHGlI' },
			{ path: 'w`~_GvwlvOI~HpFB' },
			{ path: 's`~_GvklvOC~J' },
			{ path: 'ia~_GftmvOoFVuFD' },
			{ path: 'qa~_G|wnvOsFD@_I' },
			{ path: 'aa~_G|ilvOcCuE' },
			{ path: 'ip~_GtkmvOpFAAjH' },
			{ path: 'my}_GznmvOuD`DMBM@' },
		]
	},
	{
		zip: '60093', cridId: 'C019', medianAge: 50, medianIncome: 114962, residentCount: 159, businessCount: 0, totalCount: 159, type: 'C', pathPolylines: [
			{ path: 'wm|_G~ypvOCxIGxNAlB?nAAvB' },
			{ path: 'gg{_GjrpvOEzKDHrACFBVd@t@c@d@OxABHC?IJc\\vDB' },
			{ path: 'km|_Gz`pvOzUJvI?jFF`C?`BD' },
			{ path: 'wx{_GvpqvOi@eAEMEoA@Ub@{DGq@_@iBrC?dAP`AJFCRUJO@Wk@iL@UZ{@f@[z@Mb@QJUBOF{I' },
			{ path: 'wo{_GboqvO{@h@_ArA[LK?{BwA_@O[Am@D_CjAE?}@CICa@QEI_@s@KaAVyCE{AcCyKq@cCe@y@eA{@' },
			{ path: 'wp{_Gh`qvOLfBEtAVzGj@IjCHl@c@d@iAPmDPw@f@aAj@u@' },
			{ path: 'oz{_Gd`qvOiAoCIKs@g@wBKqABcAEo@E' },
			{ path: 'owz_GjhqvOiC@CFAfB' },
			{ path: 'kvz_GltqvOw@Og@Ac@Bc@RW?[IWO' },
		]
	},
	{
		zip: '60093', cridId: 'C001', medianAge: 39, medianIncome: 242159, residentCount: 241, businessCount: 2, totalCount: 243, type: 'C', pathPolylines: [
			{ path: 'ob~_Gj{pvOuBA?oLtB@FeL' },
			{ path: 'a{}_Gj{pvOsB?DmL' },
			{ path: 'ey~_Gn{pvOqFG{FC' },
			{ path: 'yx~_Gp`pvOGfLsFE{BCwBC' },
			{ path: 'ey~_Gn{pvOEjJ?rU' },
			{ path: 'er~_Gt}qvOFiPHaPqB@_CBBuL~BApBADaL' },
			{ path: 'ij~_GnlqvOwBA{BA' },
			{ path: 'ef~_GxmpvOuB?EjLGhPChP' },
			{ path: 'ob~_GzmpvO?nLKfPpF?' },
			{ path: 'm`_`Gn`pvOuF?oFC' },
			{ path: 'aj~_Gd{pvOqFBBsLrFBBeL' },
			{ path: 'gn}_GrwpvOgCFuAMaBYuAM' },
			{ path: 'ij~_GnlqvOlFBCbP' },
			{ path: 'u~}_Gj{pvOyB?' },
			{ path: 'ef~_Gh{pvO{BC' },
			{ path: 'yx~_Gp`pvOsFA' },
			{ path: 'au~_GvmpvOCrL' },
		]
	},
	{
		zip: '60093', cridId: 'C014', medianAge: 44, medianIncome: 247973, residentCount: 139, businessCount: 0, totalCount: 139, type: 'C', pathPolylines: [
			{ path: 'uz}_GldpvO?sBqFCGdLpFABwEBsAnAFrBPjBn@tAl@NoAOcBKe@' },
			{ path: '}z}_GtrpvO?{CbABz@NxA^nCHFrAJlAVxAZ`CFnA' },
			{ path: 'ko}_G|uovO[GwAk@aAOy@GsBGEnL' },
			{ path: 'gd}_GfppvOw@QeAa@uB_CsAmA[nBG`C' },
			{ path: 'gd}_GlcpvO?iDe@{A}AuAyC_AeAS?lFJ|A' },
			{ path: '}z}_GtrpvO?nBtAL`BXtALfCG' },
			{ path: '}z}_GdvpvOCdD' },
			{ path: 'gd}_GlcpvORvD?dBSzBEp@HnFCjCCX' },
		]
	},
	{
		zip: '60093', cridId: 'C017', medianAge: 44, medianIncome: 141912, residentCount: 214, businessCount: 33, totalCount: 247, type: 'C', pathPolylines: [
			{ path: 'kz|_GffmvOaAPmCh@?nS' },
			{ path: '}`~_GtzmvOaAz@cAv@}BjB' },
			{ path: 'qd|_G|xlvOAsGbCwB' },
			{ path: '}`~_GtzmvOdCmB`BwApBaB`BqAl@a@|@s@NQr@k@bKeIlCkAzAi@Ik@' },
			{ path: '_o|_GzslvOw@p@_@Zw@r@OJKLc@Vc@Zg@VgAf@GzH' },
			{ path: 'ch|_G`klvOKHOP[j@CFKVEJCHEJGLILKPELQPOLMLOL_@V' },
			{ path: '_c|_Gn_mvOs@PFe@A_@JX`@Xr@S^M' },
			{ path: 'ch|_G`klvOh@a@VSXjAFLLClAiA' },
			{ path: 'kt|_GvxlvOBPBNGnI?P' },
			{ path: 'md|_Gz}lvOC}CqFAC|H' },
			{ path: 'of}_GlimvOsDp@OAKAIIGI' },
			{ path: '_~|_G`xlvOGaI' },
			{ path: '{`}_GbhmvOsDh@' },
			{ path: 'cl|_GzxlvOBcC?oB~@SjDI' },
			{ path: 'cl}_G~jmvOm@Js@F' },
			{ path: '_|{_Gx`lvOaDfC_DhC' },
			{ path: '_l|_GfqlvO?aB' },
		]
	},
	{
		zip: '60093', cridId: 'C033', medianAge: 46, medianIncome: 113475, residentCount: 216, businessCount: 36, totalCount: 252, type: 'C', pathPolylines: [
			{ path: 'm`~_Gr_xvOSKOAsGSQUIAG@' },
			{ path: '_x}_GlrvvO}C?W??fACnB' },
			{ path: 'q}}_GtyuvOC`A?J?^?d@CzAAdAW?@d@TE?_@' },
			{ path: 'mr}_GprvvOcDCM??qB@sC' },
			{ path: '{|}_GdouvO?qAAaCB_DAk@@{B?uA@yC' },
			{ path: 'y~}_GttvvO_D?MCEIAQb@wBHWd@k@bAaAL?FBFXBf@CvEb@?' },
			{ path: '}d~_GbrxvOiHB_@EOKtA}AbAiAX]hAiA\\a@vA_Bd@k@V_@R]b@eAR{@H]Js@Hq@De@' },
			{ path: '}d~_GddyvO?kP?U' },
			{ path: 'uu}_G`uuvOGQAkAMo@wAtA_@\\m@d@ML[NAl@A|A?n@GxCvAW' },
			{ path: 'mj~_GxjvvOyBdCx@|A' },
			{ path: '}g}_GzrvvO{HASG' },
			{ path: 'gw}_GdouvOsD?@X?d@CdD' },
			{ path: 'q}}_GtyuvO?aBBiBB_AFk@DW' },
			{ path: '{t~_GfeyvO?sA@q@F}@Do@Hs@Jo@Nk@V_AXs@j@eA^g@t@u@nC_DxCaD`@a@Zg@\\m@Vk@Pc@KM' },
			{ path: 'k}}_G|evvO?U?a@pBeAAdC' },
			{ path: 'mv}_GjauvOORCJC\\AzJ' },
			{ path: 'ii~_Gb`vvOxAi@L^fA~BDGVQXO@lCZI@mCf@K?`@' },
			{ path: '_b~_G|fvvOI~@Uf@iBpB' },
			{ path: 'ca~_GrfvvOdBW@o@' },
			{ path: 'ea~_GlswvOg@AwEAoJG' },
			{ path: 'i}}_G`avvOAfA?Z' },
			{ path: 'k}}_G|evvOAhB?X?`@?b@G`@G_@?c@?y@?oB' },
			{ path: '_~}_GjywvOEh@Ml@S~@Y|@' },
			{ path: 'i}}_G`avvOOBYFe@J' },
			{ path: 'q}}_GrswvOS?_BE' },
			{ path: 'e~}_GvrwvO?ZCp@Aj@' },
			{ path: 'aa~_GdbvvO_@HEw@' },
			{ path: 'mj~_GxjvvOfBoBnAsAZ[' },
			{ path: 'u}}_GhmvvO?bD' },
			{ path: 'sz}_GllvvO?t@HPFB`A@' },
			{ path: '_y}_GnavvOYN' },
			{ path: 'at~_G~tvvOp@u@fAkA~@}@' },
			{ path: 'u}}_Gv{uvO_AB' },
			{ path: 'ej~_Gp~wvOOTEDE@ICEM' },
			{ path: 'wo~_GtqxvOi@v@Ub@Yl@Qh@k@|B' },
		]
	},
	{
		zip: '60093', cridId: 'C025', medianAge: 51, medianIncome: 130759, residentCount: 196, businessCount: 3, totalCount: 199, type: 'C', pathPolylines: [
			{ path: 'oca`GhiyvO?jDc@?' },
			{ path: 'wp_`G~rxvOQ^?^GZIFm@PKL?HLp@EpA|CITh@VZt@p@' },
			{ path: 'oz_`GfoyvOEwCFiE?qHW_Ac@O_BH' },
			{ path: 'wo``GrrxvOTx@BZElCDbCAdCAlDAnFlGBA{C?mFJqEFUC_AGaJ?oD' },
			{ path: '}w``Gp_yvOiC~Du@jANkBJRXJ' },
			{ path: 'w__`GroyvOBkJCIGCy@EOTMDICGMAIHSJEF@RV' },
			{ path: 'kv``Gt`yvOAdML?m@|DEbC' },
			{ path: 'iv``Gl}xvOs@bAXJVV@gBCo@Gy@UcA[o@Ua@Q[We@w@qAI`@}@lDAHD~CB`A@^@^LzB' },
			{ path: 'au~_GrzxvOSFS?iAAq@?UC_AQeCy@e@A{AGOEMGKKSkA' },
			{ path: '{h_`GpfyvODnA?pAChBEPmH?I?}CC' },
			{ path: '}h_`Gz{yvOkCEE`BMbA?rH' },
			{ path: '}h_`Gz{yvO@wBImB?kADyApC@tC@dBBbA@p@?^Bd@@f@?' },
			{ path: 'e`a`G`hzvOo@JK?]I[]Kg@EoHYAUKMOESHeK' },
			{ path: '}b_`G`}yvOo@Ag@QuA?QQ' },
			{ path: 's{``GfjxvOaAhAt@fC' },
			{ path: 'cga`GtnyvOGrTClD?|BLz@AhBn@|@n@b@XELINW^@PFP`@Lr@@z@' },
			{ path: 'om_`GnvxvOStAK`A' },
			{ path: 'mv``GznyvOaGC_DA' },
			{ path: 's{``GfjxvOtAoAt@q@l@i@' },
			{ path: '_v``GznyvOvA@bC@' },
			{ path: 's_a`Gx{yvOKBILHLNFF?DCDOCIAEECK?' },
			{ path: 'im_`Gt{yvO?}@' },
			{ path: 'cga`GzrzvOO|GB^Rf@N~AVXJVFl@BfB' },
			{ path: 'qba`GdnzvOJRDf@CpAG`A' },
			{ path: '_da`GtxzvOEm@@i@' },
			{ path: 'wr_`GnoyvOEsKOiC?_B' },
			{ path: 'mr_`GnoyvO?fF' },
			{ path: 'kf``Gf|xvOkB~@UV}@v@sAh@' },
			{ path: 'i`a`Gj|yvOcC@' },
			{ path: '}}``G|gyvOQvA?`C' },
			{ path: 'u}``GplxvO{AvAG@IC' },
		]
	},
	{
		zip: '60093', cridId: 'C009', medianAge: 42, medianIncome: 133025, residentCount: 161, businessCount: 145, totalCount: 306, type: 'C', pathPolylines: [
			{ path: 'i_a`G~npvOm@}AKMQAULs@f@SP}@r@YRGRCPRh@]\\' },
			{ path: 'wu``GvnpvOA|AAlBuECe@sA~@o@~DoC?jA' },
			{ path: '_qa`GpqpvO][YYYm@YiA@cB' },
			{ path: 'ou``GddpvOCvAcAx@qB~A_CfBp@jB' },
			{ path: 'uaa`GpkpvOhFgFRF`BoAd@O?rA' },
			{ path: 'kx``G`~pvOeCaH' },
			{ path: 'wu``GjlpvO@iA@cB' },
			{ path: 'uu``Gv}ovO?`@DvAHIDI`A{@nBuAdCsB' },
			{ path: 'ula`GjzpvOl@i@x@m@h@e@d@[XWzB|G' },
			{ path: 'kta`GblpvOg@_@a@U' },
			{ path: 'so``G|spvOw@Zw@JwAA' },
			{ path: '}fa`GpupvOMc@g@ZNb@' },
			{ path: 'aya`GlhpvOYw@U_@' },
			{ path: 'i_a`G~npvOu@l@gCrB[VOa@WT' },
			{ path: '_qa`GpqpvOx@l@VRJJJNJH' },
			{ path: 'sfa`GvspvOOe@' },
		]
	},
	{
		zip: '60093', cridId: 'C005', medianAge: 46, medianIncome: 206692, residentCount: 150, businessCount: 0, totalCount: 150, type: 'C', pathPolylines: [
			{ path: 'mcb`GvbpvObB@KWaAoA' },
			{ path: 'cib`GhgpvOl@`ESLaG|Fm@}B]eBq@}BuB_I' },
			{ path: 'qza`GtepvOCoC?w@NaBNaA^}@p@y@h@W`BKP@hAX_@bAGr@Dt@Nr@ZbAMz@U`Ak@tAgAzA' },
			{ path: 'qbb`GfaqvO_@iBoAmGg@oDKsAbMcAN{@?]Yw@U_@sA]s@Y[OSS' },
			{ path: 'uva`GljpvO{AX{CzLOTsCbC' },
			{ path: 'ejb`GjfqvOeEsOeAlA' },
			{ path: '{fa`GnipvO]oAGi@GqB?qA{BCkB@' },
			{ path: '{oa`GhvovOb@m@Rm@HaAEw@Gi@Q]UQe@O[G' },
		]
	},
	{
		zip: '60093', cridId: 'C012', medianAge: 43, medianIncome: 214304, residentCount: 144, businessCount: 0, totalCount: 144, type: 'C', pathPolylines: [
			{ path: 'el}_GvgkvOqAzAsDdEmApAz@zAFJN?rB]' },
			{ path: 'ss~_GrhlvOb@|@^t@rDcELIJ?j@dAkE`FaGtGIJ@tA?xE' },
			{ path: 'cy}_GnlkvOAlH_KhL_BfBo@n@' },
			{ path: 'ey}_GxzkvO?fH?jH' },
			{ path: 'ek|_GjujvOkPxGO@GAYUy@_BJMbEuEtFmG`AeA' },
			{ path: 'mx~_GbxlvOsAkCi@}@' },
			{ path: '}e}_G~`kvO}BhCiAlA' },
			{ path: '}_}_GnzjvO_EnE' },
			{ path: 'ek|_GjujvOIjN' },
			{ path: 'yv}_GjskvOkApA?zC' },
			{ path: 'or~_GpjlvOqGdH' },
			{ path: 'kt}_GxpkvOCsF' },
		]
	},
	{
		zip: '60093', cridId: 'C007', medianAge: 41, medianIncome: 250030, residentCount: 189, businessCount: 0, totalCount: 189, type: 'C', pathPolylines: [
			{ path: 'kja`GrdtvObC@DmJcE@CgLB{@pE?M`NpAD' },
			{ path: 'ena`GtkrvOlABFkN' },
			{ path: 'yv``G|zrvOcDDyACwEDkEKB_NrE@HgN' },
			{ path: 'ula`GnptvOhECBuJ' },
			{ path: 'ela`GhysvOSB' },
			{ path: 'yw``GxltvO_CCOFELCR?nAChA?f@' },
			{ path: 'sea`GdjsvOB_NJiN' },
			{ path: '{ka`GxzrvO?tFItFeAA' },
			{ path: '{ka`GxzrvOqAC' },
			{ path: 's|``GnptvOwHC' },
		]
	},
	{
		zip: '60093', cridId: 'C010', medianAge: 47, medianIncome: 133979, residentCount: 235, businessCount: 2, totalCount: 237, type: 'C', pathPolylines: [
			{ path: 'od``GdsovOuCxBaAv@' },
			{ path: 'qc``GlrovO]VX~@D?b@Y' },
			{ path: 'w~_`Gh`pvOCqDoIp@' },
			{ path: 'iw_`G~npvOuFAsKB' },
			{ path: '{~_`GrfpvOwIC{DGiBB' },
			{ path: 'qd_`GnmpvOwBC' },
			{ path: 'wg_`G`iovOiB?w@Du@b@uDzCU\\YrBqCtC{@h@wA\\DwBEgA{@sCyAhA' },
			{ path: 'yo_`GdopvOsAA{CC@uMrF@ExMnFD' },
			{ path: 'qs_`GltovOpJBDiG@eB' },
			{ path: 'gw_`Gh`pvOoF?' },
			{ path: 'so_`Gj`pvOnFBB}J' },
			{ path: 'ug_`G|znvO_CbBgA`AcCvBiBzAQL}ErD{BhB' },
		]
	},
	{
		zip: '60093', cridId: 'C027', medianAge: 48, medianIncome: 97111, residentCount: 72, businessCount: 242, totalCount: 314, type: 'C', pathPolylines: [
			{ path: 'ub_`Gh`vvOgBxAeAp@mBtAiCzAqAv@qInEmDhBq@\\[N{@h@c@`@g@j@_@z@EJ]`Ao@xAq@bBm@t@s@n@MLeAb@o@PKBmA?U?@gB' },
			{ path: '_r}_Gn~tvOyAr@OJc@Z' },
			{ path: 'ar{_GxtsvOiQ`KgFfDkBdAwFhCi@Z}R|Kn@dCv@g@' },
			{ path: 'aw~_GvvuvOk@ZOJq@f@w@n@_CjBmAdAN^AtC' },
			{ path: 'ev~_GlvuvO[H' },
			{ path: '{n{_GprsvOiAr@[R' },
			{ path: 'ea{_GzrsvO}@CaAMaDo@e@?M@a@LQLc@TGB' },
			{ path: 'mm~_GpvuvOHuEiCvA' },
			{ path: '_p}_G||tvO_Ap@T`A?xI' },
			{ path: '{n}_Gb`uvOc@eB~BoA' },
			{ path: '}e~_GlkuvO{@d@iDfB' },
			{ path: 'oj}_Gr~tvOyAbA' },
		]
	},
	{
		zip: '60093', cridId: 'C045', medianAge: 41, medianIncome: 138710, residentCount: 200, businessCount: 2, totalCount: 202, type: 'C', pathPolylines: [
			{ path: 'ena`Gj|qvOtA@vDB\\@xDB' },
			{ path: 'ki``Gh|ovOGdIMfBM~@c@hB[r@]b@eAbABuL@{H' },
			{ path: 'mda`Gz}pvO}@BuC@EhNuAD' },
			{ path: 'k~``G~}pvOEfJEfCbB@' },
			{ path: 'av``Gb~pvOiAA_EA{BGeABEtF?|FzD?' },
			{ path: 'wo``GfmqvOBwMmEK?lD' },
			{ path: '{u``GbupvOE~G' },
			{ path: 'so``Gn~pvO@}CAsDbDvAj@|@Rd@^nA~A|HnAlDVZf@NR?' },
			{ path: 'yda`Gr|qvOIfNKhN' },
			{ path: '__``G~vpvOm@?I@aB`Aa@Lo@?]IYGg@a@c@g@]oAGsB@o@' },
			{ path: 'oka`Gl|qvOFaNrEBEbN' },
			{ path: 'aka`G`~pvOE_D' },
			{ path: 'ki``Gh|ovO{@qB' },
			{ path: 'wh``GvmqvOwAoFs@eDUeAECKCg@C' },
		]
	},
	{
		zip: '60093', cridId: 'C044', medianAge: 44, medianIncome: 218102, residentCount: 198, businessCount: 3, totalCount: 201, type: 'C', pathPolylines: [
			{ path: 'ez_`GlwmvOiA~@_E~CyElD' },
			{ path: 'qc``GlrovO[aAM_@_AcCrHgGlJqHLa@AO_@SKw@@s@u@jAsAgAkAqBmBs@' },
			{ path: '}w_`GrumvOgAx@qAcD' },
			{ path: 'ag_`GrkmvOKiBt@s@`AeAtB_CX\\EfICbIuFAiIAmEAjE_DbIiG' },
			{ path: 'aj_`GnenvOV_AbAeAXaArL?' },
			{ path: 'm{~_Gh{lvOoB|BsAoC' },
			{ path: 'uh_`G`vnvOi@wBa@qDS{@aF~G' },
			{ path: 'yq_`Gz}mvOYeAIQSQOEO?g@J' },
			{ path: 'm{~_Gh{lvO~AeBHK`GuG' },
			{ path: 'qx~_G|umvO}EC' },
			{ path: 'mr_`GfwnvO_A_@' },
			{ path: 'ug_`G~~nvO?aC' },
			{ path: 'ag_`GrkmvOCbIA|DDRJRFT@r@' },
			{ path: '_~_`GtjnvOAdA' },
			{ path: '}a_`GrgnvO}EAyAhB' },
			{ path: 'oc_`G|pnvOeDbD' },
			{ path: '}~~_Gf_mvOUXKL' },
			{ path: 'un_`GrynvOc@g@WI[E' },
			{ path: 'oq_`GtumvOBz@@XE`@Y\\SV' },
			{ path: '{f``GfkovOkBeF' },
		]
	},
	{
		zip: '60093', cridId: 'C030', medianAge: 44, medianIncome: 180367, residentCount: 161, businessCount: 82, totalCount: 243, type: 'C', pathPolylines: [
			{ path: 'mp~_G`anvOiE|CeAr@BxABb@FzA' },
			{ path: 'kn~_G`fnvO[kAI_@[s@Ee@BuIaGVBgI}E?uFA' },
			{ path: 'kx~_GramvO?yEAuAsAkC' },
			{ path: 'qx~_G|umvOEhHEjG_H?' },
			{ path: 'ap~_G`olvO@rGpF@' },
			{ path: 'ip~_GtkmvOcG?@aIhG@@_ImEE' },
			{ path: 'mp~_GhhnvOFOHMHKJMJILEnAaAhA}@' },
			{ path: 'qx~_G|umvO}EC' },
			{ path: 'ip~_GtkmvOEnHtFE' },
			{ path: 'ap~_GtamvOG~HpFA' },
			{ path: 'qh~_GtamvOoF?' },
			{ path: 'kx~_GramvOyEE' },
		]
	},
];
