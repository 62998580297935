import { OrderStepStateEnum } from "@plazarre/phoenix-ux-order/lib/esm/core/data-types/order-step-state-enum";
import { FetchCall } from "@plazarre/phoenix.ux.fetch/lib/esm/core/domain/fetch-call";

import { PostCardOrderStepEnum } from "../data-types/postcard-order-step-enum";
import { IOrder } from "../../../ux/pages/order-postcard";
import { Contact } from "./contact";
import { PostCardCustomTypeEnum, PostcardMailingTypeEnum } from "../../../data/order-postcard-data";
import { IImportValidation } from "../interfaces/iimport-validation";
import { IExtraFields } from "../../../ux/templates/mailing-list/mailing-list-import-list";
import { ContactGroup } from "../../../api/google-contacts";
import { Option } from '../../../ux/templates/mailing-list/mailing-list-import-google';
import { IFileTransfer } from "../../../api/domain/file-transfer";

export class PostcardCustomOrder implements IOrder {
    step: PostCardOrderStepEnum = PostCardOrderStepEnum.Type;

    stepStates: OrderStepStateEnum[] = [];
    
    /** These are the combined mailing list and primary sphere contacts */
    contacts: Contact[] = [];

    /** These are the removed contacts from the mailing list screen. Coorespond to contacts */
    removedContacts: Contact[] = [];

    /** These are the  mailing list (file) only contacts */
    mailingListContacts: Contact[] = [];
    
    /** These are the google imported only contacts */
    googleContacts: Contact[] = [];

    googleGroups: FetchCall<ContactGroup[]> = FetchCall.Initialize;

    selectedGoogleContactGroups: Option[] = [];

    postCardType: PostCardCustomTypeEnum = PostCardCustomTypeEnum.Custom;

    /**
     * If the post card type is custom, then this is the description
     */
    postCardCustomType: string = '';

    description: string = '';

    headline: string = '';
    copy: string = '';

    fileTransfers: IFileTransfer[] = [];

    /** The CSV records for the mailing list import data */
    fileRecords: string[] = [];

    /** The filename of the CSV of txt file imported */
    fileName: string = '';

    importValidation: IImportValidation | null = null;

    mappedFields: number[] = [];

    extraFields: IExtraFields[] = [];
    
    mailingType: PostcardMailingTypeEnum = PostcardMailingTypeEnum.Unknown;

    /** The number of postcards if a mailing list isn't supplied */
    postCardCount: number = 100; 

}