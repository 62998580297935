import { useCallback, useEffect, useState } from "react";
import * as uuid from 'uuid';
import { IFileTransfer } from "../api/domain/file-transfer";
import { baseUri, getBearerHeader } from "../api/fetch";

const CHUCKSIZE : number = 65535*15;
const CHUCK_TIMEOUT_SECONDS : number = 10;

const UTIF = require('../api/utif');
const CRC32 = require('crc-32');
const MD5 = require('md5');

let inProcessCount = 0;

export enum FileTransferMessageType {
    NO_STATE = 0,
    SELECTED = 1,
    COMPLETE = 2,
    ERROR = 3
}

export interface FileTransferMessage {
    type: FileTransferMessageType,
    fileTransfer: IFileTransfer
} 

const mapIncommingFileTransfer = (transfer: IFileTransfer) => {
    const fileTransfer : IFileTransfer = {
        file: transfer.file, startOffset: 0, endOffset: CHUCKSIZE, length: transfer.length, isComplete: false, isError: false, errorDescription: '', 
        retryCount: 0, retrySleepUntil: 0, isImageInitialized: false, id: transfer.id, fetchAbortController: null, chunks: [], fetchTime: 0,
        fetchTimeout: null, isFileStartInitialized: false, crc32: transfer.crc32, md5: transfer.md5, blobLocalSource: transfer.blobLocalSource, 
        fileName: transfer.fileName, fileType: transfer.fileType
    };

    return fileTransfer;
}

export const useFileTransfer = (incommingFileTransfers: IFileTransfer[] = []) => {
    const [fileTransfers, setFileTransfers] = useState<IFileTransfer[]>(incommingFileTransfers.map(mapIncommingFileTransfer));

    const handleChange = useCallback((files : File[]) => {

        
        const newFileTransfers: IFileTransfer[] = [];
        files.forEach((newFile) => {
            const findFile = fileTransfers.find(e => e.file.name === newFile.name && e.file.size === newFile.size);
            if (!findFile) {
                newFileTransfers.push(
                    {
                        file: newFile, startOffset: 0, endOffset: CHUCKSIZE, length: newFile.size, isComplete: false, isError: false, errorDescription: '', 
                        retryCount: 0, retrySleepUntil: 0, isImageInitialized: false, id: '', fetchAbortController: null, chunks: [], fetchTime: 0,
                        fetchTimeout: null, isFileStartInitialized: false, crc32: 0, md5: '', blobLocalSource: '', fileName: '', fileType: ''
                    });
            }
        });

        if (newFileTransfers.length) {
            setFileTransfers((prevValues) => {
                const newValues = prevValues.map((newFileTransfer) => {
                    const newFileTransferObject : IFileTransfer = { ...newFileTransfer }; 
                    return newFileTransferObject;
                });

                newFileTransfers.forEach((newFile) => {
                    const findFile = fileTransfers.find(e => e.fileName === newFile.fileName && e.length === newFile.length);
                    if (!findFile) {
                        newValues.push(newFile);
                    }
                });

                return newValues;
            });
        }
    }, [fileTransfers]);

    const renderPDF = useCallback((urlSrc: string, img: HTMLImageElement, fileTransfer: IFileTransfer) => {
        var { pdfjsLib } = globalThis as any;
        pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.mjs';
        
        const pdf = pdfjsLib.getDocument(urlSrc);
        // Using promise to fetch the page
        pdf.promise.then(async (pdf: any) => {
            console.log('pdf.promise');
            const page = await pdf.getPage(1);
            console.log('page', page);
            const viewport = await page.getViewport({ scale: 1.25 });
            const canvas: HTMLCanvasElement | null = document.getElementById('pdf') as HTMLCanvasElement;
            if (!canvas) {
                throw new Error(`Error: unable to get canvas 'pdf'.`);
            }

            const ctx = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            const renderContext = {
                canvasContext: ctx,
                viewport: viewport
            };

            const renderTask = page.render(renderContext);
            renderTask.promise.then(() => {
            const imgSrc = canvas.toDataURL(); //.replace("image/png", "image/octet-stream");;//'image/png');
            img.src = imgSrc;
            });
        });
    }, []);

    const processFileTransfers = useCallback(() => {

        inProcessCount++;
        console.log('processFileTransfers: in process count = ',inProcessCount);

        const buildMD5 = async (buffer: Uint8Array) : Promise<string> => {
            const md5: string = MD5(buffer); 
            return md5;
        }

        const buildUriFromNEF = async(buffer: Uint8Array, img: HTMLImageElement, imgHolder: HTMLDivElement) => {
            img.src = UTIF.bufferToURI(buffer); //ev.target.result
            imgHolder.style.display = 'grid';
            img.style.display = 'inline';
        }

        console.log('process file transfer internal running...');
        /**
         * Initialize the images
         * @param fileTransfer 
         * @param index 
         * @returns 
         */
        const initializeImage = async (fileTransfer: IFileTransfer, index: number) => {
            const imageId = `image${index}`;
            const img = document.getElementById(imageId) as HTMLImageElement; 
    
            console.log('initializeImage:initialize image get element...', fileTransfer.file.name);
            fileTransfer.fileName = fileTransfer.file.name;
            const imageHolderId = `imageholder${index}`
            const imgHolder = document.getElementById(imageHolderId) as HTMLDivElement; 
            if (!img || !imgHolder) {
                console.error('Error initializeImage: unable to locate img or imgHolder.');
                return;
            }

            // Do we already have the image stored locally?
            if (fileTransfer.blobLocalSource) {
                imgHolder.style.display = 'grid';
                img.src = fileTransfer.blobLocalSource;
                img.style.display = 'inline';
                updateFileTransferInitialized(fileTransfer, fileTransfer.crc32, fileTransfer.md5);
                const message : FileTransferMessage = {
                    type: FileTransferMessageType.SELECTED,
                    fileTransfer: fileTransfer
                };
    
                window.parent.postMessage(message, '*');
                return;
            }

            console.log('initializeImage:is nef...', fileTransfer.file.name);
            if (fileTransfer.file.name.toLocaleLowerCase().includes('.nef')) {
            
                var fileReaderNEF = new FileReader();
                fileReaderNEF.onload = async (ev: ProgressEvent<FileReader>) => {
                    if (!ev || !ev.target) {
                        throw new Error('Error: unable to process fileReaderNEF');
                    }
                    
                    const arrayBuffer = new Uint8Array(ev.target.result as ArrayBuffer);
                    buildUriFromNEF(arrayBuffer, img, imgHolder);
                    const crc32 = CRC32.buf(arrayBuffer, 0);
                    const md5 = buildMD5(arrayBuffer);
                    console.log('initializeImage:updateFileTransferInitialized...', fileTransfer.file.name);
                    updateFileTransferInitialized(fileTransfer, crc32, await md5);
                }
                
                console.log('initializeImage:read as array buffer...', fileTransfer.file.name);
                fileReaderNEF.readAsArrayBuffer(fileTransfer.file);
            } else {
                var arrayBuffer = await fileTransfer.file.arrayBuffer();
                var buffer = new Uint8Array(arrayBuffer);
                var crc32 = CRC32.buf(buffer, 0);
                const md5 = buildMD5(buffer);

                imgHolder.style.display = 'grid';
                const urlSrc = URL.createObjectURL(fileTransfer.file);

                if (fileTransfer.file.name.toLocaleLowerCase().includes('.pdf')) {
                    renderPDF(urlSrc, img, fileTransfer);
                } else {
                    img.src = urlSrc;
                }

                img.style.display = 'inline';
                img.onload = () => { }// Remove the file after use // URL.revokeObjectURL(img.src); }

                updateFileTransferInitialized(fileTransfer, crc32, await md5);
            }

            const message : FileTransferMessage = {
                type: FileTransferMessageType.SELECTED,
                fileTransfer: fileTransfer
            };

            window.parent.postMessage(message, '*');
        }

        const updateFileTransferProgress = (fileTransfer : IFileTransfer, data: any) => {
            if (fileTransfer.isComplete) {
                return;
            }

            setFileTransfers((prevValues) => {
                return prevValues.map((fileTransferItem, index) => {
                    if (fileTransferItem.file.name !== fileTransfer.file.name) {
                        return fileTransferItem;
                    }

                    // Clear the timeout
                    if (fileTransferItem.fetchTimeout) {
                        clearTimeout(fileTransferItem.fetchTimeout);
                    }

                    let newStartOffset = fileTransfer.startOffset;
                    let newEndOffset = fileTransfer.endOffset;
                    let isFileStartInitialized = fileTransfer.isFileStartInitialized;
        
                    // Is this the first run
                    if (!fileTransfer.isFileStartInitialized) {
                        // Yes. Say we are dont initializing
                        isFileStartInitialized = true; 
        
                        // Does file already exist? 
                        // If the file already exists and has same length and crc32 checksum, then return finished by setting start length to length
                        if (data === 'file_exists') {
                            newStartOffset = fileTransfer.length;
                        }
                    } else {
                        newStartOffset = fileTransfer.endOffset;
                        newEndOffset = newStartOffset + CHUCKSIZE;
                        if (newEndOffset >= fileTransfer.length) {
                            newEndOffset = fileTransfer.length;
                        }
                        console.log('newStart/newEndendoffset/length - first= ', newStartOffset, newEndOffset, fileTransfer.length);
                    }

                    // Did this call already run?
                    if (newStartOffset < fileTransferItem.startOffset) {
                        console.error('Offset was before. Should cancel.', fileTransferItem.id, index);
                        // fileTransferItem.fetchAbortController?.abort();
                    }

                    // Log the successful chunk in the chunk array to track everything
                    const findChunk = fileTransferItem.chunks.find(e => e.startOffset === fileTransfer.startOffset);
                    if (!findChunk) {
                        fileTransferItem.chunks.push({ startOffset: fileTransfer.startOffset, endOffset: fileTransfer.endOffset});
                    } 

                    // Build a copy of new object
                    const newFileTransferItem: IFileTransfer = {
                        ...fileTransferItem,
                        // Remove the fetch abort. Must make this change or the error won't be processed correctly, because prevValue will be unchanged
                        fetchAbortController: null,
                        fetchTime: 0,
                        fetchTimeout: null,
                    }

                    // Are we done?
                    if (newStartOffset === fileTransfer.length) {
                        // We are completed!
                        newFileTransferItem.isComplete = true;
                        newFileTransferItem.startOffset = fileTransfer.length;
            
                        // Run one more time after is complete in case there are any left
                        // setTimeout(() => processFileTransfers(), 500);
                        console.log(`${newFileTransferItem.file.name} is complete.`);

                        // Create message that is completed
                        const message : FileTransferMessage = {
                            type: FileTransferMessageType.COMPLETE,
                            fileTransfer: fileTransfer
                        };
            
                        window.parent.postMessage(message, '*');
            
                    } else {
                        // No, this was the first run or we need move to the next block
                        newFileTransferItem.isFileStartInitialized = isFileStartInitialized;
                        newFileTransferItem.startOffset = newStartOffset;
                        newFileTransferItem.endOffset = newEndOffset;
                        newFileTransferItem.retryCount = 0;
                    }
        
                    console.log('start/end offset', newFileTransferItem.file.name, newFileTransferItem.startOffset, newFileTransferItem.endOffset, 
                                newFileTransferItem.isComplete, newFileTransferItem.isError, newFileTransferItem.retryCount);
                    return newFileTransferItem;
                });
            });
        }

        const updateFileTransferError = (fileTransfer: IFileTransfer, exception: any) => {
            const retryCount = fileTransfer.retryCount + 1;
            
            setFileTransfers((prevValues) => {
                return prevValues.map((fileTransferItem) => {
                    if (fileTransferItem.file.name !== fileTransfer.file.name) {
                        return fileTransferItem;
                    }

                    console.log(`Updating file transfer error: ${fileTransfer.file.name}, ${retryCount}`);

                    // Clear the timeout for the fetch since we have an error
                    if (fileTransferItem.fetchTimeout) {
                        clearTimeout(fileTransferItem.fetchTimeout);
                    }

                    // Remove the last chuck since we errored
                    const chunks = fileTransferItem.chunks.filter(e => e.startOffset !== fileTransfer.startOffset);

                    // Otherwise build a copy of new object
                    const newFileTransferItem: IFileTransfer = {
                        ...fileTransferItem,
                        chunks,
                        // Remove the fetch abort. Must make this change or the error won't be processed correctly, because prevValue will be unchanged
                        fetchAbortController: null,
                        fetchTime: 0,
                        fetchTimeout: null,
                    }

                    // Do we have an actual error? isError is set after retries are exhaused
                    if (newFileTransferItem.isError) {
                        return newFileTransferItem;
                    }

                    // Increment the retries, but only if one second passed since the last retry
                    newFileTransferItem.retryCount = retryCount;
                    if (newFileTransferItem.retryCount > 0 && Date.now() < newFileTransferItem.retrySleepUntil) {
                        newFileTransferItem.retrySleepUntil++;
                        return newFileTransferItem;
                    }

                    if (retryCount > 3) {
                        newFileTransferItem.isError = true;
                        newFileTransferItem.errorDescription = exception.message;
                        console.log('isError=true', exception, newFileTransferItem.errorDescription);

                        // Send message that we errored sending
                        const message : FileTransferMessage = {
                            type: FileTransferMessageType.ERROR,
                            fileTransfer: fileTransfer
                        };
            
                        window.parent.postMessage(message, '*');
                        return newFileTransferItem;
                    } 

                    // Retry until max retry occur
                    newFileTransferItem.retrySleepUntil = Date.now() + 1000; // wait at least one second before retry
                    return newFileTransferItem;
                });
            });
        }
    
        const updateFileTransferInitialized = async (fileTransfer : IFileTransfer, crc32: number, md5: string) => {
            const id = uuid.v4();
            console.log('updateFileTransferInitialized:setFileTransfer', fileTransfer.file.name, id);
            setFileTransfers((prevValue) => {
                return prevValue.map((fileTransferItem) => {
                    if (fileTransferItem.file.name !== fileTransfer.file.name) {
                        return fileTransferItem;
                    }

                    // Otherwise build a copy of new object
                    const newFileTransferItem : IFileTransfer = {
                        ...fileTransferItem,
                        id: fileTransferItem.id ? fileTransferItem.id : id,
                        isImageInitialized : true,
                        crc32: crc32,
                        md5 : md5,
                     }
                     
                     return newFileTransferItem; 
                });
            });
        }

        const postFileData = (fileTransfer: IFileTransfer, ev: ProgressEvent<FileReader>) => {
            // Push the chuck using fetch
            if (!ev || !ev.target) {
                return new Error(`Error reading result for ${fileTransfer.file.name}`);
            }
    
            if (fileTransfer.fetchAbortController) {
                console.error('fetch abort controller present', fileTransfer.fetchAbortController, fileTransfer.fetchAbortController.signal);
            }
            
            // (*) TODO: if fetch call takes more than x seconds then abort it and try other site (10/1/2022 - pel)
            const abortController = new AbortController();
            const requestInit = getBearerHeader({ method: 'POST', mode: 'cors', signal: abortController.signal })
            const fetchTime = Date.now();
            
            const fetchTimeout = setTimeout(() => {
                const timeoutMessage = `Fetch timeout of ${CHUCK_TIMEOUT_SECONDS} seconds for ${fileTransfer.file.name} ${fileTransfer.startOffset}. Aborting!`;
                console.warn(timeoutMessage);
                abortController.abort(timeoutMessage);
            }, CHUCK_TIMEOUT_SECONDS * 1000);

            const action = fileTransfer.isFileStartInitialized ? 'file-chunk' : 'file-start';
            const startParams = `${fileTransfer.file.name}/${fileTransfer.file.size}/${fileTransfer.crc32}/${fileTransfer.md5}`;
            const chunkParams = `${fileTransfer.startOffset}`;
            let actionUri = `${baseUri}api/upload/${action}/${fileTransfer.id}/`;
            if (!fileTransfer.isFileStartInitialized) {
                actionUri += startParams;
                requestInit.credentials = 'include';
            } else {
                actionUri += chunkParams;
                requestInit.body = ev?.target?.result;
            }

            console.log('postFileData:fetch', actionUri, fileTransfer.file.name);

            const findChunk = fileTransfer.chunks.find(e => e.startOffset === fileTransfer.startOffset && e.startOffset > 0);
            if (findChunk) {
                console.warn('fetch chunck duplicated', fileTransfer.fileName, fileTransfer.startOffset, abortController.signal);
                // setTimeout(() => processFileTransfers(), 1000);
                // return;
            }

            fetch(actionUri, requestInit)
                .then((response) => { 
                    if (!fileTransfer.isFileStartInitialized)
                    {
                        // Start - which also returns 202 (accepted) if file already exists
                        if (response.ok) {
                            return response.status === 202 ? 'file_exists' : response.json();
                        }
                    } else {
                        // Chuck
                        if (response.ok) {
                            return response.json();
                        }
                    }

                    throw new Error(`Fetch response error: ${response.status}, ${response.statusText}`);
                    })
                .then((data) => updateFileTransferProgress(fileTransfer, data))
                .catch((error) => updateFileTransferError(fileTransfer, error));
            

            setFileTransfers((prevValues) => {
                // This is called twice in debug mode
                return prevValues.map((fileTransferItem) => {
                    if (fileTransferItem.file.name !== fileTransfer.file.name) {
                        return fileTransferItem;
                    }

                    // Otherwise build a copy of new object
                    const newFileTransferItem: IFileTransfer = {
                        ...fileTransferItem,
                        fetchAbortController: abortController,
                        fetchTime,
                        fetchTimeout
                    }
                   
                    return newFileTransferItem;
                });
            });
        };

        const updateFileTransferLocalBlobComplete = (fileTransfer: IFileTransfer) => {
            // This is called twice in debug mode
            setFileTransfers((prevValues) => {
                return prevValues.map((fileTransferItem) => {
                    if (fileTransferItem.file.name !== fileTransfer.file.name) {
                        return fileTransferItem;
                    }

                    // Otherwise build a copy of new object
                    const newFileTransferItem: IFileTransfer = {
                        ...fileTransferItem,
                        isComplete: true,
                        startOffset: fileTransferItem.length,
                    }

                    return newFileTransferItem;
                });
            });
        }

        // Only process items that are images, aren't complete, aren't errors and a maximum of [X] (3) images at once
        // if more than 3 images getting http2 errors
        // const fileTransferArray = fileTransfers.filter(e => e.file.type.startsWith('image/') && !e.isComplete && !e.isError).slice(0,2);
        let runningCount = 0;
        console.log('process file transfer internal running, for each...');
        fileTransfers.forEach((fileTransfer, index) => {
            // If we are already loaded locally, the File structure won't be set
            const fileType = fileTransfer.file?.type ?? '';
            if (fileType && !fileType.startsWith('image/') && !fileType.startsWith('application/pdf')) {
                return;
            }

            console.log('process file transfer internal running, is complete/error next...');
            if (fileTransfer.isComplete || fileTransfer.isError) {
                return;
            }

            console.log('process file transfer internal running, finished is complete/error next...');
            if (!fileTransfer.isError && fileTransfer.retryCount > 0 && Date.now() < fileTransfer.retrySleepUntil) {
                // Run again in alloted time
                const timeToSleep = fileTransfer.retrySleepUntil - Date.now();
                console.warn('sleeping until retry ready - now/sleep until/timetosleep', Date.now(), fileTransfer.retrySleepUntil, timeToSleep);
                setTimeout(() => processFileTransfers(), timeToSleep >= 0 ? timeToSleep : 1);
                return;
            }

            console.log('isImageInitialized running next...');
            // Inload the base64 data to display as a source image
            if (!fileTransfer.isImageInitialized) {
                // Don't start posting with fetch until images are initialized.
                initializeImage(fileTransfer, index);
                return;
            }

            if (fileTransfer.blobLocalSource && fileTransfer.isImageInitialized) {
                updateFileTransferLocalBlobComplete(fileTransfer);
                return;
            }

            console.log('runningCount running next...');
            // Anything more than three, we get lots of HTTP2 errors
            if (++runningCount > 2) {
                return;
            }

            console.log('abort controller running next...');
            // Is the fetch already running? If so wait for it to be cleared!
            if (fileTransfer.fetchAbortController) {
                console.log('abort controller present, skipping...');
                return;
            }

            const findChunk = fileTransfer.chunks.find(e => e.startOffset === fileTransfer.startOffset && e.startOffset > 0);
            if (findChunk) {
                console.warn('mail loop: fetch chunck duplicated, skipping...', fileTransfer.fileName, fileTransfer.startOffset, fileTransfer.fetchAbortController);
                return;
            }

            var fileReader = new FileReader();
            fileReader.onload = (ev: ProgressEvent<FileReader>) => {
                if (fileTransfer.fetchAbortController) {
                    console.log(`Waiting for fetch to finish for ${fileTransfer.file.name}`);
                    return;
                }

                postFileData(fileTransfer, ev);
            };

            console.log('read slice up next...');
            var blob = fileTransfer.file.slice(fileTransfer.startOffset, fileTransfer.endOffset);
            console.log('readAsArrayBuffer - fileName/startOffset', fileTransfer.file.name, fileTransfer.startOffset)
            fileReader.readAsArrayBuffer(blob);
        });

        inProcessCount--;
    }, [fileTransfers, renderPDF]);

    // If we have an updated fileTransfer status, go ahead and process
    useEffect(() => {
        console.log('file transfers updated...');
        processFileTransfers();
    }, [fileTransfers, processFileTransfers])

    /** Clears the file transfers */
    const clearFileTransfers = () => {
        setFileTransfers([]);
    }

    return [fileTransfers, handleChange, clearFileTransfers] as const;
}