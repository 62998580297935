import { AppContext } from "@plazarre/phoenix-ux-order/lib/esm/hook/use-application-context";
import React, { PropsWithChildren, useContext } from "react";
import { Navigate, useLocation } from "react-router";

export interface IRequireAuthProps {

}

/** RequireAuth handles login if not already logged in */
export const RequireAuth : React.FC<PropsWithChildren<IRequireAuthProps>> = (props) => {
    const { children } = props;
    const appContext = useContext(AppContext);
    const { isUserLoggedIn } = appContext;
    const location = useLocation();
  
    if (appContext && !isUserLoggedIn) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  
    return children as JSX.Element;
  }