import { Col, Form } from "react-bootstrap";
//import { AsProp } from "react-bootstrap/esm/helpers";

import * as CssType from 'csstype';
import React, { useEffect, useRef, useState } from "react";
//import { setSelectionRange } from "@testing-library/user-event/dist/utils";

type TLength = (string & {}) | 0;

interface IFormControlMoneyProps {
    /** To be displayed after the input box */
    afterInputDisplay?: string,
    /** What to display at the start of the input, e.g., $ or % */
    currency?: string,
    /** Digits after the decimal point */
    digits?: number,
    formGroupAs?: React.ElementType,
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void,
    maxLength?: number,
    name: string,
    value?: string | number,
    setValue: React.Dispatch<React.SetStateAction<number>> | React.Dispatch<React.SetStateAction<string>>,
    validationMessage: string,
    width?: CssType.Property.Width<TLength>
};


// (*) TODO: This probably belongs somewhere else (2/17/2022 - pel)
/**
 * Gets a number as a string with commas and [digits] decimal points
 * @param x The number
 * @param digits The number of digits after the decimal point
 */
 export const numberWithCommas = (x: number | string | undefined, digits: number = 0): string => {
    if (x == null || x === undefined) {
        return '';
    }

    let value : number;
    if (typeof x === 'string') {
        value = Number.parseFloat(x);
    } else {
        value = x;
    }

    return value.toFixed(digits).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// (*) TODO: Should handle number | undefined (11/13/2022 -pel)

export const FormControlMoney : React.FC<IFormControlMoneyProps> = (props) => {
    const { formGroupAs, maxLength, name, onBlur, setValue, value, validationMessage, width } = props;

    const [ isValueInvalid, setIsValueInvalid ] = useState(true);
    const [ inputSelectionStart, setInputSelectionStart ] = useState<number | null>(null);
    const controlRef = useRef<HTMLInputElement>(null);

    const DEFAULT_MAX_LENGTH = 10;
    const isNumber = typeof(value) === 'number';
    const currency = props.currency ?? '$';
    const digits = props.digits ?? 0;
    const afterInputDisplay = props.afterInputDisplay ?? '';

    //const doesExist = isNumber ? value : (value?.length ?? 0) > 0;

    const setValueHandler = (e: React.ChangeEvent<HTMLInputElement>, digits: number) => { //value: number | string) => {
        const value : number | string = e.currentTarget.value;
        console.log('selection start', e.currentTarget.selectionStart, value);
        let valueString : string = '';
        let valueFloat : number = 0;
        if (typeof value === 'string') {
            valueString = value.replace(/,/g, '');
            const valueCommasAndDigits = numberWithCommas(valueString, 2);
            let valueDigitsOnly = numberWithCommas(valueString, 2);
            valueDigitsOnly = valueDigitsOnly.replace(/,/g, '');
            const replaceLengthDifference = valueCommasAndDigits.length - valueDigitsOnly.length;
            console.log('replace', valueCommasAndDigits, valueDigitsOnly, replaceLengthDifference);
            const selectionStart = e?.currentTarget?.selectionStart;
            if (selectionStart != null)
                setInputSelectionStart(selectionStart + replaceLengthDifference);

            const valueFloat = parseFloat(valueString);
            if (valueString === '0' || valueString === '') {
                setIsValueInvalid(true);
            } else if (!Number.isNaN(valueFloat)) {
                setIsValueInvalid(false);
            } else {
                setIsValueInvalid(true);
                const setValueString = (setValue as React.Dispatch<React.SetStateAction<string>>);
                setValueString('');
                return;
            }
        } else {
            console.log('notstring');
        }
        
        if (isNumber) {
            const setValueNumber = (setValue as React.Dispatch<React.SetStateAction<number>>);
            if (setValueNumber) {
                setValueNumber(valueFloat as number);
            }
        } else {
            const setValueString = (setValue as React.Dispatch<React.SetStateAction<string>>);
            if (setValueString) {
                setValueString(valueString as string);
            }
        }
    }

    useEffect(() => {
        if (!controlRef || !controlRef.current)
            return;

        console.log('value changed', value, inputSelectionStart);

        controlRef.current.selectionStart = inputSelectionStart;
        controlRef.current.selectionEnd = inputSelectionStart;
    }, [value, inputSelectionStart]);

    return (
        <Form.Group as={formGroupAs ?? Col} xxl="8" controlId="validationCustom03">
            <div className="d-flex align-items-center">
            <span>{currency}</span>
            <Form.Control
                name={name}
                //type="number"
                pattern="[0-9.,]*" 
                inputMode="decimal"
                autoComplete="nope"
                value={(value ?? 0) <= 0 ? '' : numberWithCommas(value, digits)}
                maxLength={maxLength ?? DEFAULT_MAX_LENGTH}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setValueHandler(e, digits); }}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => { if (onBlur) onBlur(e); }}
                style={{ width: width ?? '80%' }}
                ref={controlRef}
            />
            &nbsp;
            <span>{afterInputDisplay}</span>
            </div>

            <Form.Control.Feedback type="invalid" style={{ display: isValueInvalid ? 'inline' : 'none' }}>
                {validationMessage}
            </Form.Control.Feedback>
        </Form.Group>
    );
}