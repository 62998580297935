import { useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { differenceInDays } from 'date-fns';

import { ColumnDropdown } from '@plazarre/phoenix-ux-order/lib/esm/ux/molecules/column-dropdown';

import { getSplitMasterSchedule, getShops, getSplitSchedules, getSplitAnalysis } from "./api/advisors";
import { IDropdown } from "./core/idropdown";


export const AdvisorBusinessTerms = (): JSX.Element => {
    const [shops, setShops] = useState<Array<any> | null>(null);
    const [splitSchedules, setSplitSchedules] = useState<Array<any> | null>(null);
    const [splitAnalysis, setSplitAnalysis] = useState<Array<any> | null>(null);
    const [commissionMasterSchedule, setCommissionMasterSchedule] = useState<Array<any> | null>(null);
    const [shopSelection, setShopSelection] = useState(0);
    const [splitScheduleSelection, setSplitScheduleSelection] = useState(0);

    const mapCommissionScheduleToJsx = (value: any, index: number, splitAnalysis: any): JSX.Element => {
        const tiers = value.tiers as Array<any>;
        const tiersJsx = tiers.map<JSX.Element>((value, index) => mapTierToJsx(value, index)) ?? [];
        return (
            <>
                <Row xs="6" lg="12">
                    <Col sm={1}>
                        <b>Tier-Level</b>
                    </Col>
                    <Col xs style={{ textAlign: 'right' }}>
                        <b>GCI (in $'s)</b>
                    </Col>
                    <Col xs style={{ textAlign: 'right' }}>
                        <b>Side(s)</b>
                    </Col>
                    <Col xs style={{ textAlign: 'right' }}>
                        <b>Increase %<br/>(Split %)</b>
                    </Col>
                    <Col xs style={{ textAlign: 'left' }}>
                        <b>Advisors</b>
                    </Col>
    
                </Row>
                {tiersJsx}
            </>
        );
    };
    
    const mapTierToJsx = (value: any, index: number): JSX.Element => {
        const advisors = splitAnalysis?.filter((e : any) => e.masterScheduleTierLevel === value.tierLevel) ?? [];
    
        const advisorsJsx : JSX.Element[] = [];
        advisors.forEach((value) => {
            const days = differenceInDays(
                Date.now(),
                new Date(value.advisorStartDate)
              );

            const color = days < 365 ? 'orange' : 'black';
            const title = days < 365 ? `${value.name}. Less than 1 year with E&V` : value.name;
            advisorsJsx.push(
                <>
                    <FontAwesomeIcon icon="circle" color={color} title={ title } />
                    &nbsp;
                </>
            );
        });

        return (
            <Row xs="6" lg="12">
                <Col sm={1}>
                    {String(value.tierLevel).padStart(2, '0')} [{value.advisorLevel}]
                </Col>
                <Col xs style={{ textAlign: 'right' }}>
                    {value.gci.toLocaleString("en-us")}
                </Col>
                <Col xs style={{ textAlign: 'right' }}>
                    {value.sides}
                </Col>
                <Col xs style={{ textAlign: 'right' }}>
                    {value.percentIncrease}% ({value.calculatedPercent}%)
                </Col>
                <Col xs style={{ textAlign: 'left' }}>
                    {advisorsJsx}
                </Col>
            </Row>
        );
    }
    
    useEffect(() => {
        const getShopsCallback = async () => {
            const shopsResult = await getShops();
            if (shopsResult.isSuccess) {
                setShops(shopsResult.value);
            }
        };

        const getSplitSchedulesCallback = async () => {
            const result = await getSplitSchedules();
            if (result.isSuccess) {
                setSplitSchedules(result.value);
            }
        };

        getSplitSchedulesCallback();
        getShopsCallback();
    }, []);

    useEffect(() => {
        const getSplitMasterScheduleCallback = async () => {
            const result = await getSplitMasterSchedule();
            if (result.isSuccess) {
                setCommissionMasterSchedule(result.value);
            }
        };

        const getSplitAnalysisCallback = async() => {
            const result = await getSplitAnalysis();
            if (result.isSuccess) {
                console.log('splitanalysis', result.value);
                setSplitAnalysis(result.value);
            } 
        }

        getSplitMasterScheduleCallback();
        getSplitAnalysisCallback();
    }, [shopSelection]);

    let shopsDropdown: IDropdown[] = [];
    if (shops) {
        shopsDropdown = shops.map<IDropdown>((value, index) => {
            return { index: index, name: value.name };
        });
    }

    let splitScheduleYearDropdown: IDropdown[] = [];
    if (splitSchedules) {
        splitScheduleYearDropdown = splitSchedules.map<IDropdown>((value, index) => {
            return { index: index, name: value.apiName };
        });
    }

    const commissionScheduleJsx = commissionMasterSchedule?.map<JSX.Element>((value, index, splitAnalysis) => mapCommissionScheduleToJsx(value,index,splitAnalysis)) ?? [];

    console.log(commissionMasterSchedule);
    return (
        <Container fluid>
            <Row>
                <Col>
                    Header
                </Col>
            </Row>
            <Row>
                <Col xs={3}>
                    <b>Split Schedule:</b>
                </Col>
                <ColumnDropdown dropdownValues={shopsDropdown} value={shopSelection} colLg={3} setValue={setShopSelection} variant="secondary" />
                &nbsp; &nbsp;
                <ColumnDropdown dropdownValues={splitScheduleYearDropdown} value={splitScheduleSelection} colLg={3} setValue={setSplitScheduleSelection} variant="secondary"/>
            </Row>
            <Row>&nbsp;</Row>
            {commissionScheduleJsx}

        </Container>
    );
}