import { AppContext } from "@plazarre/phoenix-ux-order/lib/esm/hook/use-application-context";
import { FetchCall } from "@plazarre/phoenix.ux.fetch/lib/esm/core/domain/fetch-call";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import Select, { ActionMeta } from "react-select";
import { toast } from "react-toastify";
import { ContactGroup, getGoogleGroupContacts, getGoogleGroups } from "../../../api/google-contacts";
import { LoadStatusType } from "../../../core/postcard/data-types/fetch-load-status-type";
import { Contact } from "../../../core/postcard/domain/contact";

interface IMailingListGoogleProps {
    updateContacts: (contactsUpdated: Contact[]) => void,

    googleGroups: FetchCall<ContactGroup[]>,
    setGoogleGroups: React.Dispatch<React.SetStateAction<FetchCall<ContactGroup[]>>>,

    selectedContactGroups: Option[],
    setSelectedContactGroups: React.Dispatch<React.SetStateAction<Option[]>>,

    isLoadingMailingList: boolean,
    setIsLoadingMailingList: React.Dispatch<React.SetStateAction<boolean>>;
}

export type Option = {
    value: string;
    label: string;
  };

const removeLabels = ['myContacts', 'all'];

export const MailingListImportGoogle : React.FC<IMailingListGoogleProps> = (props) => {
    const { googleGroups, isLoadingMailingList, selectedContactGroups, setGoogleGroups, setIsLoadingMailingList, setSelectedContactGroups, updateContacts} = props;
    const loadStatus = googleGroups?.loadStatus ?? LoadStatusType.Unset;
    const context = useContext(AppContext);
    
    const [options, setOptions] = useState<Option[]>([]);

    const importGroupOnChange = (optionsSelected: readonly Option[], actionMeta: ActionMeta<Option>) => {
        setSelectedContactGroups(optionsSelected as Option[]);
        optionsSelected.forEach(async (option) => {
            const googleGroup = googleGroups.data?.find(e => e.id === option.value);

            if (!googleGroup) 
                throw new Error(`Error:onChange, unable to locate google group: ${option.value}`);
            
            // Do we need to load in the contacts?
            let peopleWithAddresses : Contact[];
            let peopleInGroup : Contact[];
            if (!googleGroup.people.length) {
                // Yes load them in
                const result = await getGoogleGroupContacts(context.userLoginEmail, option.value);
                if (!result.isSuccess || !result.value) {
                    const errorMessage = `Unable to load contacts for group ${option.label}`;
                    toast.error(errorMessage);
                    console.error(errorMessage);
                    return;
                }
                
                peopleInGroup = result?.value ?? [];
                peopleWithAddresses = result.value.filter(e => (e?.addresses?.length ?? 0) > 0) ?? 0;
            } else {
                // No, they are loaded
                peopleInGroup = googleGroup?.people ?? [];
                peopleWithAddresses = googleGroup.people.filter(e => (e?.addresses?.length ?? 0) > 0) ?? 0;
            }
            
            setGoogleGroups((prevGoogleGroups) => {
                const newGoogleGroups = prevGoogleGroups?.data?.map((item) => {
                    if (item.id !== googleGroup.id)
                        return item;

                    const newGoogleGroup = {...item};
                    newGoogleGroup.people = peopleInGroup;
                    newGoogleGroup.countWithAddresses = peopleWithAddresses.length;
                    return newGoogleGroup;
                }) ?? [];

                return FetchCall.Success(newGoogleGroups);
            });
        });
    }

    const setImportGroupOptions = useCallback((contacts: ContactGroup[])=> {
        const newOptions : Option[] = [];
        if (!contacts)
            return;

        contacts.forEach((group) => {
            if (group.count === 0)
                return;

            // Remove labels such as all and myContacts
            if (removeLabels.includes(group.name))
                return;

            newOptions.push({
                value: group.id,
                label: `${group.name} (${group.count})`
            });
        });

        setOptions(newOptions);
    }, []);

    useEffect(() => {
        if (loadStatus !== LoadStatusType.Success || !googleGroups.data || googleGroups.data.length === 0) {
            return;
        }

        // Did we not yet set our import group options?
        if (!options.length) {
            setImportGroupOptions(googleGroups.data);
        }

        if (!selectedContactGroups.length) {
            return;
        }

        // Do we have any selected contact to import?
        const peopleToImport = (googleGroups.data?.flatMap(e => e.people) ?? []).filter(e => (e.addresses?.length ?? 0) > 0);
        if (peopleToImport.length > 0)
            updateContacts(peopleToImport);
    }, [googleGroups, updateContacts, options.length, setImportGroupOptions, loadStatus, selectedContactGroups.length]);

    useEffect(() => {
        const getGoogleContactGroupCallback = async () => {
            
            const result = await getGoogleGroups(context.userLoginEmail);
            if (result.isSuccess) {
                const googleGroups = FetchCall.Success(result.value ?? []);
                setGoogleGroups(googleGroups);
            } else {
                const errorMessage = `Error loading google groups: ${result.errorMessage}.`;
                const googleGroups = FetchCall.Error(errorMessage);
                setGoogleGroups(googleGroups);
                toast.error(errorMessage);
            }
        };

        if (loadStatus === LoadStatusType.Unset) {
            const newGoogleGroups = FetchCall.Initialize;
            newGoogleGroups.loadStatus = LoadStatusType.Loading;
            setGoogleGroups(newGoogleGroups);
            getGoogleContactGroupCallback();
        }
    }, [updateContacts, setGoogleGroups, loadStatus, context.userLoginEmail]);

    let primarySphereInformationJsx = <></>;
    let contactCount = 0;
    setIsLoadingMailingList(false);
    let distinctContacts : Contact[] = [];
    selectedContactGroups.forEach((contactGroup) => {
        const googleGroup = googleGroups?.data?.find(e => e.id === contactGroup.value);
        if (!googleGroup)
            throw new Error(`Unable to find selected group option`);

        contactCount += googleGroup.count;
        if (!googleGroup?.countWithAddresses) {
            setIsLoadingMailingList(true);
            return;
        }

        const peopleWithAddresses = googleGroup.people.filter(e => (e?.addresses?.length ?? 0) > 0);
        distinctContacts = [...distinctContacts, ...peopleWithAddresses];
    });

    const countWithAddresses = distinctContacts.filter(Contact.uniqueValues).length;
    let withAddressesJsx = <></>;
    if ((googleGroups?.data?.length ?? 0) > 0) {
        const countWithAddressesJsx = isLoadingMailingList ? <Spinner size="sm" animation="border" /> : <>{countWithAddresses}</>;
        withAddressesJsx = (
            <>
                {contactCount} contact(s) have been selected.
                &nbsp;
                There are {countWithAddressesJsx} unique selected group contact(s) with postal mailing addresses.
            </>
        );

        primarySphereInformationJsx = (
            <>
            
            <Row>
                <Col>
                <Select
                    isMulti
                    name="colors"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={importGroupOnChange}
                    value={selectedContactGroups}
                    
                />
                </Col>
            </Row>
            <Row>
                <Col>
                    <br/>
                    {withAddressesJsx}
                    <br />
                </Col>
            </Row>
            </>
        );
    }

    return (
        <>
            {loadStatus === LoadStatusType.Success && primarySphereInformationJsx}
            {loadStatus === LoadStatusType.Loading && (<>Please wait loading your Google contact groups...&nbsp;<Spinner animation='border' size="sm" /></>) }
            {loadStatus === LoadStatusType.Error && (<span style={{color:'red'}}><b>Sorry, we are unable to load your primary sphere.</b></span>)}
            {//<MailingList contacts={googleGroups} />
}
            
        </>
    );
}