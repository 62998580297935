import { IDropdownImage } from "@plazarre/phoenix-ux-order/lib/esm/core/interfaces/idropdown-image";

import PostCardCustomImage from '../images/postcard-custom.png';
import PostCardNewToMarketImage from '../images/postcard-new-to-market.png';
import PostCardUnderContractImage from '../images/postcard-under-contract.png';
import PostCardJustSoldImage from '../images/postcard-just-sold.png';

export enum PostCardCustomTypeEnum { Unknown = -1, NewToMarket, UnderContract, JustSold, Custom };



export const postcardCustomTypeDropdownValues: IDropdownImage[] = [
    { index: PostCardCustomTypeEnum.NewToMarket, name: 'New To Market', src: PostCardNewToMarketImage },
    { index: PostCardCustomTypeEnum.UnderContract, name: 'Under Contract', src: PostCardUnderContractImage },
    { index: PostCardCustomTypeEnum.JustSold, name: 'Just Sold', src: PostCardJustSoldImage },
    { index: PostCardCustomTypeEnum.Custom, name: 'Custom', src: PostCardCustomImage },
];

export enum PostcardMailingTypeEnum { Unknown = -1, EDDM, PrimarySphere, CustomSphere, MailingList, PrintedNoPostage, Custom };
export const postcardMailingTypeDropdownValues: IDropdownImage[] = [
    { index: PostcardMailingTypeEnum.EDDM, name: 'EDDM', src: 'dist/images/postcard-new-to-market.png' },
    // { index: PostcardMailingTypeEnum.PrimarySphere, name: 'Your Primary Sphere', src: 'dist/images/postcard-new-to-market.png' },
   // { index: PostcardMailingTypeEnum.CustomSphere, name: 'Custom Sphere', src: 'dist/images/postcard-new-to-market.png' },
    { index: PostcardMailingTypeEnum.MailingList, name: 'Mailing List(s)', src: 'dist/images/postcard-new-to-market.png' },
    { index: PostcardMailingTypeEnum.PrintedNoPostage, name: 'No Addresses', src: 'dist/images/postcard-new-to-market.png' },
];