import { CSSProperties, MouseEventHandler, PropsWithChildren } from "react";

interface LinkProps {
    /** A unique identifier to keep opening the same tab window */
    tabId?: string,

    /** The title to display */
    title?: string,

    /** The url to open */
    url: URL,

    /** The style to use */
    style?: CSSProperties
}

/** Create a link */
export const Link : React.FC<PropsWithChildren<LinkProps>> = (props) => {
    const {children, title, url} = props;

    const style = props.style ?? {
        color: 'blue',
        textDecoration: 'underline',
        cursor: 'pointer'
    };

    const tabId = props.tabId ?? '_blank';
    const onClick : MouseEventHandler<HTMLSpanElement> = (e) => {
        e.preventDefault();
        window.open(url.toString(), tabId);
    }

    if (!children && !title) {
        throw new Error('Link must have either children or a title');
    }

    return (
        <span onClick={onClick} style={style}>
            {title ? title : children}
        </span>
    );
}