import { CSSProperties, PropsWithChildren } from "react";
import { Link } from "./link";

interface SalesForceLinkProps {
    /** A unique identifier to keep opening the same tab window */
    tabId?: string,

    /** The SalesForce id */
    id: string,

    /** The style to use */
    style?: CSSProperties
}

export const SalesForceLink : React.FC<PropsWithChildren<SalesForceLinkProps>> = (props) => {
    const {id, tabId, style} = props;

    /** Gets the SalesForce url to display an given id */
    const getSalesForceUrl = (id: string) : URL => {
        const salesForceUrl = 'https://jenniferames.lightning.force.com/';
        return new URL(`${salesForceUrl}${id}`);
    }

    return (
        <Link url={getSalesForceUrl(id)} tabId={tabId} style={style}>
            {id}
        </Link>
    );
}