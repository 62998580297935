import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Col, Form } from 'react-bootstrap';

import { OrderStepStateEnum } from '@plazarre/phoenix-ux-order/lib/esm/core/data-types/order-step-state-enum';
import { OrderButtons } from '@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-buttons';
import { applyStepStateAdjustment, OrderForm } from '@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form';
import { OrderFormDropdown } from '@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form-dropdown';
import { OrderFormRow } from '@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form-row';
import { IPostCardCustomOrderProps } from '../pages/order-postcard';
import { PostcardCustomOrder } from '../../core/postcard/domain/postcard-custom-order';
import { postcardCustomTypeDropdownValues, PostCardCustomTypeEnum } from '../../data/order-postcard-data';

export const PostcardOrderType: React.FC<IPostCardCustomOrderProps> = (props: IPostCardCustomOrderProps) => {
    const { order, setOrder, updateSlug, id } = props;
    
    const [postCardType, setPostCardType] = useState(order.postCardType);
    const postCardTypeRef = useRef<HTMLDivElement>(null);    

    const [postCardCustomType, setPostCardCustomType] = useState(order.postCardCustomType);
    const postCardCustomTypeRef = useRef<HTMLInputElement>(null);

    const [postCardDescription, setPostCardDescription] = useState(order.description);

    /** Handle if the form is reset */
    useEffect(() => {
        setPostCardType(order.postCardType);
        setPostCardCustomType(order.postCardCustomType);
        setPostCardDescription(order.description);
        postCardTypeRef?.current?.focus();
    }, [order])

    useEffect(() => {
        if (postCardType === PostCardCustomTypeEnum.Custom) {
            postCardCustomTypeRef?.current?.focus();
        }
    }, [postCardType])

    const setOrderFields = (stepAdjustment: number, state: OrderStepStateEnum) => {
        let newOrder = JSON.parse(JSON.stringify(order)) as unknown as PostcardCustomOrder;
        newOrder.stepStates[newOrder.step] = state;

        // Did we hit reset order?
        if (stepAdjustment === -1) {
            newOrder = new PostcardCustomOrder();
        } else {
            newOrder.step += applyStepStateAdjustment(newOrder.step, stepAdjustment, newOrder.stepStates);
            //newOrder.orderEV.requestShopTV = requestShopTV;
            //newOrder.orderEV.headline = requestShopTV ? headline : '';
            // const json = JSON.stringify(newOrder);
            // localStorage.setItem('evc-order', json);
            newOrder.postCardType = postCardType;
            newOrder.postCardCustomType = postCardCustomType;
            newOrder.description = postCardDescription;
        }

        setOrder(newOrder);
        updateSlug(id, newOrder);
    };

    const isValidated = postCardType !== PostCardCustomTypeEnum.Custom || (postCardType === PostCardCustomTypeEnum.Custom && postCardCustomType.length > 0);
    const buttonsJsx = (
        <OrderButtons
            submitLabel="Next"
            submitDisabled={!isValidated}
            skipLabel=""
            backLabel="Reset Order"
            SetOrderFields={setOrderFields}
        />
    );

    const colLg = 4;
    const examplePostcardJsx = <><img key={`image-postcard-${postCardType}`} src={postcardCustomTypeDropdownValues[postCardType].src} alt="Post card" style={{width: '100%'}}></img></>;

    const customTypeJsx = (
        <OrderFormRow header="Custom Type" value="" colLg={colLg - 1} outerColStyle={{ display: 'flex' }} rowStyle={{ marginTop: '1.5em' }} >
            <Form.Group as={Col} xxl="8" controlId="validationCustom04">
                <Form.Control
                    ref={postCardCustomTypeRef}
                    name="postcard_custom_type"
                    autoComplete="nope"
                    value={postCardCustomType}
                    maxLength={40}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { e.preventDefault(); setPostCardCustomType(e.currentTarget.value); }}
                    style={{ width: '80%' }}
                />
                <Form.Control.Feedback type="invalid" style={{ display: postCardCustomType.length > 0 ? 'none' : 'inline'}}>
                    Please provide a description of the post card type.
                </Form.Control.Feedback>
            </Form.Group>
        </OrderFormRow>
    );

    return (
                <OrderForm id="postcard" name="postcard" buttonsJsx={buttonsJsx} exampleJsx={examplePostcardJsx} SetOrderFields={setOrderFields}>
                    <OrderFormDropdown
                        variant="secondary"
                        label="Custom Postcard Type"
                        value={postCardType}
                        dropdownValues={postcardCustomTypeDropdownValues}
                        colLg={colLg}
                        middleColLg={colLg * 2}
                        setValue={setPostCardType}
                        ref={postCardTypeRef}
                    />
                    
                    { postCardType === PostCardCustomTypeEnum.Custom && customTypeJsx }
                    <OrderFormRow header="Postcard Description" value="" colLg={colLg - 1} outerColStyle={{ display: 'flex' }} rowStyle={{ marginTop: '1.5em' }}>
                        <Form.Control
                            as="textarea"
                            rows={8}
                            name="postcard_description"
                            autoComplete="nope"
                            value={postCardDescription}
                            maxLength={2000}
                            
                            onChange={(e) => { e.preventDefault(); setPostCardDescription(e.target.value); }}
                            style={{ width: '45em', height: '10em' }}
                        />
                    </OrderFormRow>
                </OrderForm>
    );
};
