import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { OrderStepStateEnum } from "@plazarre/phoenix-ux-order/lib/esm/core/data-types/order-step-state-enum";
import { OrderButtons } from "@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-buttons";
import { applyStepStateAdjustment, OrderForm } from "@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form";
import { OrderFormDropdown } from "@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form-dropdown";
import { OrderFormRow } from "@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form-row";
import { IDropdown } from "../../core/idropdown";
import { PostcardCustomOrder } from "../../core/postcard/domain/postcard-custom-order";
import { postcardCustomTypeDropdownValues, PostCardCustomTypeEnum, PostcardMailingTypeEnum } from "../../data/order-postcard-data";
import { IPostCardCustomOrderProps } from "../pages/order-postcard";

import StampImage from '../../images/stamp-60.png';
import StampEDDM from '../../images/eddm-retail-indicia.jpg';

export const TemplateOrderPostCardSummary : React.FC<IPostCardCustomOrderProps> = (props) => {
    const { id, order, setOrder, updateSlug } = props;
    const [includePostage, setIncludePostage] = useState(1);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const setOrderFields = (stepAdjustment: number, state: OrderStepStateEnum) => {
        const newOrder = JSON.parse(JSON.stringify(order)) as unknown as PostcardCustomOrder;
        newOrder.stepStates[newOrder.step] = state;
    
        newOrder.step += applyStepStateAdjustment(newOrder.step, stepAdjustment, newOrder.stepStates);
        //newOrder.orderEV.requestShopTV = requestShopTV;
        //newOrder.orderEV.headline = requestShopTV ? headline : '';
        //const json = JSON.stringify(newOrder);
        //localStorage.setItem('evc-order', json);
        setOrder(newOrder);
        updateSlug(id, newOrder);
    };

    const buttonsJsx = (
        <OrderButtons
            submitLabel="Submit Order"
            skipLabel=""
            backLabel="Back"
            SetOrderFields={setOrderFields}
        />
    );

       
    
    let postCardType = `${PostCardCustomTypeEnum[order.postCardType]}`;
    if (order.postCardType === PostCardCustomTypeEnum.Custom) {
        postCardType += ` / ${order.postCardCustomType}`;
    }

    const colLg = 3;
    const includePostageDropdownValues : IDropdown[] = [{ name: 'No', index: 0}, {name: 'Yes', index: 1}]
    const description = order.description.length > 0 ? order.description : '[NONE]';
    const copy = order.copy.length > 0 ? order.copy : '[NONE]';
    // const STANDARD_POSTCARD_POSTAGE = 0.44;
    const OVERSIZED_POSTCARD_POSTAGE = 0.60;

    // const STANDARD_POSTCARD_COST = 0.10;
    const OVERSIZED_POSTCARD_COST = 0.10;

    useEffect(() => {
        const canvasElement = canvasRef.current;
        if (!canvasElement)
            return;

        const postageImage = new Image();
        postageImage.src = order.mailingType === PostcardMailingTypeEnum.EDDM ? StampEDDM : StampImage;
        postageImage.onload = () => {

        };
        
        const image = new Image();
        image.src = postcardCustomTypeDropdownValues[order.postCardType].src;
        image.onload = (e: Event) => {
            const pixelWidthImage = image.width;
            const pixelHeightImage = image.height;
            var parentDiv = document.getElementById('canvas-div');
            if (!parentDiv) {
                console.error('Error unable to get parent div canvas-div');
                return;
            }
            
            let devicePixelRatio = window.devicePixelRatio           // DPR: How many device pixels per CSS pixel (can be fractional)

            const cssWidth = parentDiv.clientWidth;           // CSS dimensions of canvas
            const cssHeight = parentDiv.clientHeight;
            const pixelWidth = Math.round(devicePixelRatio * cssWidth);    // Dimensions we should set the backing buffer to.
            let pixelHeight = Math.round(devicePixelRatio * cssHeight);

            const widthToHeightImageRatio = pixelWidthImage / pixelHeightImage;

            canvasElement.width = parentDiv.clientWidth;
            canvasElement.height = parentDiv.clientWidth / widthToHeightImageRatio;
            canvasElement.style.display = 'inline';

            const ctx = canvasElement.getContext('2d');
            if (!ctx)
                return;
            
            // set the size of the canvas to the client width & height
            // ctx.scale(devicePixelRatio, devicePixelRatio);

            // Sometimes we don't have the height yet, so then use the parent info
            if (pixelHeight === 0) {
                pixelHeight = canvasElement.height * devicePixelRatio;
            }

            const drawWidth = (parentDiv.clientWidth / pixelWidth) * pixelWidth;
            const drawHeight = (parentDiv.clientHeight / pixelHeight) * pixelHeight;
            ctx.drawImage(image, 0, 0, drawWidth, drawHeight);

            const fontSize = drawHeight / 65.125, fontFamily = 'Arial', color = 'black', textAlign = 'left', textBaseline = 'top';
            const startingPointX = drawWidth * .67;
            const startingPointY = drawHeight * .75;
            const lineSizeY = drawHeight / 48;
            ctx.beginPath();
            ctx.font = fontSize + 'px ' + fontFamily;
            ctx.textAlign = textAlign;
            ctx.textBaseline = textBaseline;
            ctx.fillStyle = color;

            let cityStateZip: string;

            if (order.mailingType === PostcardMailingTypeEnum.EDDM) {
                ctx.fillText('Local', startingPointX, startingPointY+lineSizeY);
                cityStateZip = 'Postal Customer';
            } else {
                const addressIndex = order.contacts[0].selectedAddressIndex;
                ctx.fillText(order.contacts[0].name, startingPointX, startingPointY);
                ctx.fillText(order.contacts[0].addresses[addressIndex].streetAddress, startingPointX, startingPointY+lineSizeY);
                const address = order.contacts[0].addresses[addressIndex];
                cityStateZip = `${address.city ?? ''}, ${address.state ?? ''}  ${address.postalCode ?? ''}  ${address.country ?? ''}`
            }

            ctx.fillText(cityStateZip, startingPointX, startingPointY+(lineSizeY*2));

            if (includePostage === 1) {
                ctx.drawImage(postageImage, drawWidth * 0.87, drawHeight * 0.51, lineSizeY*4.5, lineSizeY*4.5);
            }
            
            ctx.stroke();
        };

        
        
    }, [includePostage, order.contacts, order.postCardType, order.mailingType]);


    const exampleJsx = (
        <>
        <Row style={{marginBottom: '1rem'}}>
            <Col>
                <b>Postcard Example:</b>
            </Col>
        </Row>
        <Row>
            <Col id='canvas-div' xxl={12}>
                <canvas ref={canvasRef} width="100%" height="100%" style={{border:'1px gray solid', display: 'none'}}/>
            </Col>
        </Row>
        </>
    );

    let mailListString = `${order.contacts.length} contact(s)`;
    if (order.mailingType === PostcardMailingTypeEnum.EDDM) {
        mailListString = `EDDM Route(s), ${order.postCardCount} postcard(s)`
    } else {
        mailListString = `No mailing list, ${order.postCardCount} postcard(s) requested`
    }

    let orderCostString;
    if (order.mailingType === PostcardMailingTypeEnum.MailingList) {
        orderCostString = `$${(order.contacts.length * ((OVERSIZED_POSTCARD_POSTAGE * includePostage) + OVERSIZED_POSTCARD_COST)).toFixed(2)}`;
    } else {
        const postageCost = order.mailingType === PostcardMailingTypeEnum.EDDM ? 0.187 : OVERSIZED_POSTCARD_POSTAGE;
        orderCostString = `$${(order.postCardCount * ((postageCost * includePostage) + OVERSIZED_POSTCARD_COST)).toFixed(2)} ($${postageCost.toFixed(3)} each)`;
    } 

    return (
        <>
            <OrderForm id="postcard" name="postcard" buttonsJsx={buttonsJsx} exampleJsx={exampleJsx} SetOrderFields={setOrderFields}>
                <OrderFormRow header="Postcard Type" value="" colLg={colLg} >
                    {postCardType}
                </OrderFormRow>

                <OrderFormRow header="Postcard Description" value="" colLg={colLg}>
                    {description}
                </OrderFormRow>

                <OrderFormRow header="Headline" value="" colLg={colLg}>
                    {order.headline}
                </OrderFormRow>

                <OrderFormRow header="Copy" value="" colLg={colLg}>
                    {copy}
                </OrderFormRow>

                <OrderFormRow header="Design Files" value="" colLg={colLg}>
                    {order.fileTransfers.length} file(s)
                </OrderFormRow>

                <OrderFormRow header="Mailing List" value="" colLg={colLg}>
                    {mailListString}
                </OrderFormRow>

                { order.mailingType !== PostcardMailingTypeEnum.EDDM &&
                    <OrderFormDropdown
                            variant="secondary"
                            label="Include Postage"
                            value={includePostage}
                            dropdownValues={includePostageDropdownValues}
                            colLg={colLg+1}
                            middleColLg={colLg}
                            setValue={setIncludePostage}
                            rowStyle={{display:'flex', alignItems: 'center' }}
                        />
                }
                <OrderFormRow header="Order Cost" value="" colLg={colLg}>
                    {orderCostString}
                </OrderFormRow>

            </OrderForm>

        </>
    );
}
