import { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import { Point } from "../../core/postcard/domain/point";


interface MediaProps {
    mediaSizeInches?: Point,
    mediaTitle: string
}

export const Media : React.FC<MediaProps> = (props) => {
    const [mediaSizePixels, setMediaSizePixels] = useState<Point>({x: 0, y: 0});
    const mediaRef = useRef<HTMLDivElement>(null);

    const {mediaSizeInches, mediaTitle} = props;

    useEffect(() => {
        if (!mediaRef || !mediaRef.current)
            return;

        const mediaDiv = mediaRef.current;

        // If media size is unknown (not set), go with 5 x 5.
        const newMediaSizeInches : Point = mediaSizeInches ?? { x: 5, y: 5};

        const pixelsPerInch = Math.round(mediaDiv.clientWidth / newMediaSizeInches.x);
        const mediaSizeX = pixelsPerInch * newMediaSizeInches.x;
        const mediaSizeY = pixelsPerInch * newMediaSizeInches.y;
        setMediaSizePixels({x: mediaSizeX, y: mediaSizeY});
    }, [mediaRef, mediaSizeInches]);

    return (
        <Col lg={12} ref={mediaRef}>
            <div className="d-flex align-items-center justify-content-center" style={{ backgroundColor: 'lightgray', width: `${mediaSizePixels.x}px`, height: `${mediaSizePixels.y}px` }} >
                <span className="text-center">
                    {mediaTitle}
                    <br />
                    ({mediaSizeInches?.x ?? '?'}&quot; x {mediaSizeInches?.y ?? '?'}&quot;)
                </span>
            </div>
        </Col>
    );
}