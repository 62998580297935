import { MarketingOrder, PropertyDisplayResult } from "@plazarre/phoenix.javascript.approval";
import { WaveBillEditLink } from "../../atoms/link-wave-bill-edit";

export interface InvoiceBillDetailsProps {
    selectedProperty?: PropertyDisplayResult;
}

export interface InvoiceBillDetailItemProps {
    name: string;
    value: string;
}

export const InvoiceBillDetailItem: React.FC<InvoiceBillDetailItemProps> = (props) => {
    return (
        <div>
            <b>{props.name}</b>
            &nbsp;
            {props.value}
            <br />
        </div>
    )
}

export const InvoiceBillDetails: React.FC<InvoiceBillDetailsProps> = (props) => {
    const { selectedProperty } = props;

    // If we don't have a selected property, we have nothing to show
    if (!selectedProperty) {
        return null;
    }

    let totalOutstandingOverageInvoiceAmountString = '';
    let totalOverageAmountAlreadyInvoicedString = '';
    if (selectedProperty) {
        // Add all the overage invoice amounts together for each order
        let  totalOutstandingOverageInvoiceAmount = selectedProperty.orders?.reduce((previousValue, currentValue) => {
            let subTotal = (currentValue.serviceCostsOutstanding ?? 0) - (currentValue?.invoiceOverageCost ?? 0);
            if (subTotal < 0) {
                subTotal = 0;
            }
            return previousValue + subTotal;
        }, 0);

        // Don't show negative numbers. That's our profit.
        if (totalOutstandingOverageInvoiceAmount < 0) {
            totalOutstandingOverageInvoiceAmount = 0;
        }
        
        totalOutstandingOverageInvoiceAmountString = totalOutstandingOverageInvoiceAmount.toLocaleString("en-US", { style: "currency", currency: "USD" })

        let totalOverageAmountAlreadyInvoiced = selectedProperty.orders?.reduce((previousValue: any, currentValue: any) => {
            const invoicesOverageCost = currentValue?.invoicesOverageCost ?? 0;
            return previousValue + invoicesOverageCost;
        }, 0);

        // Don't show negative numbers. That's our profit.
        if (totalOverageAmountAlreadyInvoiced < 0) {
            totalOverageAmountAlreadyInvoiced = 0;
        }

        totalOverageAmountAlreadyInvoicedString = totalOverageAmountAlreadyInvoiced.toLocaleString("en-US", { style: "currency", currency: "USD" });
    }

    console.log('selectedProperty', selectedProperty);
    type ServiceCostsType = {
        [key: string] : number;
   }

    const mapOrderDetailJsx = (serviceCosts: ServiceCostsType) => {
        const serviceCostsActualJsx = Object.entries(serviceCosts).map((services) => {

            const costString = services[1].toLocaleString("en-US", { style: "currency", currency: "USD" });
            return (
                <InvoiceBillDetailItem name={services[0]} value={costString}/>
            );
        });

        return serviceCostsActualJsx;
    }


    const mapOrderJsx = (order: MarketingOrder) => {

        const serviceCostsOutstanging = order.serviceCostsOutstanding >= 0 ? order.serviceCostsOutstanding : 0;
        const serviceCostsOutstangingString = serviceCostsOutstanging.toLocaleString("en-US", { style: "currency", currency: "USD" });

        return (
            <>
                <InvoiceBillDetailItem name="Order" value={`${(order as any).evOrderId} (${order.id}}`}/>
                <InvoiceBillDetailItem name="Order Date" value={new Date(order.created).toLocaleDateString('en-US')} />
                <br/>
                <div>
                    <b>Actual Service Costs Sub-Totals (By Type):</b><br />
                    { mapOrderDetailJsx(order.serviceCostsActual) }
                </div>
                <div>
                    <br />
                    <b>Expected Service Value Sub-Totals (By Type):</b>
                    { mapOrderDetailJsx(order.serviceCostsExpected) }
                </div>
                <div>
                    <br />
                    <b>Outstanding Overage Invoice Amount Sub-Total for Order:</b>
                    &nbsp;
                    {serviceCostsOutstangingString}
                <br />
                <hr />
                </div>
            </>
        );
    }

    return (
        <div>
            <div>
                <InvoiceBillDetailItem name="Total Overage Amount Outstanding (Needing Invoicing)" value={totalOutstandingOverageInvoiceAmountString} />
                <InvoiceBillDetailItem name="Total Overage Amount Already Invoiced" value={totalOverageAmountAlreadyInvoicedString} />
                <br/>
            </div>
            { selectedProperty && selectedProperty.orders?.map(mapOrderJsx) }
            <div>
                <br />
                <b>Current Bill(s) Accross All Orders:</b>
                {
                    selectedProperty && selectedProperty.bills?.map((value, index) => {
                        return (
                            <div>
                                <b>Provider:</b>
                                &nbsp;
                                {value.vendor.name}<br />
                                <b>Bill Number:</b>
                                &nbsp;
                                {
                                    value?.id ? <WaveBillEditLink internalBillId={value.billId.toString()} billId={value.billNumber} tabId="wave-bill-edit" /> : <></>}
                                <br />
                                <b>Bill Date:</b>
                                &nbsp;
                                {value.billDate ? new Date(value.billDate).toLocaleDateString('en-US') : ''}
                                <br />
                                <b>E&V Order Id:</b>
                                &nbsp;
                                {(value as any).purchaseOrderNumber}
                                <br />
                                <b>Cost:</b>
                                &nbsp;
                                {value.total?.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                                <br />
                                <br />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}