
export enum ResultType { Unknown, Success, Error };
export class Result<T> {
    resultType: ResultType;
    value: T | null;
    errorMessage: string;

    constructor(resultType: ResultType, value: T | null, errorMessage: string)
    {
        this.resultType = resultType;
        this.value = value;
        this.errorMessage = errorMessage;
    }

    get isSuccess() : boolean {
         return this.resultType === ResultType.Success;
    }

    static Error(errorMessage: string) : Result<any> {
        console.error(errorMessage);
        return new Result(ResultType.Error, null, errorMessage);
    }

    static Success(value: any) {
        return new Result(ResultType.Success, value, '');
    }
}