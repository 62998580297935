import { useLocation } from "react-router";

export const CoverSheetPage = (): JSX.Element => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);

    return (
        <div>
            <img style={{width: '100%'}} src={query.get('url') ?? ''} alt="cover sheet" />
        </div>
    );
}