import { useState, CSSProperties } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ButtonSpinner } from "@plazarre/phoenix-ux-order/lib/esm/ux/atoms/button-spinner";

export interface RefreshButtonProps<T> {
    update: (object: any) => Promise<void>;
    object: T;
}

type ReactFCRefreshButton<T = {}> = React.FC<RefreshButtonProps<T>>;  

/** Creates a refresh button */
export const RefreshButton : ReactFCRefreshButton = <T, >(props: RefreshButtonProps<T>) => {
    const { object, update } = props;

    const [isRefreshing, setIsRefreshing] = useState(false);
    const style : CSSProperties = isRefreshing ? { margin: '-0.25rem', padding: '0px 0px 0px 9px'}
                                               : { margin: '-0.25rem', padding: '0px 6px 0px 6px' };


    const onClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIsRefreshing(true);
        await update(object);
        setIsRefreshing(false);
    };

    return(
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Refresh property</Tooltip>}>
        <span className="d-inline-block">
            <ButtonSpinner id="refresh_button" variant='outline-secondary'
                waitingIcon={isRefreshing}
                disabled={isRefreshing}
                onClick={onClick}
                style={style}
                >
                {!isRefreshing && <FontAwesomeIcon icon="sync" size="1x" style={{ cursor: 'pointer', paddingTop: 0, marginTop: '-0.5rem' }} />}
            </ButtonSpinner>
            </span>
        </OverlayTrigger>
    );
}