import { Accordion, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IUseFetchOptions, useFetchJSON } from "@plazarre/phoenix.ux.fetch/lib/esm/hook/use-fetch-json";

interface ITheme {
    id: number;
    theme: string;
    original_run_timeframe: string;
    reference_date: Date;
    customization_needed: number;
    created: Date;
    modified: Date;
    deleted?: Date;
    category?: string;
}

const themes : ITheme[] = [
    {
        "id": 1,
        "theme": "Marketing",
        "original_run_timeframe": "Jan/Feb 2022",
        "reference_date": new Date("2022-01-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 2,
        "theme": "Buyers",
        "original_run_timeframe": "Feb/Mar 2022",
        "reference_date": new Date("2022-02-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 3,
        "theme": "Find Your Inner",
        "original_run_timeframe": "Mar/Apr 2022",
        "reference_date": new Date("2022-03-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 4,
        "theme": "Spotlight On",
        "original_run_timeframe": "Apr/May/Jun 2022",
        "reference_date": new Date("2022-04-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 5,
        "theme": "What Home Means",
        "original_run_timeframe": "Jun/Jul 2022",
        "reference_date": new Date("2022-06-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 6,
        "theme": "Second Homes",
        "original_run_timeframe": "Jul/Aug 2022",
        "reference_date": new Date("2022-07-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 7,
        "theme": "Renovate",
        "original_run_timeframe": "Aug/Sep 2022",
        "reference_date": new Date("2022-08-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 8,
        "theme": "Success",
        "original_run_timeframe": "Sep/Oct 2022",
        "reference_date": new Date("2022-09-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    /*
    {
        "id": 9,
        "theme": "Push Your Limits",
        "original_run_timeframe": "Oct/Nov 2022",
        "reference_date": new Date("2022-10-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z"),
        "category": "Recruiting",
    },
    */
    {
        "id": 10,
        "theme": "Name Your Adventure",
        "original_run_timeframe": "Nov/Dec 2022",
        "reference_date": new Date("2022-11-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 11,
        "theme": "E&V Brand",
        "original_run_timeframe": "Dec 2022/Jan 2023",
        "reference_date": new Date("2022-12-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 12,
        "theme": "Simple Pleasures - New Year, New Goals",
        "original_run_timeframe": "Jan 2023",
        "reference_date": new Date("2023-01-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 13,
        "theme": "Home is Where the Heart Is",
        "original_run_timeframe": "Feb/Mar 2023",
        "reference_date": new Date("2023-02-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 14,
        "theme": "Spring Break",
        "original_run_timeframe": "Mar 2023",
        "reference_date": new Date("2023-03-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z"),
    },
    {
        "id": 15,
        "theme": "Live Your Luxury",
        "original_run_timeframe": "Apr 2023",
        "reference_date": new Date("2023-04-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 16,
        "theme": "Why Engel & Völkers",
        "original_run_timeframe": "May 2023",
        "reference_date": new Date("2023-05-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 17,
        "theme": "Spotlight on Shops",
        "original_run_timeframe": "Jun 2023",
        "reference_date": new Date("2023-06-01"),
        "customization_needed": 1,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 18,
        "theme": "Focus on the Water",
        "original_run_timeframe": "Jul 2023",
        "reference_date": new Date("2023-07-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 19,
        "theme": "Buyer and Seller Guides",
        "original_run_timeframe": "Aug 2023",
        "reference_date": new Date("2023-08-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 20,
        "theme": "Luxury Homebuyer Personas",
        "original_run_timeframe": "Sep 2023",
        "reference_date": new Date("2023-09-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 21,
        "theme": "Iconic Marketing",
        "original_run_timeframe": "Oct 2023",
        "reference_date": new Date("2023-10-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 22,
        "theme": "Get Your Home Ready For Winter",
        "original_run_timeframe": "Nov 2023",
        "reference_date": new Date("2023-11-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 23,
        "theme": "New Year's Resolutions",
        "original_run_timeframe": "Dec 2023",
        "reference_date": new Date("2023-12-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 24,
        "theme": "Move or Renovate?",
        "original_run_timeframe": "Jan 2024",
        "reference_date": new Date("2024-01-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 25,
        "theme": "How to Get Your Home to Stand Out",
        "original_run_timeframe": "Feb 2024",
        "reference_date": new Date("2024-02-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 26,
        "theme": "Home Edition",
        "original_run_timeframe": "Mar 2024",
        "reference_date": new Date("2024-03-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 27,
        "theme": "Dream  Homes",
        "original_run_timeframe": "Mar/Apr 2024",
        "reference_date": new Date("2024-04-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 28,
        "theme": "Global Network",
        "original_run_timeframe": "May 2024",
        "reference_date": new Date("2024-05-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    },
    {
        "id": 29,
        "theme": "Current Events - Summer",
        "original_run_timeframe": "Jun 2024",
        "reference_date": new Date("2024-06-01"),
        "customization_needed": 0,
        "created":  new Date("2023-05-28T00:00:00Z"),
        "modified":  new Date("2023-05-28T00:00:00Z")
    }
];

interface ITimeframe {
    timeframe: string;
}

interface IThemeTemplate {
    theme_id: number;
    templates_rezora_id: number;
    title: string;
    icons?: string[];
    timeframe?: ITimeframe;
}

const themeTemplates : IThemeTemplate[] = [
    {
        "theme_id": 1,
        "templates_rezora_id": 72,
        "title": "CNSS Advisors - Unique Marketing Tools"
    },
    {
        "theme_id": 1,
        "templates_rezora_id": 74,
        "title": "CNSS Advisors - Redefining Wealth"
    },
    {
        "theme_id": 1,
        "templates_rezora_id": 75,
        "title": "CNSS Advisors - Personalized Marketing"
    },
    {
        "theme_id": 1,
        "templates_rezora_id": 122,
        "title": "CNSS Advisors - Global Network"
    },
    {
        "theme_id": 2,
        "templates_rezora_id": 102,
        "title": "CNSS Advisors - Partnership"
    },
    {
        "theme_id": 2,
        "templates_rezora_id": 103,
        "title": "CNSS Advisors - Reaching Buyers Online"
    },
    {
        "theme_id": 2,
        "templates_rezora_id": 104,
        "title": "CNSS Advisors - Curated Approach"
    },
    {
        "theme_id": 3,
        "templates_rezora_id": 100,
        "title": "CNSS Advisors - Find Your Inner Athlete"
    },
    {
        "theme_id": 4,
        "templates_rezora_id": 91,
        "title": "CNSS Advisors - Spotlight on Paris, France"
    },
    {
        "theme_id": 5,
        "templates_rezora_id": 85,
        "title": "CNSS Advisors - What Home Means to You - Peace of Mind"
    },
    {
        "theme_id": 6,
        "templates_rezora_id": 82,
        "title": "CNSS Advisors - Second Homes - Wine Country"
    },
    {
        "theme_id": 7,
        "templates_rezora_id": 78,
        "title": "CNSS Advisors - Renovate Your Kitchen"
    },
    {
        "theme_id": 8,
        "templates_rezora_id": 95,
        "title": "CNSS Advisors - Measuring Success - Buying a Home"
    },
    {
        "theme_id": 9,
        "templates_rezora_id": 92,
        "title": "CNSS Recruiting - Push Your Limits"
    },
    {
        "theme_id": 10,
        "templates_rezora_id": 70,
        "title": "CNSS Advisors - Name Your Adventure - Unchartered Waters"
    },
    {
        "theme_id": 11,
        "templates_rezora_id": 66,
        "title": "CNSS Advisors - Brand Essence"
    },
    {
        "theme_id": 12,
        "templates_rezora_id": 25,
        "title": "CNSS Advisors - Simple Pleasures - Skiing",
        "icons": [ "canedar" ],
        "timeframe": { "timeframe": "Anytime in January" }
    },
    {
        "theme_id": 13,
        "templates_rezora_id": 63,
        "title": "CNSS Advisors - Home for the Super Bowl",
        "icons": [ "canedar" ],
        "timeframe": { "timeframe": "Exactly on February 11, 2024" }

    },
    {
        "theme_id": 14,
        "templates_rezora_id": 59,
        "title": "CNSS Advisors - Tropical Retreat",
        "icons": [ "canedar" ],
        "timeframe": { "timeframe": "Anytime in the Spring" }
    },
    {
        "theme_id": 15,
        "templates_rezora_id": 56,
        "title": "CNSS Advisors - Live Your Luxury - City"
    },
    {
        "theme_id": 16,
        "templates_rezora_id": 53,
        "title": "CNSS Advisors - Why E & V - European Brand"
    },
    {
        "theme_id": 17,
        "templates_rezora_id": 49,
        "title": "CNSS Advisors - Spotlight on Chicago"
    },
    {
        "theme_id": 18,
        "templates_rezora_id": 39,
        "title": "CNSS Advisors - Bodies of Water"
    },
    {
        "theme_id": 19,
        "templates_rezora_id": 46,
        "title": "CNSS Advisors - Home Buyer Guide"
    },
    {
        "theme_id": 20,
        "templates_rezora_id": 43,
        "title": "CNSS Advisors - Personas - The Year-round Vacationer"
    },
    {
        "theme_id": 21,
        "templates_rezora_id": 36,
        "title": "CNSS Advisors - Iconic Marketing"
    },
    {
        "theme_id": 22,
        "templates_rezora_id": 32,
        "title": "CNSS Advisors - Fall Cleanup",
        "icons": [ "canedar" ],
        "timeframe": { timeframe: "Fall" },
    },
    {
        "theme_id": 23,
        "templates_rezora_id": 26,
        "title": "CNSS Advisors - New Year Resolutions - Time to Sell",
        "icons": [ "canedar" ],
        "timeframe": { timeframe: "January" },
    },
    {
        "theme_id": 24,
        "templates_rezora_id": 123,
        "title": "CNSS Advisors - Move or Renovate"
    },
    {
        "theme_id": 25,
        "templates_rezora_id": 116,
        "title": "CNSS Advisors - The Importance of Pricing"
    },
    {
        "theme_id": 26,
        "templates_rezora_id": 113,
        "title": "CNSS Advisors - March Madness",
        "icons": [ "canedar" ],
        "timeframe": { timeframe: "The first Monday in March" }
    },
    {
        "theme_id": 27,
        "templates_rezora_id": 110,
        "title": "CNSS Advisors - Waterfront Luxury"
    },
    {
        "theme_id": 28,
        "templates_rezora_id": 107,
        "title": "CNSS Advisors - Local Service, Global Access"
    },
    {
        "theme_id": 28,
        "templates_rezora_id": 73,
        "title": "CNSS Advisors - Global Network"
    },
    {
        "theme_id": 26,
        "templates_rezora_id": 112,
        "title": "CNSS Advisors - March Madness - Modern vs. Traditional",
        "icons": [ "canedar" ],
        "timeframe": { timeframe: "The second Monday in March" },
    },
    {
        "theme_id": 27,
        "templates_rezora_id": 109,
        "title": "CNSS Advisors - Mountain Luxury"
    },
    {
        "theme_id": 25,
        "templates_rezora_id": 117,
        "title": "CNSS Advisors - Make Your Home Appeal to Buyers"
    },
    {
        "theme_id": 24,
        "templates_rezora_id": 120,
        "title": "CNSS Advisors - Cost of Moving"
    },
    {
        "theme_id": 23,
        "templates_rezora_id": 27,
        "title": "CNSS Advisors - New Year Resolutions - Time to Laugh",
        "icons": [ "canedar" ],
        "timeframe": { timeframe: "January" },
    },
    {
        "theme_id": 21,
        "templates_rezora_id": 35,
        "title": "CNSS Advisors - Shop TV"
    },
    {
        "theme_id": 22,
        "templates_rezora_id": 31,
        "title": "CNSS Advisors - Home Maintenance",
        "icons": [ "canedar" ],
        "timeframe": { timeframe: "Fall" },
    },
    {
        "theme_id": 20,
        "templates_rezora_id": 42,
        "title": "CNSS Advisors - Personas - The Homeward Bound Millennial"
    },
    {
        "theme_id": 19,
        "templates_rezora_id": 45,
        "title": "CNSS Advisors - Home Seller Guide"
    },
    {
        "theme_id": 17,
        "templates_rezora_id": 48,
        "title": "CNSS Advisors - Spotlight on Chicago North Shore"
    },
    {
        "theme_id": 18,
        "templates_rezora_id": 38,
        "title": "CNSS Advisors - Live Your Luxury | Oceanfront"
    },
    {
        "theme_id": 16,
        "templates_rezora_id": 52,
        "title": "CNSS Advisors - Why E & V - Iconic Marketing"
    },
    {
        "theme_id": 14,
        "templates_rezora_id": 58,
        "title": "CNSS Advisors - Ski Retreat",
        "icons": [ "canedar" ],
        "timeframe": { timeframe: "Spring" },
    },
    {
        "theme_id": 15,
        "templates_rezora_id": 55,
        "title": "CNSS Advisors - Live Your Luxury - Horses"
    },
    {
        "theme_id": 13,
        "templates_rezora_id": 62,
        "title": "CNSS Advisors - Home for Valentine's Day"
    },
    {
        "theme_id": 12,
        "templates_rezora_id": 24,
        "title": "CNSS Advisors - Simple Pleasures - Water",
        "icons": [ "canedar" ],
        "timeframe": { timeframe: "January" },
    },
    {
        "theme_id": 8,
        "templates_rezora_id": 94,
        "title": "CNSS Advisors - Success = Being a Leader"
    },
    {
        "theme_id": 11,
        "templates_rezora_id": 65,
        "title": "CNSS Advisors - Journey of a Listing (Consumer)"
    },
    {
        "theme_id": 9,
        "templates_rezora_id": 105,
        "title": "CNSS Recruiting - Soar to New Heights"
    },
    {
        "theme_id": 10,
        "templates_rezora_id": 68,
        "title": "CNSS Advisors - Name Your Adventure - Learn Something New"
    },
    {
        "theme_id": 7,
        "templates_rezora_id": 77,
        "title": "CNSS Advisors - Renovate Your Bathroom"
    },
    {
        "theme_id": 6,
        "templates_rezora_id": 81,
        "title": "CNSS Advisors - Second Homes - Southwest Michigan"
    },
    {
        "theme_id": 5,
        "templates_rezora_id": 86,
        "title": "CNSS Advisors - What Home Means to You - Family"
    },
    {
        "theme_id": 4,
        "templates_rezora_id": 90,
        "title": "CNSS Advisors - Spotlight on Sun Valley, Idaho"
    },
    {
        "theme_id": 3,
        "templates_rezora_id": 99,
        "title": "CNSS Advisors - Find Your Escape"
    },
    {
        "theme_id": 3,
        "templates_rezora_id": 98,
        "title": "CNSS Advisors - Find Your Profession"
    },
    {
        "theme_id": 4,
        "templates_rezora_id": 89,
        "title": "CNSS Advisors - Spotlight on Munich, Germany"
    },
    {
        "theme_id": 5,
        "templates_rezora_id": 84,
        "title": "CNSS Advisors - What Home Means to You - Friends and Laughter"
    },
    {
        "theme_id": 6,
        "templates_rezora_id": 80,
        "title": "CNSS Advisors - Second Homes - Europe"
    },
    {
        "theme_id": 7,
        "templates_rezora_id": 76,
        "title": "CNSS Advisors - Renovate Your Outdoor Space"
    },
    {
        "theme_id": 8,
        "templates_rezora_id": 93,
        "title": "CNSS Advisors -  Success = Family"
    },
    {
        "theme_id": 10,
        "templates_rezora_id": 71,
        "title": "CNSS Advisors - Cozy Nights at Home"
    },
    {
        "theme_id": 11,
        "templates_rezora_id": 64,
        "title": "CNSS Advisors - Crafting Bespoke Home Experiences"
    },
    {
        "theme_id": 12,
        "templates_rezora_id": 23,
        "title": "CNSS Advisors - Simple Pleasures - Golf",
        "icons": [ "canedar" ],
        "timeframe": { timeframe: "January" },
    },
    {
        "theme_id": 13,
        "templates_rezora_id": 61,
        "title": "CNSS Advisors - Home in Television"
    },
    {
        "theme_id": 15,
        "templates_rezora_id": 54,
        "title": "CNSS Advisors - Live Your Luxury - Campsite"
    },
    {
        "theme_id": 14,
        "templates_rezora_id": 57,
        "title": "CNSS Advisors - European Getaway",
        "icons": [ "canedar" ],
        "timeframe": { timeframe: "Spring" },
    },
    {
        "theme_id": 16,
        "templates_rezora_id": 51,
        "title": "CNSS Advisors - Why E & V - Digital Reach"
    },
    {
        "theme_id": 17,
        "templates_rezora_id": 47,
        "title": "CNSS Advisors - Spotlight on Shoreline"
    },
    {
        "theme_id": 18,
        "templates_rezora_id": 37,
        "title": "CNSS Advisors - Summer Happenings",
        "icons": [ "canedar" ],
        "timeframe": { timeframe: "Summer" },
    },
    {
        "theme_id": 19,
        "templates_rezora_id": 44,
        "title": "CNSS Advisors - Home Staging Guide"
    },
    {
        "theme_id": 20,
        "templates_rezora_id": 41,
        "title": "CNSS Advisors - Personas - The Renaissance City Dweller"
    },
    {
        "theme_id": 21,
        "templates_rezora_id": 34,
        "title": "CNSS Advisors - Window Exposes"
    },
    {
        "theme_id": 24,
        "templates_rezora_id": 119,
        "title": "CNSS Advisors - Cost of Renovations"
    },
    {
        "theme_id": 22,
        "templates_rezora_id": 30,
        "title": "CNSS Advisors - Cozy Up Your Home",
        "icons": [ "canedar" ],
        "timeframe": { timeframe: "Fall" },
    },
    {
        "theme_id": 23,
        "templates_rezora_id": 28,
        "title": "CNSS Advisors - New Year Resolutions - Me Time",
        "icons": [ "canedar" ],
        "timeframe": { timeframe: "January" },
    },
    {
        "theme_id": 25,
        "templates_rezora_id": 115,
        "title": "CNSS Advisors - All About the Marketing"
    },
    {
        "theme_id": 26,
        "templates_rezora_id": 111,
        "title": "CNSS Advisors - March Madness - Rustic vs. Urban",
        "icons": [ "canedar" ],
        "timeframe": { timeframe: "March" },
    },
    {
        "theme_id": 27,
        "templates_rezora_id": 108,
        "title": "CNSS Advisors - City Luxury"
    },
    {
        "theme_id": 28,
        "templates_rezora_id": 121,
        "title": "CNSS Advisors - Referral Network"
    },
    {
        "theme_id": 28,
        "templates_rezora_id": 106,
        "title": "CNSS Advisors - Global Guide"
    },
    {
        "theme_id": 25,
        "templates_rezora_id": 114,
        "title": "CNSS Advisors - The E & V  Network"
    },
    {
        "theme_id": 24,
        "templates_rezora_id": 118,
        "title": "CNSS Advisors - Choosing an Advisor"
    },
    {
        "theme_id": 22,
        "templates_rezora_id": 29,
        "title": "CNSS Advisors - Safety Tips for Pets",
        "icons": [ "canedar" ],
        "timeframe": { timeframe: "Fall" },
    },
    {
        "theme_id": 21,
        "templates_rezora_id": 33,
        "title": "CNSS Advisors - GG/Private Residence"
    },
    {
        "theme_id": 20,
        "templates_rezora_id": 40,
        "title": "CNSS Advisors - Personas - The Eager International Buyer"
    },
    {
        "theme_id": 16,
        "templates_rezora_id": 50,
        "title": "CNSS Advisors - Why E & V - Outstanding Advisors"
    },
    {
        "theme_id": 13,
        "templates_rezora_id": 60,
        "title": "CNSS Advisors - Homes in Movies"
    },
    {
        "theme_id": 12,
        "templates_rezora_id": 22,
        "title": "CNSS Advisors - Simple Pleasures - Home",
        "icons": [ "canedar" ],
        "timeframe": { timeframe: "January" },
    },
    {
        "theme_id": 7,
        "templates_rezora_id": 96,
        "title": "CNSS Advisors - Renovations - Living Space"
    },
    {
        "theme_id": 8,
        "templates_rezora_id": 67,
        "title": "CNSS Advisors - Success = Travel"
    },
    {
        "theme_id": 10,
        "templates_rezora_id": 69,
        "title": "CNSS Advisors - Fall Adventures",
        "icons": [ "canedar" ],
        "timeframe": { timeframe: "Fall" },
    },
    {
        "theme_id": 6,
        "templates_rezora_id": 79,
        "title": "CNSS Advisors - Second Homes - Mountains"
    },
    {
        "theme_id": 4,
        "templates_rezora_id": 88,
        "title": "CNSS Advisors - Spotlight on New York, New York"
    },
    {
        "theme_id": 5,
        "templates_rezora_id": 83,
        "title": "CNSS Advisors - What Home Means to You - Feeling"
    },
    {
        "theme_id": 3,
        "templates_rezora_id": 97,
        "title": "CNSS Advisors - Find Your Place in Nature"
    },
    {
        "theme_id": 4,
        "templates_rezora_id": 87,
        "title": "CNSS Advisors - Spotlight on Barcelona, Spain"
    }
];

export class RezoraDripDataJson
{
    blockelemtype: string;
    
    blockid: string;
    
    waitType: string;
    
    forAmount: string;
    
    forPeriod: string;
    
    untilDay: string;
    
    untilHour: string;
    
    untilMin: string;
    
    untilAmpm: string;
    
    templateId: string;
    
    template_title: string;
    
    email_subject: string;
    
    campaignId: string;
    
    campaign_name: string;
    
    constructor()
    {
        this.blockelemtype = "";
        this.blockid = "";
        this.waitType = "";
        this.forAmount = "";
        this.forPeriod = "";
        this.untilDay = "";
        this.untilHour = "";
        this.untilMin = "";
        this.untilAmpm = "";
        this.templateId = "";
        this.template_title = "";
        this.email_subject = "";
        this.campaignId = "";
        this.campaign_name = "";
    }
}
//== File: /dtos/RezoraDripJson.tsx

export class RezoraDripJson
{
    childwidth: number;
    
    parent: number;
    
    id: number;
    
    x: number;
    
    y: number;
    
    width: number;
    
    height: number;
    
    data: RezoraDripDataJson;
    
    constructor()
    {
        this.childwidth = 0;
        this.parent = 0;
        this.id = 0;
        this.x = 0;
        this.y = 0;
        this.width = 0;
        this.height = 0;
        this.data = new RezoraDripDataJson();
    }
}

export interface Drip {
    title: string;
    rezora_drip_details: RezoraDripJson[];
}


interface IEmailsPageProps {
}

export const EmailsPage : React.FC<IEmailsPageProps> = (props) => {
    const idToken = localStorage.getItem('user_token') ?? '';
    const optionsGetFetch : IUseFetchOptions = {
        jwtToken: idToken,
        shouldInitializeOnly: false,
        method: 'GET',
        shouldUseCache: false,
        timeoutMilliseconds: 30000 // (*) TODO: This isn't ideal. Why is sales-calendar taking so long? (12/22/2022 - pel)
    };

    const [ emailResponse,  ] = useFetchJSON<Drip[]>(`/api/emails/`, optionsGetFetch);
    console.log('abc', emailResponse);

    if (emailResponse.isLoading) {
        return (
            <div>
                Loading...
            </div>
        );
    }

    //emailResponse.data.
    //const dripsJsx = emailResponse.data?.map((drip, index) => 
    const themesJsx = themes.map((theme, themeIndex) => 
    {
        //const emails = emailResponse.data?.filter((drip) => drip.rezora_drip_details.blockelemtype === 'Email');
        const emails = themeTemplates.filter((template) => template.theme_id === theme.id);
        const categoryIcons = emails.filter((template) => template.icons && template.icons.length > 0)?.flatMap(e => e.icons ?? []);
        const categoryIconsJsx = categoryIcons.filter((value, index, array) => array.indexOf(value) === index).map((icon, index) => {
            return (
                <>
            &nbsp;
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => <Tooltip id="icon-tooltip" {...props}>{`The calendar icon means one or more email-templates are timeframe sensative.`}</Tooltip>}
                >
                <FontAwesomeIcon icon="calendar" />
            </OverlayTrigger>
            &nbsp;
            </>
            ) 
        });

        const emailsJsx = emails.map((email, index) => {

            const iconsJsx = email?.icons?.map((icon, index) => {


                return (
                    <>
                        &nbsp;
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={(props) => <Tooltip id="icon-tooltip" {...props}>{`Ideal timeframe is: ${email.timeframe?.timeframe ?? ''}`}</Tooltip>}
                            >
                            <FontAwesomeIcon icon="calendar" />
                        </OverlayTrigger>

                    </>
                );
            });

            

            return (
                <div>
                    {email.title}{iconsJsx} 
                </div>
            );
        });

        const installedJsx = themeIndex < 7 ? (
            <>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => <Tooltip id={`icon-tooltip-installed-${themeIndex}`} {...props}>{`Complete - Email templates and drip are complete.`}</Tooltip>}
                >
                <FontAwesomeIcon icon="check" />
            </OverlayTrigger>
            &nbsp;
            </>
        ) : <></>;

        const activeJsx = themeIndex === 7 ? (
            <>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => <Tooltip id={`icon-tooltip-active-${themeIndex}`} {...props}>{`Active - These templates and drip are currently active.`}</Tooltip>}
                >
                <FontAwesomeIcon icon="heart-pulse" />
            </OverlayTrigger>
            &nbsp;
            </>
        ) : <></>;

        const skipJsx = themeIndex === 8 ? (
            <>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => <Tooltip id={`icon-tooltip-upnext-${themeIndex}`} {...props}>{`Skipped - These email templates will be installed and the drip will be activated when the timeframe is applicable.`}</Tooltip>}
                >
                <FontAwesomeIcon icon="forward" />
            </OverlayTrigger>
            &nbsp;
            </>
        ) : <></>;

        const upNextJsx = themeIndex === 9 ? (
            <>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => <Tooltip id={`icon-tooltip-upnext-${themeIndex}`} {...props}>{`Up next - these email templates installed and the drip will be activated on 6/15/2023.`}</Tooltip>}
                >
                <FontAwesomeIcon icon="arrow-right" />
            </OverlayTrigger>
            &nbsp;
            </>
        ) : <></>;
        
        const categoryString = theme?.category ? ` [${theme.category}]` : '';
        const themeJsx = <>{installedJsx}{activeJsx}{skipJsx}{upNextJsx}<b>Theme:</b>&nbsp;{theme.theme}&nbsp;({emails.length} emails){categoryString}{categoryIconsJsx}</>

        return (
            <Accordion.Item eventKey={themeIndex.toString()}>
                <Accordion.Header>{themeJsx}</Accordion.Header>
                <Accordion.Body>
                    {emailsJsx}
                    
                </Accordion.Body>
            </Accordion.Item>
        );
    });

    return (
        <Container>
            <Row>
                <Col>
                <b>Shop Licensee:</b> Spokane, WA
                </Col>
            </Row>
            <Row>
                <Col>
                <b>Licenses:</b> Master License with up to 5 Advisor License(s)
                </Col>
            </Row>
            <Row>
                <Col>
                <b>Licenses:</b> 1 of 5 Advisor License(s) In Use
                </Col>
            </Row>
            <br/>
            <div style={{width: '55em'}}>
            <Accordion defaultActiveKey="7">
                {themesJsx}
            </Accordion>    
        </div>

        </Container>
    );
}
