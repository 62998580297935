import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card"
import CardGroup from "react-bootstrap/esm/CardGroup";
import Col from "react-bootstrap/esm/Col"
import Container from "react-bootstrap/esm/Container"
import Row from "react-bootstrap/esm/Row"

import PostCardUnderContract from '../../images/postcard-under-contract.png';

export const PostcardManagePage : React.FC = () => {

    const mapPostcard = (postcard: any) : JSX.Element =>  {
        return (
            <>
            <Card style={{width: '20rem', maxWidth:'20rem'}} key={postcard.name}>
                <Card.Img src={PostCardUnderContract} style={{height: '12rem', objectFit: 'cover', objectPosition: '2% 2%'}}>
                </Card.Img>
                <Card.Footer className="text-center">
                    {postcard.name}
                </Card.Footer>
            </Card>
            &nbsp;
            &nbsp;
            </>
        )
    }

    const postcards = [{name: 'post card 1'}, {name: 'post card 2'}, {name: 'post card 3'}];
    const postcardsJsx = postcards.map(mapPostcard);
    return (
        <Container fluid>
            <Row style={{paddingBottom: '1rem'}}>
                <Col className="d-flex justify-content-end" lg={8}>
                    <Button variant="danger" >
                        New Postcard
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col lg={8}>
                    <CardGroup>
                        {postcardsJsx}
                    </CardGroup>
                </Col>
            </Row>
        </Container>
            
    )
}