/*
import Select, { ActionMeta, MultiValue, OnChangeValue } from "react-select";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/esm/Form";

import DatePicker from 'react-datepicker';

import { OrderForm } from "@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form"
import { OrderFormDropdown } from "@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form-dropdown";
import { OrderFormRow } from "@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form-row";
import { CSSProperties, DependencyList, useEffect, useRef, useState } from "react";
import { FormControlMoney } from "../../molecules/form-control-money";
import { IFormControlState, PostcardCostType, PostcardCostTypeFields } from "../../molecules/post-card-cost-type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/esm/Button";
import { UploadFile } from "@plazarre/phoenix.ux.file-upload/lib/esm/ux/molecules/upload-file";
import { useFileTransfer } from "../../../hooks/use-file-transfer";
import ProgressBar from "react-bootstrap/esm/ProgressBar";
import Row from "react-bootstrap/esm/Row";
import { Crop, PixelCrop } from "react-image-crop";
import ReactCrop from "react-image-crop";
import { centerCrop, makeAspectCrop } from "react-image-crop/src/utils";

import 'react-image-crop/dist/ReactCrop.css';
import { OrderButtons } from "@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-buttons";
import { Point } from "../../../core/postcard/domain/point";
import { Media } from "../../atoms/media";
import { CategoryNewModal } from "../../organisms/category-new-modal";
import { Option } from '../../../core/postcard/data-types/option-type';
*/

export const PostCardEditTemplate : React.FC = () => {
    return <></>;
} 

/*
  const TO_RADIANS = Math.PI / 180
  
  export const canvasPreview = async (image: HTMLImageElement, canvas: HTMLCanvasElement, crop: PixelCrop, scale = 1, rotate = 0) : Promise<string> => {
    const ctx = canvas.getContext('2d')
  
    if (!ctx) {
      throw new Error('No 2d context')
    }
  
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio
    // const pixelRatio = 1
  
    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)
  
    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'
  
    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY
  
    const rotateRads = rotate * TO_RADIANS
    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2
  
    ctx.save()
  
    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 3) Rotate around the origin
    ctx.rotate(rotateRads)
    // 2) Scale the image
    ctx.scale(scale, scale)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
    )
  
    ctx.restore();

    // (*) TODO: Do we need to delete after use?
    return canvas.toDataURL("image/png");
  }
  

  const useDebounceEffect = (fn: () => void, waitTime: number, deps?: DependencyList) => {
    useEffect(() => {
      const t = setTimeout(() => {
        fn.apply(undefined, deps as [])
      }, waitTime)
  
      return () => {
        clearTimeout(t)
      }
    }, deps);
  }
  
const mapCapturedImages = (imageSrc : string, index: number) => {
    const imageKey = `image-${index}`;
    const altString = `Page #${index}`;
    return (

        <div style={{paddingRight: '1rem'}}>
        <figure>
            <img src={imageSrc} key={imageKey} alt={altString} style={{ width : '12rem'}} />
            <figcaption style={{textAlign: 'center'}}>Page {index+1}</figcaption>
        </figure> 
        </div>
    );
}  

export const PostCardEditTemplate : React.FC = (props) => {
    const [name, setName] = useState('');
    const [tonerCost, setTonerCost] = useState('');
    const [printingLaborCost, setPrintingLaborCost] = useState('');
    const [postProductionLaborCost, setPostProductionLaborCost] = useState('');
    const [paperType, setPaperType] = useState(0);
    const [costType, setCostType] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [designPrintCostPercentage, setDesignPrintCostPercentage] = useState(0);
    const [postCardsPerSheet, setPostCardsPerSheet] = useState('2');
    const [selectedCategories, setSelectedCategories] = useState<Option[]>([]);
    const nameRef = useRef<HTMLInputElement>(null);
    const [fileTransfers, handleChange, clearFileTransfers] = useFileTransfer([]);

    const [mediaSizeX, setMediaSizeX] = useState<string>('');
    const [mediaSizeY, setMediaSizeY] = useState<string>('');

    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [aspect, setAspect] = useState<number | undefined>(11 / 7);

    const [isImageCaptured, setIsImageCaptured] = useState(false);
    const [capturedImageUrl, setCapturedImageUrl] = useState('');
    const [capturedImageUrls, setCapturedImageUrls] = useState<string[]>([]);

    const [displayCategoryNewModal, setDisplayCategoryNewModal] = useState<boolean>(false);
    const [categories, setCategories] = useState<Option[]>([{value: 'Just Listed', label: 'Just Listed'}]);

    const exampleRef = useRef<HTMLDivElement>(null);

    //const buttonsJsx = <></>;

    
    const colLg = 3;
    const fields : IFormControlState<any>[] = [
        { name: PostcardCostTypeFields.tonerCost, value: tonerCost, setValue: setTonerCost},
        { name: PostcardCostTypeFields.printingLaborCost, value: printingLaborCost, setValue: setPrintingLaborCost},
        { name: PostcardCostTypeFields.postProductionLaborCost, value: postProductionLaborCost, setValue: setPostProductionLaborCost},
    ];

    const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
        return centerCrop(
          makeAspectCrop(
            {
              unit: '%',
              width: 100,
            },
            aspect,
            mediaWidth,
            mediaHeight,
          ),
          mediaWidth,
          mediaHeight,
        )
      };
      
      useDebounceEffect(async () => {
          if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
            // We use canvasPreview as it's much faster than imgPreview.
            setCapturedImageUrl(await canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate));
          }
        },
        100,
        [completedCrop, scale, rotate],
      );

    const onSelection = (newValue: Option[], actionMeta: ActionMeta<Option>) => {
        setSelectedCategories(newValue);
    }

    const mediaSizeXFloat = parseFloat(mediaSizeX);
    const mediaSizeYFloat = parseFloat(mediaSizeY);
    const mediaSizePoint : Point | undefined = mediaSizeX && mediaSizeY ? { x: mediaSizeXFloat, y: mediaSizeYFloat } : undefined;

    const imageStyles : CSSProperties = {maxWidth: '100%', maxHeight: '11rem', minHeight: '11rem', minWidth: 'auto', margin: '0 auto' };
    const imageHolderStyles : CSSProperties = { border: '1px solid black', maxHeight: '11rem', maxWidth: '30rem', 
                                                display: 'none', background: 'lightgray'};
                                                
    const imageHoldersJsx : JSX.Element[] = fileTransfers.map((fileTransfer, index) => {
        const imageId = `image${index}`;
        const imageHolderId = `imageholder${index}`;
        const imageProcessId = `imageprogress${index}`;

        // Be sure startOffset isn't past the length. If so correct.
        const startOffset = fileTransfer.startOffset > fileTransfer.length ? fileTransfer.length : fileTransfer.startOffset;
        const now = Math.round((startOffset / fileTransfer.length)*100);

        const errorJsx = fileTransfer.isError ? <span style={{color:'red'}}>{fileTransfer.errorDescription}</span> : <></>;
        return (
            <Col xxl={3} id={imageHolderId} className='text-center align-items-end' style={imageHolderStyles}>
                <img id={imageId} alt={fileTransfer.file.name} style={imageStyles} />
                <ProgressBar id={imageProcessId} now={now} label={`${now}%`} style={{marginTop: '-1rem'}}/>
                {errorJsx}
            </Col>
        );
    }) ?? [];


      function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
          const { width, height } = e.currentTarget
          setCrop(centerAspectCrop(width, height, aspect))
        }
      }

   
    const cropperJsx : JSX.Element[]  = [];
    
    if (fileTransfers.length === 0) {
        cropperJsx.push(
            <Row lg={12} style={{ height: '60vh' }}>
                <Media mediaSizeInches={mediaSizePoint} mediaTitle="Postcard" />
            </Row>
        );
    }

    cropperJsx.push(
        <Row>
            <Col style={{paddingRight: '0'}}>
                <UploadFile 
                    name="image" 
                    fileTypes={['jpg', 'jpeg', 'gif', 'bmp', 'png']} 
                    handleChange={handleChange} 
                    multiple={false}
                    style={{paddingLeft: '0', paddingRight: '0'}}
                    />
            </Col>
        </Row>
    );
      
    if (fileTransfers.length > 0) {
        const fileTransfer = fileTransfers[0];

        // Be sure startOffset isn't past the length. If so correct.
        const startOffset = fileTransfer.startOffset > fileTransfer.length ? fileTransfer.length : fileTransfer.startOffset;
        const now = Math.round((startOffset / fileTransfer.length) * 100);
        const image = document.getElementById('image0') as HTMLImageElement;
        const imageSrc = image?.src;


        let canvasJsx = <></>;
        if (completedCrop) {
            canvasJsx = (
                <canvas
                    ref={previewCanvasRef}
                    style={{
                        border: '1px solid black',
                        objectFit: 'contain',
                        width: completedCrop.width,
                        height: completedCrop.height,
                        display: isImageCaptured ? 'inline' : 'none'
                    }}
                />
            );
        }

        console.log('imageSrc', imageSrc);
        console.log('isImageCaptured', isImageCaptured);
        if (imageSrc) {
                cropperJsx.push(
                    <>
                        <Row lg={12}>
                            <Col lg={12}>

                                <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                    aspect={aspect}
                                    style={{ display: isImageCaptured ? 'none' : 'inherit'}}
                                >
                                    <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={imageSrc}
                                        style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                        onLoad={onImageLoad}
                                    />
                                </ReactCrop>
                                
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="secondary" onClick={() => {
                                    console.log('captured image url', capturedImageUrl);
                                    if (capturedImageUrl) {
                                        setCapturedImageUrls((prevValue) => [...prevValue, capturedImageUrl]);
                                        setIsImageCaptured(false);
                                    }

                                    setCompletedCrop(undefined);
                                    clearFileTransfers();
                                }}
                                >
                                    Capture Image
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {canvasJsx}
                            </Col>
                        </Row>
                    </>
                );
            }
    }

    useEffect(() => {
        const fileTransfer = fileTransfers[0];
        if (fileTransfer) {
            console.log('file transfer', fileTransfer);

        // Be sure startOffset isn't past the length. If so correct.
        const startOffset = fileTransfer.startOffset > fileTransfer.length ? fileTransfer.length : fileTransfer.startOffset;
        const now = Math.round((startOffset / fileTransfer.length)*100);
        console.log('filetransfers', now);
        }
    }, [fileTransfers]);
    
    const buttonsJsx = (
        <OrderButtons
            submitLabel="Save"
            submitDisabled={name.length === 0 || !startDate || !mediaSizePoint || capturedImageUrls.length === 0}
            submitWaitingIcon={false}
            skipLabel=""
            backLabel="Cancel"
            SetOrderFields={() => {}}
        />
    );

    const categoryJsx = (
        <div className="d-flex" style={{ width: '22rem' }} >
            <Select
                isMulti
                name="categories"
                options={categories}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(newValue, actionMeta) => onSelection(newValue as Option[], actionMeta)}
                value={selectedCategories}
                styles={{}}
            />
        </div>
    );

    return (
        <OrderForm id="postcard-edit" name="postcard-edit" buttonsJsx={buttonsJsx} SetOrderFields={() => {}} exampleJsx={<>{cropperJsx}</>}>
            <CategoryNewModal isModalDisplayed={displayCategoryNewModal} setIsModalDisplayed={setDisplayCategoryNewModal} 
                    categories={categories} setCategories={setCategories} />
            <OrderFormRow colLg={colLg} header="Name" value="" >
                <Form.Group as={Col} xxl="8" controlId="validationCustom01">
                    <Form.Control
                        ref={nameRef}
                        name="name"
                        autoComplete="nope"
                        value={name}
                        maxLength={40}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { e.preventDefault(); setName(e.currentTarget.value); }}
                        style={{ width: '80%' }}
                    />
                    <Form.Control.Feedback type="invalid" style={{ display: name.length > 0 ? 'none' : 'inline' }}>
                        Please provide a unique name for the post card.
                    </Form.Control.Feedback>
                </Form.Group>
            </OrderFormRow>

            <OrderFormRow header="Postcard Size in Inches (w/h)" value="" colLg={colLg} >
                <Form.Group  as={Col} xxl="8" controlId="validationCustom02">
                    <div className="d-flex align-items-center">
                    <Form.Control
                        name="width"
                        autoComplete="nope"
                        value={mediaSizeX}
                        maxLength={10}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { e.preventDefault(); setMediaSizeX(e.currentTarget.value); }}
                        style={{ width: '15%' }}
                    />
                    <span>
                        &quot;
                        X
                        &nbsp;
                    </span>
                    <Form.Control
                        name="width"
                        autoComplete="nope"
                        value={mediaSizeY}
                        maxLength={10}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { e.preventDefault(); setMediaSizeY(e.currentTarget.value); }}
                        style={{ width: '15%' }}
                    />
                    &quot;
                    </div>
                    <Form.Control.Feedback type="invalid" style={{ display: mediaSizePoint ? 'none' : 'inline' }}>
                        Please provide width and height in inches for the paper.
                    </Form.Control.Feedback>
                </Form.Group>
            </OrderFormRow>

            <OrderFormDropdown
                        variant="secondary"
                        label="Costs"
                        value={costType}
                        dropdownValues={[{name: '10% (Effective 11/1-12/31/2022)', index: 0} , {name: '$2.50 per 100 postcards (Effective 1/1/2023)', index: 1}]}
                        colLg={colLg+1}
                        middleColLg={colLg + 2}
                        setValue={setCostType}
                    >
                        <Button variant='outline-secondary' title="Define costs" >
                            ...
                        </Button>
            </OrderFormDropdown>

            { costType === 3 && (
                <OrderFormRow colLg={colLg} header="Design / Print Cost Percentage" value="%">
                    <FormControlMoney
                        name='design_print_cost_percentage'
                        value={designPrintCostPercentage}
                        setValue={setDesignPrintCostPercentage}
                        validationMessage="Please provide the design/print cost percentage."
                        maxLength={6}
                        width="10rem"
                    />
                </OrderFormRow>
            )}
            { costType === 4 && <PostcardCostType fields={fields} /> }

            <OrderFormRow colLg={colLg+1} middleColLg={5} outerColStyle={{ display: 'flex'}} header="Categories" valueJsx={categoryJsx} >
                <Button variant='outline-secondary' title="Add category" onClick={() => setDisplayCategoryNewModal((prevValue) => !prevValue)} >
                    <FontAwesomeIcon icon="plus" />
                </Button>
            </OrderFormRow>

            <OrderFormRow header="Availability Start/End" value="" colLg={colLg} >
                <Form.Group  as={Col} xxl="8" controlId="validationCustom05">
                <div className="d-flex" style={{width: '50%'}}>
                    <DatePicker selected={startDate} onChange={(date: Date) => setStartDate(date)} placeholderText="Start date" isClearable />
                    &nbsp;
                    <DatePicker selected={endDate} onChange={(date: Date) => setEndDate(date)} placeholderText="End date" isClearable />
                </div>
                <Form.Control.Feedback type="invalid" style={{ display: startDate ? 'none' : 'inline' }}>
                        Please enter the start date for availability of the post card. Leave end date blank for no end date.
                </Form.Control.Feedback>
                </Form.Group>
            </OrderFormRow>

            <div style={{display: 'none'}}>
            {imageHoldersJsx}           
            </div>
            <div >
                <b>
                    Images:
                </b>
                <br/><br/>
                <div className='d-flex'>
                {capturedImageUrls.map(mapCapturedImages)}
                
                </div>
                <Form.Control.Feedback type="invalid" style={{ display: capturedImageUrls.length > 0 ? 'none': 'inline' }}>
                        Please upload at least one image.
                </Form.Control.Feedback>
            </div>
        </OrderForm>
    );
}
*/
