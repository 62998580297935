import { IPostCardCustomOrderProps } from "../pages/order-postcard";

import { Col, Container, Row } from "react-bootstrap";

export const TemplateOrderPostCardComplete : React.FC<IPostCardCustomOrderProps> = (props) => {
    // const { id, order, setOrder, updateSlug } = props;
    // const [includePostage, setIncludePostage] = useState(1);
    // const canvasRef = useRef<HTMLCanvasElement>(null);

    /*
    const setOrderFields = (stepAdjustment: number, state: OrderStepStateEnum) => {
        const newOrder = JSON.parse(JSON.stringify(order)) as unknown as PostcardCustomOrder;
        newOrder.stepStates[newOrder.step] = state;
    
        newOrder.step += applyStepStateAdjustment(newOrder.step, stepAdjustment, newOrder.stepStates);
        //newOrder.orderEV.requestShopTV = requestShopTV;
        //newOrder.orderEV.headline = requestShopTV ? headline : '';
        //const json = JSON.stringify(newOrder);
        //localStorage.setItem('evc-order', json);
        setOrder(newOrder);
        updateSlug(id, newOrder);
    };
    */

    /*
    const buttonsJsx = (
        <OrderButtons
            submitLabel="Submit Order"
            skipLabel=""
            backLabel="Back"
            SetOrderFields={setOrderFields}
        />
    );
    */

       
    // const exampleJsx = <></>;
    return (
        <>
            <Container fluid>
                <Row style={{marginTop: '2rem'}}>
                    <Col>
                    Your order has been submitted!
                    </Col>
                </Row>
                <Row style={{marginTop: '1rem'}}>
                    <Col>
                        Feel free to close your browser window.
                    </Col>
                </Row>

            </Container>

        </>
    );
}
