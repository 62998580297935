import React from 'react';
import ReactDOM from 'react-dom/client';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRight, faArrowUpRightFromSquare, faBan, faBars, faCalendar, faCheck, faCircle, 
         faDollarSign, faForward, faFileCsv, faFileExcel, 
         faHeartPulse, faPlus, faRotateLeft, faSync, faTrashCan, faTriangleExclamation, faXmark } from '@fortawesome/free-solid-svg-icons';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
import { HelmetProvider } from 'react-helmet-async';

library.add(faArrowRight, faArrowUpRightFromSquare, faBan, faBars, faCalendar, faCheck, faCircle, 
            faDollarSign, faFileCsv, faFileExcel, faForward, 
            faHeartPulse, faPlus, faRotateLeft, faSync, faTrashCan, faTriangleExclamation, faXmark);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
      <HelmetProvider>
        <App />
      </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
