
import { OrderNewOrEditEnum } from "@plazarre/phoenix-ux-order/lib/esm/core/data-types/order-new-or-edit-enum";
import { PostCardOrderStepEnum } from "../../core/postcard/data-types/postcard-order-step-enum";
import { IPostCardCustomOrderProps, PostcardOrderStepComponents as PostCardOrderStepComponents } from "../pages/order-postcard";

export const PostcardOrderStepComponent = (props: IPostCardCustomOrderProps) => {
    const { id, index, order, setOrder, stepSlug, updateSlug } = props;

    let { step } = order;
    if (step == null || step === undefined) {
        step = 0;
    }

    // Make sure this is a valid step
    if (step > PostCardOrderStepEnum.Complete || step < PostCardOrderStepEnum.Type) {
        throw new Error(`Error postcard-order, invalid step number: ${step}`);
    }

    // Validate the stepSlug (part I)
    if (!stepSlug) {
        throw new Error(`Error postcard-order, stepSlug is invalid: ${step}`);
    }

    // Validate the stepSlug (part II)
    const indexSlug = PostCardOrderStepComponents.findIndex((e) => e.slug === stepSlug);
    if (indexSlug === -1) {
        throw new Error(`Error postcard-order, stepSlug is invalid ${stepSlug}, ${step}`);
    }

    // Create the correct component
    const Component = PostCardOrderStepComponents[step].component;
    const newOrEdit = OrderNewOrEditEnum.New;

    //            history={history}

    // use the reference to the component with the Capitalized variable name to render it
    return (
        <Component
            id={id}
            index={index}
            /* key={key} */
            order={order}
            setOrder={setOrder}
            stepSlug={stepSlug}
            updateSlug={updateSlug}

            newOrEdit={newOrEdit}
        />
    );
};
