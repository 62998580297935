import { ApprovalProcessInformation, MarketingOrderInvoiceUpdateRequest, PropertyDisplayResult, WaveInvoiceStatus, useInvoiceProcess, useListingMarketingOrder, useMLS, useMarketingOrderInvoice, useProperty } from "@plazarre/phoenix.javascript.approval";
import { FetchCall } from "@plazarre/phoenix.ux.fetch/lib/esm/core/domain/fetch-call";
import { useEffect, useState } from "react";
import { DoFetchRequest } from "@plazarre/phoenix.ux.fetch/lib/esm/hook/use-fetch-json";
import { InvoiceCompleteCommand } from "@plazarre/phoenix.javascript.approval";
import { ButtonListingKeyClick, PropertyButtonBase } from "../ux/atoms/invoices/property-button-base";
import { IModalData, IModalDataInvoiceComment } from "../ux/molecules/invoices/modal-invoice-comment";
import { InvoiceApprovalUpdateCommand } from "@plazarre/phoenix.javascript.approval";

interface ProcessedMLSMessage {
    mlsId: string,
    isLoading: boolean,
    message: string,
}

export interface UseListingsPageOnClicksResult {
    onMarketingOrderUpdateInvoiceClick: ButtonListingKeyClick,
    onMarketingOrderCreateInvoiceClick: ButtonListingKeyClick,
    onUpdateApprovalInvoiceClick: ButtonListingKeyClick,
    onSendForApprovalClick: ButtonListingKeyClick,
    onSendToAdvisorsClick: ButtonListingKeyClick,
    onInvoiceApprovalUpdateClick: ButtonListingKeyClick,
    onCreateSalesForceListingFromMLSClick: ButtonListingKeyClick,
    onCreateListingMarketingOrderFromListingClick: ButtonListingKeyClick,
}

/*
export interface UseListingsPageResult {
    commentModalData: IListingsCommentModalData | null, 
    setCommentModalData: ReactState<IListingsCommentModalData | null>,
    processedMessages: ProcessedMLSMessage[],
    setProcessedMessage: ReactState<ProcessedMLSMessage[]>,
    selectedCard: string | null,
    setSelectedCard: ReactState<string | null>,
    selected: string | null,
    setSelected: React.Dispatch<React.SetStateAction<string | null>>,
    showCommentModal: boolean, 
    setShowCommentModal: ReactState<boolean>,
    propertyInvoices: FetchCall<PropertyDisplayResult[]>,
    onClicks: UseListingsPageOnClicksResult,   
    saveListingsCommentModal: (data: IListingsCommentModalData) => void,
}
*/

export const useListingsPage = () => {
    const [processedMessages, setProcessedMessage] = useState<ProcessedMLSMessage[]>([]);
    const [selectedCard, setSelectedCard] = useState<string | null>(null);

    const [selected, setSelected] = useState<string | null>(null);

    const modalData : IModalData<IModalDataInvoiceComment> = {
        baseButton: null, shouldShowModal : false, save: () => {}, data: null
    }
    const modalDataState = useState<IModalData<IModalDataInvoiceComment>>(modalData);

    const { propertyInvoicesDisplay, propertyLink, propertyLinkRun, propertyDisplayMLS } = useProperty();
    const propertyInvoicesDataSetStateFetch = propertyInvoicesDisplay;
    const [propertyInvoices, propertyInvoicesSetStateSetch] = propertyInvoicesDataSetStateFetch;
    const [propertyLinkResult, , doPropertyLinkGet] = propertyLink;

    const { invoiceApprovalSend, invoiceApprovalComplete, invoiceApprovalUpdate } = useInvoiceProcess();
    const { invoiceMarketingOrderCreate, invoiceMarketingOrderUpdate } = useMarketingOrderInvoice();
    const [ , , doMarketingOrderCreate] = invoiceMarketingOrderCreate;
    const [ , , doMarketingOrderUpdate] = invoiceMarketingOrderUpdate;
    const [ , , doInvoiceComplete] = invoiceApprovalComplete;
    const [ , , doInvoiceApprovalUpdate] = invoiceApprovalUpdate;

    const { mlsSync } = useMLS();

    const { listingMarketingOrderCreate } = useListingMarketingOrder();

    useEffect(() => {
        propertyInvoicesSetStateSetch.fetch();
    }, [propertyInvoicesSetStateSetch]);

    const updateButtonProcessedMessage = (property: PropertyDisplayResult, returnResult: FetchCall<any>) => {
        const message = returnResult.data ? (returnResult.data.isSuccess ? 'Success!' : returnResult.data.error) 
                                          : returnResult.errorMessage;

        const { listingKey } = property;
        setProcessedMessage((prevValue) => {
            const newValue = [...prevValue];
            const newProcessedValue = newValue.find(e => e.mlsId === listingKey);
            if (newProcessedValue == null) {
                // Add the message
                newValue.push({ mlsId: listingKey, message, isLoading: false });
            } else {
                newProcessedValue.message = message;
                newProcessedValue.isLoading = false;
                
                // Remove the value from the array and then add it back in
                newValue.splice(newValue.indexOf(newProcessedValue), 1);
                newValue.push(newProcessedValue);
            }

            return newValue;
        });
    }

    const onMarketingOrderUpdateInvoiceClick : ButtonListingKeyClick = async (e, property, baseButton) => {
        const { listingKey } = property;

        // Get the marketing order id
        const marketingOrderId = property.orders?.[0]?.id;
        if (!marketingOrderId) {
            throw new Error(`Error: unable to find marketing order id of ${listingKey}.`);
        }

        const modalData : IModalData<IModalDataInvoiceComment> = {
            baseButton, shouldShowModal : true, save: saveListingsCommentModalInvoiceUpdate, data: { property, comment: '' }
        };

        modalDataState[1](modalData);
    }

    const onMarketingOrderCreateInvoiceClick : ButtonListingKeyClick = async (e,  property) => {
        const { listingKey } = property;

        // filter out the orders that have invoice numbers
        // (*) TODO: how do we compare the enum type to the e.status field instead of a string? (1/16/2024 - pel)
        const marketingOrderId = property.orders.filter(e => e.invoiceNumbers.length === 0 && e.status.toString() !== 'Canceled').map(e => e.id)[0];
        if (!marketingOrderId) {
            throw new Error(`Error: unable to find marketing order id of ${listingKey}.`);
        }

        const returnResult = await doMarketingOrderCreate({ urlParams: marketingOrderId });

        /*
        if (returnResult.hasData && returnResult.data?.isSuccess) {
            const returnGetPropertyLinkResult = await getPropertyLink({ urlParams: listingKey, jwtToken: idToken});
            console.log('returnPostResult', returnGetPropertyLinkResult);
        
            setPropertyResponse((prevData : FetchCall<PropertyDisplayResult[]>) => {
                if (!prevData.data || !returnGetPropertyLinkResult.data || returnGetPropertyLinkResult.data.listings.length === 0)
                    return prevData;
                
                const newProperties = prevData.data.filter(e => e.listingKey !== listingKey);
                const listings = returnGetPropertyLinkResult.data;
                // const abc = returnGetPropertyLinkResult.data.inquire;
                const { property } = returnGetPropertyLinkResult.data; // inquire, 
                newProperties.push(
                    { 
                        listingKey, listings, fullStreetAddress: property.fullStreetAddress, propertyType: property.propertyType,
                        physicalPropertyType: property.physicalPropertyType, physicalPropertySubType: property.physicalPropertySubType,
                        standardStatus: property.standardStatus, listingContractDate: new Date(Date.parse(property.listingContractDate)), 
                        orders: [], invoices: [], advisorName: '[GETME]', coAdvisorName: '[GETME]', orderDateTimes: [], 
                        approvalProcessInformationJsonList: [], isPrivate: false, mlsData: []
                    });
                const newData : FetchCall<PropertyDisplayResult[]> = FetchCall.Success(newProperties);
                return newData;
            });
        }
        */



        updateButtonProcessedMessage(property, returnResult);
    }

    const onUpdateApprovalInvoiceClick : ButtonListingKeyClick = async (e: any, property) => {
    }

    /** Used to update the approval process information document(s) (invoice) without updating the Wave invoice */
    const onInvoiceApprovalUpdateClick : ButtonListingKeyClick = async (e: any, property, baseButton) => {
        const { listingKey } = property;

        // Get the marketing order id
        const marketingOrderId = property.orders?.[0]?.id;
        if (!marketingOrderId) {
            throw new Error(`Error: unable to find marketing order id of ${listingKey}.`);
        }

        const modalData : IModalData<IModalDataInvoiceComment> = {
            baseButton, shouldShowModal : true, save: saveListingsCommentModalInvoiceApprovalUpdate, data: { property, comment: '' }
        };

        modalDataState[1](modalData);
    }

    /** filter out the orders that haven't been sent to advisors */
    const getUnsentOrderId = (property: PropertyDisplayResult) => {
        const listingKey = property.listingKey;
        const unsentInvoices = property.invoices.filter(e => new WaveInvoiceStatus(e.status).isUnsent);
        const invoiceNumberString = unsentInvoices[0]?.invoiceNumber;
        if (!invoiceNumberString)
            throw new Error(`Error: unable to find invoice number of ${listingKey} that has unsent statuses in marketing orders.`);

        const invoiceNumber = Number.parseInt(invoiceNumberString);
        const marketingOrderId = property.orders.filter(e => e.invoiceNumbers.includes(invoiceNumber))[0]?.id;
        if (!marketingOrderId) {
            throw new Error(`Error: unable to find marketing order id of ${listingKey} that has unsent statuses.`);
        }

        return marketingOrderId;
    }

    const getFirstUnsentOrderIdFromApprovalProcessInformation = (property: PropertyDisplayResult) => {
        let marketingOrderId : string = '';
        const approvalJsonList = property.approvalProcessInformationJsonList;
        const approvalProcessInformationList = approvalJsonList.map(e => ApprovalProcessInformation.fromJSON(e))
        const approvalDetails = approvalProcessInformationList.map(e => e.getApprovalDetail());
        approvalDetails.forEach((detail, index) => {
            const unapprovedPeople = detail?.approvalStepAction?.filter((step) => step.status !== 'Completed' && step.status !== 'Complete' && step.action === 'Approval').map((step) => step) ?? [];
            if (unapprovedPeople.length > 0) {
                marketingOrderId = approvalProcessInformationList[index].salesForceObjectId;
                return;
            }
        });

        if (!marketingOrderId) {
            throw new Error(`Error: unable to find marketing order id of ${property.listingKey} that has unsent statuses.`);
        }

        return marketingOrderId;
    }

    const onSendForApprovalClick : ButtonListingKeyClick = async (e, property) => {
        if (!property) {
            return;
        }

        const { listingKey } = property;
        setProcessedMessage((prevValue) => {
            const newValue = prevValue.filter(e => e.mlsId !== listingKey);
            newValue.push({ mlsId: property.listingKey, message: 'Sending for approval...', isLoading: true });
            return newValue;
        });

        const marketingOrderId = getFirstUnsentOrderIdFromApprovalProcessInformation(property);
        const invoiceApprovalSendRequest : DoFetchRequest<string> = { 
            urlParams: marketingOrderId
        };
        const result = await invoiceApprovalSend[2](invoiceApprovalSendRequest);
        if (result.hasData && result.data?.isSuccess) {
            console.log('result', result);
        }

        displayOnClickResult(listingKey, result);
    }

    const onSendToAdvisorsClick : ButtonListingKeyClick = async (e, property) => {
        if (!property) {
            return;
        }

        const { listingKey } = property;
        setProcessedMessage((prevValue) => {
            const newValue = prevValue.filter(e => e.mlsId !== listingKey);
            newValue.push({ mlsId: property.listingKey, message: 'Sending to advisor(s)...', isLoading: true });
            return newValue;
        });

        const marketingOrderId = getUnsentOrderId(property);
        const invoiceCompleteCommand : DoFetchRequest<InvoiceCompleteCommand> = { 
            body: { marketingOrderId: marketingOrderId, isTest: false }
        };
        const result = await doInvoiceComplete(invoiceCompleteCommand);
        if (result.hasData && result.data) {
            console.log('result', result);
        }

        const message = result.data ? `Success! Sent invoice for marketing order ${result.data.marketingOrderId}` 
                                    : result.errorMessage;
        setProcessedMessageFromResult(listingKey, message);
    }

    // (*) TODO: Ideally we get rid of this because of the type any. Should be specific to the call (9/12/2023 - pel)
    const displayOnClickResult = (listingKey: string, result: FetchCall<any>) => {
        const message = result.data ? (result.data.isSuccess ? 'Success!' : result.data.error) : result.errorMessage;
        setProcessedMessageFromResult(listingKey, message);
    }

    const setProcessedMessageFromResult = (listingKey: string, message: string) => {
        setProcessedMessage((prevValue) => {
            const newValue = [...prevValue];
            const newProcessedValue = newValue.find(e => e.mlsId === listingKey);
            if (newProcessedValue == null)
                throw new Error(`Error: unable to find replacement key of ${listingKey}.`);

            newProcessedValue.message = message;
            newProcessedValue.isLoading = false;
            return newValue;
        });
    }


    const saveListingsCommentModalInvoiceUpdate = async (baseButton: PropertyButtonBase, data: IModalDataInvoiceComment) => {
        const { property, comment } = data;
        const { listingKey } = property;

        // Get the marketing order id
        const marketingOrderId = property.orders?.[0]?.id;
        if (!marketingOrderId) {
            throw new Error(`Error: unable to find marketing order id of ${listingKey}.`);
        }

        modalDataState[1](prev => ({ ...prev, shouldShowModal: false }));
        baseButton.setIsLoading(true);
        const updateRequest : MarketingOrderInvoiceUpdateRequest = { marketingOrderId: marketingOrderId, updateComment: comment};
        const returnResult = await doMarketingOrderUpdate({ urlParams: marketingOrderId, body: updateRequest });
        updateButtonProcessedMessage(property, returnResult);
        baseButton.updateProperty(property);
        baseButton.setIsLoading(false);
    }

    /** Finish calling the REST api call after modal data is entered. */
    const saveListingsCommentModalInvoiceApprovalUpdate = async (baseButton: PropertyButtonBase, data: IModalDataInvoiceComment) => {
        const { property, comment } = data;
        const { listingKey } = property;

        // Get the marketing order id
        const marketingOrderId = property.orders?.[0]?.id;
        if (!marketingOrderId) {
            throw new Error(`Error: unable to find marketing order id of ${listingKey}.`);
        }

        modalDataState[1](prev => ({ ...prev, shouldShowModal: false }));
        baseButton.setIsLoading(true);
        const updateRequest : InvoiceApprovalUpdateCommand = { marketingOrderId: marketingOrderId, comment };
        const returnResult = await doInvoiceApprovalUpdate({ body: updateRequest });
        updateButtonProcessedMessage(property, returnResult);
        baseButton.updateProperty(property);
        baseButton.setIsLoading(false);
    }

    const onCreateSalesForceListingFromMLSClick : ButtonListingKeyClick = async (e, property) => {
        if (!property) {
            return;
        }

        const { listingKey } = property;
        setProcessedMessage((prevValue) => {
            const newValue = prevValue.filter(e => e.mlsId !== listingKey);
            newValue.push({ mlsId: property.listingKey, message: 'Requesting build SalesForce listing from MLS...', isLoading: true });
            return newValue;
        });

        const result = await mlsSync[2]({urlParams: listingKey});
        if (result.hasData && result.data?.isSuccess) {
            console.log('result', result);
        }

        displayOnClickResult(listingKey, result);
    }

    const onCreateListingMarketingOrderFromListingClick : ButtonListingKeyClick = async (e, property) => {
        if (!property) {
            return;
        }

        const { listingKey } = property;
        setProcessedMessage((prevValue) => {
            const newValue = prevValue.filter(e => e.mlsId !== listingKey);
            newValue.push({ mlsId: property.listingKey, message: 'Building marketing order from listing...', isLoading: true });
            return newValue;
        });

        const result : FetchCall<any> = await listingMarketingOrderCreate[2]({urlParams: listingKey});
        if (result.hasData && result.data) {
            result.data = 'The marketing orde was successfully created!';
        }

        displayOnClickResult(listingKey, result);
    }

    const onClicks : UseListingsPageOnClicksResult = { onMarketingOrderUpdateInvoiceClick, onMarketingOrderCreateInvoiceClick, 
                    onUpdateApprovalInvoiceClick, onSendForApprovalClick, onSendToAdvisorsClick, onInvoiceApprovalUpdateClick,
                    onCreateSalesForceListingFromMLSClick, onCreateListingMarketingOrderFromListingClick }; 
    return { processedMessages, setProcessedMessage, selectedCard, setSelectedCard, selected, setSelected, 
        modalDataState, propertyInvoices, 
        propertyInvoicesSetStateSetch, propertyLinkResult, doPropertyLinkGet, 
        onClicks, propertyLinkRun, propertyDisplayMLS };

}