import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as uuid from 'uuid';

import { ValueDropdown } from "@plazarre/phoenix-ux-order/lib/esm/ux/atoms/value-dropdown";
import { UploadFile } from "@plazarre/phoenix.ux.file-upload/lib/esm/ux/molecules/upload-file";

import { IDropdown } from "../../../core/idropdown";
import { IFieldValidation } from "../../../core/postcard/interfaces/ifield-validation";
import { IImportValidation } from "../../../core/postcard/interfaces/iimport-validation";
import { Contact } from "../../../core/postcard/domain/contact";

interface IMailingListImportListProps {
    shouldHideFileUploadAfterUse?: boolean;
    importValidation: IImportValidation | null;
    fileRecords: string[];
    extraFields: IExtraFields[];
    mappedFields: number[];
    fileName: string,

    updateContacts: (contactsUpdated: Contact[]) => void;
    updateFileName?: (fileNameUpdated: string) => void;
    updateFileRecords: (fileRecordsUpdated: string[]) => void;
    updateMappedFields: (mappedFieldsUpdated: number[]) => void;
    updateExtraFields: (extraFieldsUpdated: IExtraFields[]) => void;
    updateImportValidation: (importValidationUpdated: IImportValidation | null) => void;
}

export interface IExtraFields {
    1: string,
    0: number
}

export const MailingListImportList: React.FC<IMailingListImportListProps> = (props) => {
    const { shouldHideFileUploadAfterUse, updateContacts, updateFileName, updateFileRecords, updateExtraFields, updateImportValidation, updateMappedFields } = props;

    const [importValidation, setImportValidation] = useState<IImportValidation | null>(props.importValidation);
    const [fileRecords, setFileRecords] = useState<string[]>(props.fileRecords);
    const [extraFields, setExtraFields] = useState<IExtraFields[]>(props.extraFields);
    const [mappedFields, setMappedFields] = useState<number[]>(props.mappedFields);

    useEffect(() => {
        updateFileRecords(fileRecords);
    }, [fileRecords, updateFileRecords, props])

    useEffect(() => {
        updateExtraFields(extraFields);
    }, [extraFields, updateExtraFields, props])

    useEffect(() => {
        updateImportValidation(importValidation);
    }, [importValidation, updateImportValidation, props])

    useEffect(() => {
        updateMappedFields(mappedFields);
    }, [mappedFields, updateMappedFields, props])


    useEffect(() => {
        const getSourceField = (columnName: string, fields: string[]) : string => {
            const fieldOffset = importValidation?.fieldsValidation.find(e => e.fieldName === columnName)?.sourceFileColumn ?? -1;
            if (fieldOffset >= 0) {
                return fields[fieldOffset];
            } else {
                var mappedFieldIndex = importValidation?.fieldsValidation.findIndex(e => e.fieldName === columnName) ?? -1;
                if (mappedFieldIndex >= 0) {
                    const fieldIndex = mappedFields[mappedFieldIndex];
                    if (fieldIndex >= 0) {
                        return fields[fieldIndex];
                    }

                }
            }

            return '';
        }

        const convertCSVRowToContact = (row: string) => {
            const fields = row.split(',').map(e => e.trim());
            
            const firstName = getSourceField('fn', fields);
            const lastName = getSourceField('ln', fields);
            const resourceName = uuid.v4();
            const streetAddress = getSourceField('addr', fields);
            const unit = getSourceField('unit', fields);
            const city = getSourceField('ct', fields);
            const state = getSourceField('st', fields);
            const postalCode = getSourceField('zip', fields);
            const country = getSourceField('country', fields);
    
            const contact : Contact = {
                name : `${firstName} ${lastName}`,
                id: resourceName,
                addresses : [{streetAddress, unit, city, state, postalCode, country, poBox: '', type: ''}],
                selectedAddressIndex : 0,
            }
    
            return contact;
        }

        var contacts = fileRecords.map(convertCSVRowToContact).splice(1);
        contacts.sort(Contact.sortContactByNameAsc);
        updateContacts(contacts);
    }, [fileRecords, updateContacts, importValidation?.fieldsValidation, mappedFields]);

    
    const handleChange = useCallback((files: File[]) => {
        // If no files came in, no need to process anything 
        if (!files.length) {
            
            // If we don't have a have name, then the data was cleared. Reset, otherwise we are loading in prior data
            if (!props.fileName) {
                setImportValidation(null);
            }

            return;
        }

        // Notify the parent of the file name if needed
        if (updateFileName) {
            updateFileName(files[0].name);
        }

        const reader = new FileReader();
        reader.readAsText(files[0]);
        reader.onload = ((event: ProgressEvent<FileReader>) => {
            const text = reader?.result as string ?? "";
            console.log('event', event);
            console.log('text', text);

            const fileExtension = files[0].name?.split('.')?.pop()?.toLocaleLowerCase() ?? '';
            if (fileExtension === 'csv') {
                const lines = text.split(/(?:\r\n|\n)+/).filter((value: any) => { return value.length !== 0 });
                const headers = lines[0].split(',').map(e => e.trim());
                console.log('lines', lines);
                console.log('headers', headers);

                const fields = ['fn', 'ln', 'addr', 'unit', 'ct', 'st', 'zip', 'country'];
                const fieldsValidation = fields.map((value) => {
                    const sourceFileColumn = headers.findIndex(e => e === value);

                    let isRequired = true;
                    if (value === 'country') {
                        isRequired = false;
                    }

                    const validation: IFieldValidation = { 
                        fieldName: value, 
                        fieldDescription: '', 
                        isFieldFound: sourceFileColumn >= 0, 
                        sourceFileColumn,
                        isRequired, 
                        fieldAliases: [] 
                    };
                    return validation;
                });

                const extraFields = Object.entries(headers).filter(e => !fields.includes(e[1])) as unknown as IExtraFields[];
                setExtraFields(extraFields);

                const recordValidation: IImportValidation = { recordsFound: lines.length - 1, fieldsValidation };
                console.log('recordValidation', recordValidation);
                setImportValidation(recordValidation);
                setFileRecords(lines);

                const mappedFields = recordValidation.fieldsValidation.map((e) => -1);
                console.log('mappedFields', mappedFields);
                setMappedFields(mappedFields);
            }
        });
    }, [updateFileName, props.fileName]);

    const setMappedField = useCallback((fieldName: string, mappedFieldExtraIndex: number) => {
        console.log('fieldname', fieldName, mappedFieldExtraIndex);
        setMappedFields((prevValue) => {
            const fieldIndex = importValidation?.fieldsValidation.findIndex(e => e.fieldName === fieldName) ?? -1;
            if (fieldIndex === -1) {
                console.error(`Error setMappedField: unable to find required field '${fieldName}'`);
            } 

            prevValue[fieldIndex] = mappedFieldExtraIndex;
            return [...prevValue];
        });
    }, [importValidation?.fieldsValidation]);

    const fieldValidationJsx: JSX.Element[] = [];
    if (importValidation) {
        fieldValidationJsx.push(
            <>
                <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                    <Col xxl={3}>
                        Columns found in file:
                    </Col>
                </Row>
                <Row style={{marginLeft: '1em'}}>
                    <Col className="text-center" xxl={2} style={{ border: '1px dotted gray', padding: 0 }}>
                        <b>Field</b>
                    </Col>
                    <Col className="text-center" xxl={1} style={{ border: '1px dotted gray', padding: 0 }}>
                        <b>Present?</b>
                    </Col>
                    <Col className="text-center" xxl={1} style={{ border: '1px dotted gray', padding: 0 }}>
                        <b>Required?</b>
                    </Col>
                    <Col className="text-center" xxl={6} style={{ border: '1px dotted gray', padding: 0 }}>
                        <b>Mapped Field (if needed)</b>
                    </Col>
                </Row>
            </>
        );

        importValidation.fieldsValidation.map((value, index) => {
            const isRequiredBackgroundColor = value.isRequired ? 'white' : 'lightyellow';

            const isPresentIcon = value.isFieldFound ? 'check' : 'xmark';
            const isPresentBackgroundColor = value.isFieldFound ? 'honeydew' : 'mistyrose';

            const extraFieldsDropdownValues = extraFields.map<IDropdown>((value, index) => { 
                const dropdownValue : IDropdown = { name: value[1], index: +value[0] }
                console.log('dropdown value', dropdownValue);
                return dropdownValue;
            });

            extraFieldsDropdownValues.push({name: 'Not Mapped', index: -1});
            if (!value.isRequired) {
                extraFieldsDropdownValues.push({name: 'Skip Field', index: 999});
            }

            console.log('value/index/extrafields/mappedfield[index]', value, index, extraFieldsDropdownValues, mappedFields[index]);
            const mappedFieldJsx = value.isFieldFound ? <></> : 
                                <ValueDropdown 
                                    variant='secondary' 
                                    dropdownValues={extraFieldsDropdownValues} 
                                    setValue={(e: any) => {setMappedField(value.fieldName, e)}} 
                                    value={mappedFields[index]}
                                    />;
            return (
                <Row style={{marginLeft: '1em'}}>
                    <Col className="text-center" xxl={2} style={{ border: '1px dotted gray', backgroundColor: isPresentBackgroundColor }}>
                        {value.fieldName}
                    </Col>
                    <Col className="text-center" xxl={1} style={{ border: '1px dotted gray', backgroundColor: isPresentBackgroundColor }}>
                        <FontAwesomeIcon icon={isPresentIcon} />
                    </Col>
                    <Col className="text-center" xxl={1} style={{ border: '1px dotted gray', backgroundColor: isRequiredBackgroundColor }}>
                        {value.isRequired ? 'yes' : 'no'}
                    </Col>
                    <Col xxl={6} style={{ border: '1px dotted gray' }}>
                        {mappedFieldJsx}
                    </Col>
                </Row>
            );
        }).forEach((jsx) => fieldValidationJsx.push(jsx));

        var areAllNeededFieldsMapped = mappedFields.reduce((previousValue, currentValue, currentIndex) => {
            const {fieldsValidation} = importValidation;

            console.log('reducer', previousValue, currentValue, currentIndex);
            if (currentValue === -1 && fieldsValidation[currentIndex].isRequired && !fieldsValidation[currentIndex].isFieldFound) {
                return -1;
            }

            return previousValue;
        }, 0) !== -1;

        if (!areAllNeededFieldsMapped) {
            fieldValidationJsx.push(
                <Row style={{ marginTop: '1rem' }}>
                <Col style={{color:'red', marginLeft: '1rem'}}>
                    Please map all required fields before you can continue.
                </Col>
            </Row>
            );
        }


        fieldValidationJsx.push(
            <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <Col style={{marginLeft: '1rem'}}>
                    Found {importValidation.recordsFound} record(s) in file.
                </Col>
            </Row>
        )
    }

    const uploadFileDisplay = (shouldHideFileUploadAfterUse ?? false) ? (importValidation ? 'none' : 'inline') : 'inline';
    return (
        <>
        <div style={{display: uploadFileDisplay}}>
            <UploadFile name="upload" multiple={false} fileTypes={['csv', 'txt']} handleChange={handleChange}/>
        </div>
            { fieldValidationJsx }
        </>
    );
}