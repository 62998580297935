import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FetchCall } from "@plazarre/phoenix.ux.fetch/lib/esm/core/domain/fetch-call";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { LoadStatusType } from "../../core/postcard/data-types/fetch-load-status-type";
import { Contact } from "../../core/postcard/domain/contact";
import { AddressCard, IAddressCardTag } from "../molecules/address-card";

// import { useUserImpersonateList } from "@plazarre/phoenix.ux.fetch/lib/esm/hook/use-user-impersonate-list";

interface IMailingListProps {
    contacts : FetchCall<Contact[]>,
    removedContacts: Contact[],
    setContactAddressIndex: (contactId: string, index: number) => void;
    updateContactsAndRemovedContacts: (contactsUpdated: Contact[], removedContactsUpdated: Contact[]) => void;
}

export const MailingList : React.FC<IMailingListProps> = (props) => {
    const {contacts, removedContacts, setContactAddressIndex, updateContactsAndRemovedContacts} = props;
    
    const searchRef = useRef<HTMLInputElement>(null);
    const [search, setSearch] = useState<string>('');
    const [duplicateAddressTags, setDuplicateAddressTags] = useState<string[]>([]);
    const [invalidAddressTags, setInvalidAddressTags] = useState<string[]>([]);
    const [areContactsTagged, setAreContactsTagged] = useState(false);

    const createDuplicateTags = (contacts: Contact[]) : string[] => {
        const nameAudit : string[] = [];
        const duplicateTags : string[] = [];
        contacts.forEach((contact) => {
            const duplicate = nameAudit.find(e => e === contact.name);
            if (duplicate) {
                duplicateTags.push(contact.id);
            } else {
                nameAudit.push(contact.name);
            }
        });

        return duplicateTags;
    };

    const createInvalidAddressTags = (contacts: Contact[]) : string[] => {
        const invalidAddressTags : string[] = [];
        contacts.forEach((contact) => {
            const address = contact.addresses[0];
            const invalid = !address?.city?.trim() || !address?.streetAddress?.trim() || !address?.state?.trim() || !address?.postalCode?.trim();
            if (invalid) {
                invalidAddressTags.push(contact.id);
            } 
        });

        return invalidAddressTags;
    };

    /** Handle updates to contacts to tag them */
    useEffect(() => {
        if (!contacts?.data)
            return;

        if (areContactsTagged)
            return;

        const duplicateTags = createDuplicateTags(contacts.data);
        setDuplicateAddressTags(duplicateTags);
        
        const invalidAddressTags = createInvalidAddressTags(contacts.data);
        setInvalidAddressTags(invalidAddressTags);
        
        setAreContactsTagged(true);
    }, [contacts, areContactsTagged]);

    const onClickDelete = useCallback((key: string) => {
        const index = contacts?.data?.findIndex(e => e.id === key) ?? -1;
        if (index === -1) {
            console.error(`Error MailingList: can not find item to delete: ${key}`);
        }

        const newContacts = [...(contacts.data ?? [])];
        newContacts.splice(index,1);

        newContacts.sort(Contact.sortContactByNameAsc);
        const newRemovedContacts = [...removedContacts];
        newRemovedContacts.push((contacts.data ?? [])[index]);
        newRemovedContacts.sort(Contact.sortContactByNameAsc);
        updateContactsAndRemovedContacts(newContacts, newRemovedContacts);
    }, [contacts.data, removedContacts, updateContactsAndRemovedContacts]);

    const mapContactToAddressCard = (contact: Contact, onClickDelete: (key: string) => void) : JSX.Element | null => {
        if (search !== '' && !contact.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
            return null;
        } 

        const duplicateAddressTag = duplicateAddressTags.find(e => e === contact.id);
        const tags : IAddressCardTag[] = [];
        if (duplicateAddressTag) {
            tags.push({name: 'Potential Duplicate', type: 'warning'});
        }
        
        const invalidAddressTag = invalidAddressTags.find(e => e === contact.id);
        if (invalidAddressTag) {
            tags.push({name: 'Invalid Address', type: 'danger'});
        }

        return (
                <AddressCard key={contact.id} contact={contact} onClickDelete={onClickDelete} tags={tags} setContactAddressIndex={setContactAddressIndex}/>
               );
    };


    const onSearchChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.value && e.currentTarget.value.length > 0) {
            console.log('characters!');
        }

        setSearch(e.currentTarget.value);
    }

    const searchClearDisplay = search.length > 0 ? 'inline' : 'none';
    let addressCardsJsx = contacts?.data?.map((value) => mapContactToAddressCard(value, onClickDelete)).filter(e => e !== null) ?? [];
    if (addressCardsJsx?.length === 0) {
        addressCardsJsx = [<span key="no-contacts-match">No contacts match.</span>]
    }

    const noAddressCardsJsx = <span key="no-contacts">There are no contacts.</span>

    return (
        <>
            <Row className="align-items-center" style={{ marginBottom: '1rem' }}>
                <Col xxl={1}>
                    Search:
                </Col>
                <Col xxl={9}>
                    <div className="input-group">
                        <Form.Control
                            ref={searchRef}
                            type="input"
                            style={{ maxWidth: '30vw' }}
                            onChange={(e: any) => onSearchChange(e)}
                            value={search}
                        />
                        <span key="search-x-mark"
                            style={{ marginTop: '0.4rem', marginLeft: '-1.25rem', zIndex: 10, display: searchClearDisplay }}
                            onClick={() => { setSearch(''); searchRef?.current?.focus(); }}
                        >
                            <FontAwesomeIcon icon="xmark" />
                        </span>
                    </div>
                </Col>
                <Col xxl={2}>
                    {(contacts.data?.length ?? 0) > 0 && `${addressCardsJsx.length} contacts ${search.length > 0 ? '(filtered)' : ''}`}
                    {(contacts.data?.length ?? 0) === 0 && '0 contacts'}
                </Col>
            </Row>

            <Container fluid style={{ height: '60vh', overflowY: 'auto' }}>
                <Row className="g-3">
                    {props.contacts.loadStatus === LoadStatusType.Loading && (<Spinner animation='border' />)}
                    {props.contacts.loadStatus === LoadStatusType.Success && (contacts.data?.length ?? 0) === 0 && noAddressCardsJsx}
                    { props.contacts.loadStatus === LoadStatusType.Success && (contacts.data?.length ?? 0) > 0 && addressCardsJsx }
                    {props.contacts.loadStatus === LoadStatusType.Error && (<span>Sorry, unable to load Google contacts.</span>)}
                </Row>
            </Container>
            {
                /*

            */
            }
        </>
    );

}

