import { Card, CardProps, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ApprovalActionStatusType, ApprovalProcessInformation, MarketingOrder, PropertyDisplayResult } from "@plazarre/phoenix.javascript.approval";
import { WaveInvoice, WaveInvoiceStatus } from "@plazarre/phoenix.javascript.approval";
import { SalesForceLink } from "../atoms/link-salesforce";
import { Link } from "../atoms/link";
import { ReactState } from "../../hooks/use-invoice-page";
import { UseListingsPageOnClicksResult } from "../../hooks/use-listings-page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addWeeks } from "date-fns";
import { useState } from "react";
import { ApprovalInvoiceUpdateButton, CreateInvoiceButton, CreateListingMarketingOrderFromListing, CreateSalesForceListingFromMLSButton, SendApprovalInvoiceButton, SendInvoiceToAdvisorButton, UpdateInvoiceButton } from "../atoms/invoices/invoice-buttons";
import { PropertyButton, PropertyButtonBaseParams } from "../atoms/invoices/property-button-base";
import { RefreshButton } from "./refresh-button";
import { MarketingOrderStatusType } from "@plazarre/phoenix.javascript.approval";

export interface IPropertyInvoiceCardProps {
    property: PropertyDisplayResult,
    updateProperty: (property: PropertyDisplayResult) => Promise<void>,
    isSelected: boolean,
    setSelected: ReactState<string | null>,
    onClicks: UseListingsPageOnClicksResult
}

const mapMarketingOrderLink = (marketingOrder: MarketingOrder, index: number, marketingOrders: MarketingOrder[]) : JSX.Element => {
    const commaString = index === marketingOrders.length - 1 ? '' : ', ';
    const canceledJsx = marketingOrder.status.toString() === MarketingOrderStatusType.Canceled 
                                                    ? <span style={{color: 'red'}}> <b>[CANCELED]</b></span> 
                                                    : <></>;
    const salesForceOrderJsx = <span> 
                                    <SalesForceLink id={marketingOrder.id} tabId='marketingorder' /> 
                                    {canceledJsx}
                                    {commaString}
                                </span>; 
    return salesForceOrderJsx;
}

const mapInvoiceLink = (invoice: WaveInvoice, index: number, invoices: WaveInvoice[]) : JSX.Element => {
    const invoiceDescription = `${invoice.invoiceNumber}/${invoice.status}`;
    const invoiceUrl = new URL(invoice.pdfUrl);
    const commaString = index === invoices.length - 1 ? '' : ', ';
    const invoiceJsx =  <span>
                            <Link url={invoiceUrl}>
                                <span>{invoiceDescription}</span>
                            </Link>
                            {commaString}
                        </span>;
    console.log('invoiceJsx', invoiceJsx);
    return invoiceJsx;
};

export const PropertyInvoiceCard: React.FC<IPropertyInvoiceCardProps> = (props) => {
    const { isSelected, property, setSelected, onClicks: useListingsPageOnClicksResult, updateProperty } = props;
    const { onMarketingOrderCreateInvoiceClick, onMarketingOrderUpdateInvoiceClick,
            onSendForApprovalClick, onSendToAdvisorsClick, onInvoiceApprovalUpdateClick, 
            onCreateSalesForceListingFromMLSClick, onCreateListingMarketingOrderFromListingClick } = useListingsPageOnClicksResult;

    const isLoadingState = useState(false);
    const isDisabledState = useState(false);

    const buttonParams : PropertyButtonBaseParams = {
        isLoadingState,
        isDisabledState,
        updateProperty
    };

    const buttons : PropertyButton[] = [
        new CreateInvoiceButton(onMarketingOrderCreateInvoiceClick, buttonParams),
        new UpdateInvoiceButton(onMarketingOrderUpdateInvoiceClick, buttonParams),
        new SendApprovalInvoiceButton(onSendForApprovalClick, buttonParams),
        new SendInvoiceToAdvisorButton(onSendToAdvisorsClick, buttonParams),
        new ApprovalInvoiceUpdateButton(onInvoiceApprovalUpdateClick, buttonParams),
        new CreateSalesForceListingFromMLSButton(onCreateSalesForceListingFromMLSClick, buttonParams),
        new CreateListingMarketingOrderFromListing(onCreateListingMarketingOrderFromListingClick, buttonParams)
    ];

    let approvalStepActionsJsx : JSX.Element[] = [<span>None</span>];

    // Build array --- one for each marketing order
    const approvalJsonList = property?.approvalProcessInformationJsonList;
    const approvalProcessInformationList = approvalJsonList.map(e => ApprovalProcessInformation.fromJSON(e));
    const approvalDetails = approvalProcessInformationList.map(e => e.getApprovalDetail());
    console.log(property.fullStreetAddress, approvalDetails);
    let isApprovalCompleted = approvalDetails.length > 0 ? true : false;
    approvalStepActionsJsx = approvalDetails.flatMap((approvalDetail, index) => {
        const jsx = approvalDetail.approvalStepAction?.map((approvalStepAction, index, approvalStepActions) => {
            var subjectId = approvalStepAction?.person?.subjectId;
            const owner = approvalStepActions.find(e => e.action === 'Copy');
            var ownerSubjectId = owner?.person?.subjectId;
            if (!subjectId || !ownerSubjectId || subjectId === ownerSubjectId) {
                return <></>;
            }

            const key = `${index}-${subjectId}-${index}`;
            if (approvalStepAction.status !== ApprovalActionStatusType.Complete 
                && approvalStepAction.action !== ApprovalActionStatusType.Completed) { 
                isApprovalCompleted = false;
            }

            return <span key={key}>{approvalStepAction?.person?.name}: {`${approvalStepAction.status}`}&nbsp;</span>
            }) ?? [];

        return jsx;
        });

    const buttonsJsx : JSX.Element[] = [];
    buttons.forEach(e => {
        const isMatch = e.isMatch(property, isApprovalCompleted);
        if (!isMatch) {
            return;
        }

        buttonsJsx.push(e.jsx(property));
    });
   


    //const processedMessage = processedMessages.find(e => e.mlsId === property.listingKey);
    // const isWaiting = processedMessage ? processedMessage.isLoading : false;
    const listing = property.listings?.[0];

    const salesForceListingIdJsx = listing?.id 
                ? <SalesForceLink id={listing.id} tabId='listing' /> 
                : <></>;

    /*
    const processMLSButton = listing?.id ? <></> : 
        <ButtonSpinner waitingIcon={isWaiting} variant='danger' onClick={(e) => onButtonClick(e, property.listingKey)}>
            Process MLS
        </ButtonSpinner>
    */

    //const processedMLSMessage = processedMessages.find(e => e.mlsId === property.listingKey);
    //const message = processedMLSMessage ? <><br/>{processedMLSMessage.message}</> : '';
    let advisorsString = property.advisorName;
    if (property.coAdvisorName) {
        advisorsString += `, ${property.coAdvisorName}`;
    }

    if (advisorsString.includes('Jenny Ames')) {
        return <></>;
    }

    const ordersUncancelled = property.orders?.filter(e => e.status.toString() !== MarketingOrderStatusType.Canceled);
    const orderDateTimesString = ordersUncancelled.map(e => new Date(e.created).toLocaleDateString()).join(', ');
    let invoicesJsx : JSX.Element[] = [];
    if (property.invoices.length > 0) {
        invoicesJsx.push(...property.invoices.map(mapInvoiceLink));
    
        if (property.invoices.length < ordersUncancelled.length) {
            invoicesJsx.push(<>, <span style={{color: 'red'}}><b>[MISSING]</b></span></>);
            }
    
        const isAllSentOrPaid = property.invoices && property.invoices.length > 0 
                && property.invoices.every((e) => new WaveInvoiceStatus(e.status).isMatch([WaveInvoiceStatus.PAID, WaveInvoiceStatus.SENT, WaveInvoiceStatus.VIEWED, WaveInvoiceStatus.OVERDUE]))
                && property.invoices.length >= (ordersUncancelled.length ?? 0);
        
        if (isAllSentOrPaid && !property.areInvoiceOveragesOutstanding && !(property as any).areApprovalsOutstanding) {
            return <></>;
        }
    }

    const statusAndPrivate = `${property.standardStatus}${property.isPrivate ? ' - Private' : ''}`;
    const cardOutlineProps : CardProps = isSelected ? { border: 'danger' } : {};

    if (property.mlsData && property.mlsData.length > 0) {
        console.log('value', property.mlsData);
    }

    let cardHeaderName = property.fullStreetAddress;
    const isBuilding = property.building?.id ? true : false;
    if (isBuilding) {
        console.log('building', property);
        cardHeaderName = `${property.building?.name}`;
    }

    console.log('Uncanceled orders', ordersUncancelled);

    const isExemptJsx = property.isExemptFromMarketingPackage ? 
                                                            <OverlayTrigger overlay={<Tooltip id="Exempt" placement="right">
                                                                    No package needed: {property.exemptFromMarketingPackageReason}
                                                                    </Tooltip>}>
                                                                 <div style={{ marginRight: '0.5rem'}}>
                                                                    <FontAwesomeIcon icon="ban" color="black" size="1x"/>
                                                                 </div>  
                                                            </OverlayTrigger>
                                                               : <></>

    const areDefaultDates = !!(ordersUncancelled?.filter(e => new Date(e.invoiceDefaultDate).getFullYear() > 1969)?.length);
    const areErrors = (property.errors?.length ?? 0) > 0;
    const errorsJsx = areErrors ?   <div style={{marginRight: '0.5rem' }}>
                                        <FontAwesomeIcon icon="triangle-exclamation" color="red" size="1x"/>
                                    </div> 
                                    : <></>;

    const overagesOutstandingJsx = !property.areInvoiceOveragesOutstanding ? <></>
                            : <div style={{marginRight: '0.5rem' }}>
                                <FontAwesomeIcon icon="dollar-sign" color="red" size="1x"/>
                              </div>;

    const propertyAny : any = property;                            
    const listingContractDateString = property.isPrivate ? new Date(propertyAny.statusChangeTimestamp).toLocaleDateString() 
                                                   : new Date(property.listingContractDate).toLocaleDateString();
    if (property.isPrivate)
        console.log('date', property.fullStreetAddress, propertyAny.statusChangeTimestamp, listingContractDateString, property);

    // using date-fns, add two weeks to the listing contract date
    const listingContractDatePlusTwoWeeks = property.isPrivate ? addWeeks(new Date(propertyAny.statusChangeTimestamp), 2) : null;

    // Remove any mlsdata items that are identical to multiple active properties
    const multipleActiveListingValues = property.multipleActiveListings.map((e) => e.listingKey);
    const mlsData = property.mlsData?.filter((e) => !multipleActiveListingValues.includes(e));
    const mlsIds = property.multipleActiveListings.length > 0 ? property.multipleActiveListings.map(e => `${e.listingKey} [${e.propertyType}]`).join(', ') 
                                                              : property.listingKey;


    return (
        <Card style={{ width: '30rem', marginBottom: '1rem' }} onClick={(e) => setSelected(property.listingKey)} {...cardOutlineProps}>
            <Card.Header>
                <div className="d-flex justify-content-between">
                    <div>{cardHeaderName} ({statusAndPrivate})</div>
                    <div style={{ display: 'flex'}}>
                        {overagesOutstandingJsx}{isExemptJsx}{errorsJsx}
                        <RefreshButton object={property} update={updateProperty} />
                    </div>
                </div>
                
            </Card.Header>
            <div style={{ paddingLeft: '1rem' }}>
                {isBuilding && (<>Addresses: {property.listings?.map(e => e.name).join(', ')}<br /></>)}
                Active MLS Id(s): {mlsIds}<br />
                <b>Property Type:</b> {property.propertyType}{property.physicalPropertyType && (<>/{property.physicalPropertyType}</>)}<br />
                {property.physicalPropertySubType && (<>Property Sub-Type: {property.physicalPropertySubType}<br /></>)}
                Order Date(s): {orderDateTimesString}<br />
                Listing Contract Date: {listingContractDateString}<br />
                {listingContractDatePlusTwoWeeks && (<>Listing Contract Date + 2 Weeks: {listingContractDatePlusTwoWeeks.toLocaleDateString()}<br /></>)}
                Old MLS Id(s): {mlsData.join(', ')}<br />
                Advisor(s): {advisorsString}<br />
                SalesForce Listing Id: {salesForceListingIdJsx}<br />
                Marketing Ids: {property.orders?.map(mapMarketingOrderLink)}
                <br />
                {areDefaultDates && (<>Default Invoice Date: {ordersUncancelled.map(a => new Date(a.invoiceDefaultDate).toLocaleDateString())?.join(', ')}<br /></>)}
                Invoices: {invoicesJsx}<br />
                Approval(s): {approvalStepActionsJsx}<br />
                {//message
                }
            </div>
            <Card.Footer>
                {buttonsJsx.length > 0 && buttonsJsx}
                &nbsp;
                {//buildInvoiceButtonJsx
                }
                &nbsp;
                {
                //sendInvoiceButtonJsx
            }
                &nbsp;
                {
                //updateInvoiceButtonJsx
            }
            </Card.Footer>
        </Card>
    );
}