import React, { PropsWithChildren, useContext, useState } from 'react';
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ToastContainer } from 'react-toastify';

// import '@plazarre/phoenix-ux-order/lib/esm/app.css';
import 'react-datepicker/dist/react-datepicker.css';

import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

import './App.css';

import { AdvisorBusinessTerms } from './business-terms';
//import { PhotosAccept } from './photos-accept';
import { AdvisorsPage } from './ux/pages/advisors';
//import { PostcardOrder } from './ux/pages/order-postcard';
//import { OrderPostcardFileFormat } from './ux/pages/order-postcard-file-format';
// import { StreamingLogPage } from './ux/pages/streaming-log';
import { PaperManagePage } from './ux/pages/paper-manage';
import { PostcardManagePage } from './ux/pages/postcard-manage';
import { EVHeader } from '@plazarre/phoenix-ux-order/lib/esm/ux/organisms/ev-header';
import { PostCardEditTemplate } from './ux/templates/postcard-manage/postcard-edit';
import { PaperEditTemplate } from './ux/templates/paper-manage.tsx/paper-edit';
import { AdvisorsSystemsPage } from './ux/pages/advisors-systems';
import { BusinessPlanningSurveyPage } from './ux/pages/business-planning';
import { RequireAuth } from './ux/functions/auth/require-auth';
import { LoginPage } from './ux/functions/auth/login-page';
import { AppContext, useApplicationContext } from '@plazarre/phoenix-ux-order/lib/esm/hook/use-application-context';

import { StreamingLogPage } from './ux/pages/streaming-log';
import { UploadFloorplan } from './ux/pages/upload-floorplan';
import { ListingsPage } from './ux/pages/listings';
import { InvoiceRejectPage } from './ux/pages/invoice-reject';
import { InvoiceApprovePage } from './ux/pages/invoice-approve';
import { EmailsPage } from './ux/pages/emails';
import { PostcardOrder } from './ux/pages/order-postcard';
import { SocialMediaUploadPage } from './ux/pages/social-media';
import { CoverSheetPage } from './ux/pages/cover-sheet';
import { CommissionsPage } from './ux/pages/commission/commissions';

interface RequireSignInProps {
    children?: React.ReactNode;
}

const RequireSignIn : React.FC<PropsWithChildren<RequireSignInProps>> = (props) => {
    const appContext = useContext(AppContext);
    if (!appContext.isUserLoggedIn) {
        return <>You must sign in to view this page.</>;
    }

    return (
        <>
            {props.children}
        </>
    );
}


export const appInsights = new ApplicationInsights({ config: {
  connectionString: 'InstrumentationKey=6ec4c0e0-1939-46fd-b77f-e42db68d576b;IngestionEndpoint=https://northcentralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://northcentralus.livediagnostics.monitor.azure.com/'
} });

appInsights.loadAppInsights();
appInsights.trackPageView();

interface RootProps {
    headerTitle? : string
}

const Root : React.FC<RootProps> = (props) => {
    const [currentSettings] = useApplicationContext();
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const { headerTitle } = props;

    // Only include the header if the title is set. 
    // For screen log full screen login, no need for the header
    const evHeaderJsx = headerTitle ? <EVHeader shouldShowMenu={true} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} title={headerTitle} /> : <></>;
    const pageTitle = process.env.REACT_APP_WEBSITE_NAME ?? 'E&V';

    return (
        <AppContext.Provider value={currentSettings}>
            <Helmet>
                <title>{pageTitle}</title>  
            </Helmet>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <Container fluid>
                {evHeaderJsx}
            <Row id="detail">
                <Col lg={12}>
                    <Outlet />
                </Col>
            </Row>
            </Container>
        </AppContext.Provider>
    );
}

const router = createBrowserRouter([
    {
        path: '/login',
        element: <Root />,
        children: [
            {
                index: true,
                element: <LoginPage />
            }
        ],
    },
    {
        path: '/upload-floorplan',
        element: <Root />,
        children: [
            {
                index: true,
                element: <UploadFloorplan />
            }
        ],
    },
    {
        path: '/survey/outlook',
        element: <Root />,
        children: [
            {
                index: true,
                element: <RequireAuth>
                            <BusinessPlanningSurveyPage />
                        </RequireAuth>
            },
        ]
    },
    {
        path: '/admin/postcard',
        element: <Root headerTitle="POSTCARD" />,
        children: [
            {
                index: true,
                element: <PostcardManagePage />
            },
            {
                path: '/admin/postcard/:postcardId',
                element: <PostCardEditTemplate />
            }
        ]
    },
    {
        path: '/admin/paper',
        element: <Root headerTitle="PAPER" />,
        children: [
            {
                index: true,
                element: <PaperManagePage />
            },
            {
                path: '/admin/paper/:paperId',
                element: <PaperEditTemplate />
            }
        ]
    },
    {
        path: '/admin/advisors',
        element: <Root headerTitle="ADVISORS" />,
        children: [
            {
                index: true,
                element: <AdvisorsPage />
            },
        ]
    },
    {
        path: '/admin/advisor-businessterms',
        element: <Root headerTitle="ADVISORS TERMS" />,
        children: [
            {
                index: true,
                element: <AdvisorBusinessTerms />
            },
        ]
    },
    {
        path: '/admin/advisors/systems',
        element: <Root headerTitle="ADVISOR SYSTEMS" />,
        children: [
            {
                index: true,
                element: <AdvisorsSystemsPage />
            },
        ]
    },
    {
        path: '/streaming',
        element: <StreamingLogPage />,
    },
    {
        path: '/admin/invoice/approve/:code',
        element: <Root headerTitle="INVOICE" />,
        children: [
            {
                index: true,
                element: <RequireAuth>
                            <InvoiceApprovePage />
                         </RequireAuth>
            },
        ]
    },
    {
        path: '/admin/invoice/reject/:code',
        element: <Root headerTitle="INVOICE" />,
        children: [
            {
                index: true,
                element: <RequireAuth>
                            <InvoiceRejectPage />
                         </RequireAuth>
            },
        ]
    },
    {
        path: '/admin/listings',
        element: <Root headerTitle="LISTINGS" />,
        children: [
            {
                index: true,
                element: <ListingsPage />
            },
        ]
    },
    {
        path: '/admin/emails',
        element: <Root headerTitle="EMAILS" />,
        children: [
            {
                index: true,
                element: <EmailsPage />
            },
        ]
    },
    {
        path: '/order/postcard',
        element: <Root headerTitle="POSTCARD" />,
        children: [
            {
                index: true,
                element: <PostcardOrder />
            },
        ]
    },
    {
        path: '/admin/upload/social',
        element: <Root headerTitle="SOCIAL MEDIA" />,
        children: [
            {
                index: true,
                element: <SocialMediaUploadPage />
            },
        ]
    },
    {
        path: '/admin/coversheet',
        element: <Root headerTitle="COVER SHEET" />,
        children: [
            {
                index: true,
                element: <CoverSheetPage />
            },
        ]
    },
    {
        path: '/admin/commissions',
        element: <Root headerTitle="COMMISSIONS" />,
        children: [
            {
                index: true,
                element: <RequireSignIn><CommissionsPage /></RequireSignIn>
            },
        ]
    }
]);


/*
<Routes>
                        <Route path="/" element={<StreamingLogPage />} />
                        <Route path="/streaming" element={<StreamingLogPage />} />
                        <Route path="/admin/business-terms" element={<AdvisorBusinessTerms />} />
                        <Route path="/admin/advisors" element={<AdvisorsPage />} />
                        <Route path="/photos/accept" element={<PhotosAccept />} />
                        <Route path="/order/postcard" element={<PostcardOrder />} />
                        <Route path="/order/postcard-file-format" element={<OrderPostcardFileFormat/>} />
                        <Route path="/admin/postcard" element={<PostcardManagePage />} />
                    </Routes>
                    */

export const App = (): JSX.Element => {
    return (
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    );
}

export default App;
