
import { Contact } from "../core/postcard/domain/contact";
import { getBearerHeader } from "./fetch";
import { Result } from "./result";

export interface ContactGroup {
    id: string,
    name : string,
    count: number,
    countWithAddresses?: number,
    people: Contact[]
}

export const getGoogleGroups = async (userLoginEmail: string) : Promise<Result<ContactGroup[]>> => 
{
    // (*) TODO: Abort request needed (10/16/2022 - pel)
    //const baseUri2 = 'https://localhost:44334/';
    //const baseUri2 = 'https://pho.enix.re/';
    const emailAddress = encodeURIComponent(userLoginEmail);
    // 'karen.arenson@engelvoelkers.com'; // 
    const requestInit = getBearerHeader();
    requestInit.body = '[]';
    requestInit.method = 'POST';
        
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': (requestInit?.headers as any)['Authorization']
    }
    
    requestInit.headers = headers;

    try
    {
    // const response = await fetch(`${baseUri2}api/googlerezora/googlegroups/${emailAddress}`, requestInit);
    
    const response = await fetch(`/api/contact/GoogleGroupsGet/${emailAddress}`, requestInit);
    if (!response.ok) {
        const errorMessage = `Unable to run api/googlerezora/googlegroups/${emailAddress}, status code = ${response.statusText}`;
        return Result.Error(errorMessage);
    }

    const data : Result<ContactGroup[]> = await response.json();
    return Result.Success(data);
    } 
    catch(exp : any) {
        console.log('exception', exp);
        const errorMessage = `Unable to run api/googlerezora/googlegroups/${emailAddress}, error = ${exp.message}`;
        return Result.Error(errorMessage);
    }

}

export const getGoogleGroupContacts = async (userLoginEmail: string, resourceName: string) : Promise<Result<Contact[]>> => 
{
    // (*) TODO: Abort request needed (10/16/2022 - pel)
    const emailAddress = encodeURIComponent(userLoginEmail);
    const requestInit = getBearerHeader();
    requestInit.body = '[]';
    requestInit.method = 'POST';
        
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': (requestInit?.headers as any)['Authorization']
    }
    
    requestInit.headers = headers;
    const uri = `/api/contact/GoogleGroupContactsGet/${emailAddress}?googleResourceName=${encodeURIComponent(resourceName)}`;
    
    try
    {
    // const response = await fetch(`${baseUri2}api/googlerezora/googlegroups/${emailAddress}`, requestInit);

    const response = await fetch(uri, requestInit);
    if (!response.ok) {
        const errorMessage = `Unable to run ${uri}, status code = ${response.statusText}`;
        return Result.Error(errorMessage);
    }

    const data : Contact[] = await response.json();
    const newData = data.map((contact : any) =>  {
            return {...contact, id: contact.resourceName }
        }) ?? [];

    return Result.Success(newData);
    } 
    catch(exp : any) {
        console.log('exception', exp);
        const errorMessage = `Unable to run ${uri}, error = ${exp.message}`;
        return Result.Error(errorMessage);
    }

}