import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap"
import { Variant } from "react-bootstrap/esm/types";

import { ValueDropdown } from "@plazarre/phoenix-ux-order/lib/esm/ux/atoms/value-dropdown";

import { Contact } from "../../core/postcard/domain/contact";
import { IDropdown } from "../../core/idropdown";

interface IAddressCardProps {
    key: string,
    contact: Contact,
    tags?: IAddressCardTag[],
    setContactAddressIndex: (contactId: string, index: number) => void;
    onClickDelete: (key: string) => void
};

export interface IAddressCardTag {
    name: string,
    type: Variant
}

const sortAddressTags = (a : IAddressCardTag, b : IAddressCardTag) => {
    if (a.type === 'danger' && b.type !== 'danger') {
        return -1;
    }

    if (a.type === b.type) {
        return 0;
    }

    return 1;
}


export const AddressCard: React.FC<IAddressCardProps> = (props) => {
    const { contact, setContactAddressIndex, onClickDelete } = props;

    let tags = props.tags ? props.tags.sort(sortAddressTags) : []; 
    const tagsJsx = tags.map((tag) => {
        const { name, type } = tag;

        const tagKey = `${contact.id}/${type}/${name}`;
        return (
            <span key={tagKey}>
                <Badge bg={type}>
                    {name}
                </Badge>
                &nbsp;
            </span>
        );
    })

    //setMappedField(value.fieldName, e) //mappedFields[index]
    const addressTypes = contact.addresses.map((address, index) => {
        const dropdownValue : IDropdown = { name: address.type, index };
        return dropdownValue;
    })

    const selectedAddressIndex = contact.selectedAddressIndex ?? 0;
    const { streetAddress, city, state, postalCode, country } = contact.addresses[selectedAddressIndex];
    let onlyAddressType = contact.addresses[0].type ?? '';
    if (onlyAddressType) {
        onlyAddressType = `(${onlyAddressType})`;
    }

    const addressTypeJsx = contact.addresses.length > 1 ? (
        <ValueDropdown 
                                    variant='secondary' 
                                    dropdownValues={addressTypes} 
                                    setValue={(e: any) => { setContactAddressIndex(contact.id, e) }}
                                    value={selectedAddressIndex} 
                                    />
        ) : (<span>{onlyAddressType}</span>)

    return (
        <Card key={contact.id} className="rounded" style={{ padding: 0, marginLeft: '0.5rem', marginBottom: '0.5rem', minWidth: '20rem', maxWidth: '20rem' }}>
            <Card.Header style={{ paddingLeft: 0, paddingRight: 0, minWidth: '20rem' }}>
                <Container fluid>
                    <Row className="align-items-center">
                        <Col xxl={6}>
                            <b>
                                {contact.name}
                            </b>
                        </Col>
                        <Col xxl={4}>
                            {addressTypeJsx}
                        </Col>
                        <Col xxl={2}>
                            <Button 
                                variant="outline-dark" 
                                style={{ paddingLeft: 4, paddingRight: 4, paddingTop: 0, paddingBottom: 0 }}
                                onClick={(e) => onClickDelete(contact.id)}
                                >
                                <FontAwesomeIcon color="darkgray" className="pull-right" icon="trash-can" />
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Card.Header>
            <Card.Body>
            <div style={{ paddingLeft: '0rem' }}>
                {streetAddress}
                <br />
                {city}, {state}  {postalCode}  {country}
            </div>
            </Card.Body>
            <Card.Footer>
                {tagsJsx}
            </Card.Footer>
        </Card>
    );
}