import { CSSProperties, PropsWithChildren } from "react";
import { Link } from "./link";

interface WaveBillEditProps {
    /** A unique identifier to keep opening the same tab window */
    tabId?: string,

    /** The Wave Bill Number */
    billId: string,

    /** The internal Wave bill id */
    internalBillId: string,

    /** The style to use */
    style?: CSSProperties
}

export const WaveBillEditLink : React.FC<PropsWithChildren<WaveBillEditProps>> = (props) => {
    const {billId, internalBillId, tabId, style} = props;

    /** Gets the SalesForce url to display an given id */
    const getUrl = (id: string) : URL => {
        const url = `https://accounting.waveapps.com/bills/10655288/edit/${internalBillId}/`;
        return new URL(url);
    }

    return (
        <Link url={getUrl(billId)} tabId={tabId} style={style}>
            {billId}
        </Link>
    );
}