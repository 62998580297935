import { Col, Row } from "react-bootstrap";

interface IMailingListImportSampleRecordsProps {
    fileRecords: string[]
    maxRecords?: number
}

export const MailingListImportSampleRecords : React.FC<IMailingListImportSampleRecordsProps> = (props) => {
    const {fileRecords, maxRecords} = props;
    const sampleRowsJsx : JSX.Element[] = [];

    const headers = fileRecords.length > 0 ? fileRecords[0].split(',').map(e => e.trim()) : [];
    let fileRecordsFiltered = fileRecords;
    if (maxRecords) {
        fileRecordsFiltered = fileRecords.filter((record, index) => index < maxRecords + 1);
    }

    fileRecordsFiltered.forEach((record, index) => {
        // Skip the header row
        if (index === 0) {
            return;
        }
   
        const fields = record.split(',').map(e => e.trim());
        const key = `record-${index}`;
        sampleRowsJsx.push(<Row key={key}><Col><b>Record #{index}</b></Col></Row>);


        fields.forEach((column, index) => {
            const backgroundColor = (index % 2) === 0 ? 'lightgray' : 'azure';
   
            const columnKey = `${key}-${headers[index]}`
            sampleRowsJsx.push(
                <Row key={columnKey} style={{backgroundColor, width: '80%', marginLeft: '0.1rem'}}>
                    <Col xxl={3} >
                        {headers[index]}:
                    </Col>
                    <Col xxl={5}>
                        {column}
                    </Col>
                </Row>
            );
        });

        sampleRowsJsx.push(<Row key={key+'-space'} style={{marginBottom: '1rem'}}><Col></Col></Row>)
    });

    return <>{sampleRowsJsx}</>;
}

 