import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Col, Form } from 'react-bootstrap';

import { OrderStepStateEnum } from '@plazarre/phoenix-ux-order/lib/esm/core/data-types/order-step-state-enum';
import { OrderButtons } from '@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-buttons';
import { applyStepStateAdjustment, OrderForm } from '@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form';
import { OrderFormRow } from '@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form-row';

import { IPostCardCustomOrderProps } from '../pages/order-postcard';
import { PostcardCustomOrder } from '../../core/postcard/domain/postcard-custom-order';

export const PostcardOrderCopy: React.FC<IPostCardCustomOrderProps> = (props: IPostCardCustomOrderProps) => {
    const { order, setOrder, updateSlug, id } = props;

    const [headline, setHeadline] = useState(order.headline);
    const [copy, setCopy] = useState(order.copy);
    const setOrderFields = (stepAdjustment: number, state: OrderStepStateEnum) => {
        const newOrder = JSON.parse(JSON.stringify(order)) as unknown as PostcardCustomOrder;
        newOrder.stepStates[newOrder.step] = state;

        newOrder.step += applyStepStateAdjustment(newOrder.step, stepAdjustment, newOrder.stepStates);
        //newOrder.orderEV.requestShopTV = requestShopTV;
        //newOrder.orderEV.headline = requestShopTV ? headline : '';
        //const json = JSON.stringify(newOrder);
        //localStorage.setItem('evc-order', json);
        newOrder.headline = headline;
        newOrder.copy = copy;
        setOrder(newOrder);
        updateSlug(id, newOrder);
    };

    const isValidated = headline.length > 0;
    const buttonsJsx = (
        <OrderButtons
            submitLabel="Next"
            submitDisabled={!isValidated}
            skipLabel=""
            backLabel="Back"
            SetOrderFields={setOrderFields}
        />
    );

    const colLg = 4;
    const examplePostcardJsx = <></>;
    const headlineRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (headlineRef && headlineRef.current) {
            headlineRef.current.focus();
        }
    }, []);

    return (
        <OrderForm id="postcard" name="postcard" buttonsJsx={buttonsJsx} exampleJsx={examplePostcardJsx} SetOrderFields={setOrderFields}>
            <OrderFormRow header="Headline" value="" colLg={colLg - 1} outerColStyle={{ display: 'flex' }} >
                <Form.Group as={Col} xxl="8" controlId="validationCustom04">
                    <Form.Control
                        name="postcard_headline"
                        ref={headlineRef}
                        autoComplete="nope"
                        value={headline}
                        maxLength={40}
                        onChange={(e: any) => { e.preventDefault(); setHeadline(e.target.value); }}
                        style={{ width: '45em' }}
                    />
                    <Form.Control.Feedback type="invalid" style={{ display: headline.length > 0 ? 'none' : 'inline' }}>
                        Please provide a headline.
                    </Form.Control.Feedback>
                </Form.Group>
            </OrderFormRow>

            <OrderFormRow header="Copy" value="" colLg={colLg - 1} outerColStyle={{ display: 'flex' }} rowStyle={{ paddingTop: '1.2em' }}>
                <Form.Control
                    as="textarea"
                    rows={8}
                    name="postcard_copy"
                    autoComplete="nope"
                    value={copy}
                    maxLength={2000}
                    onChange={(e: any) => { e.preventDefault(); setCopy(e.target.value); }}
                    style={{ width: '45em', height: '10em' }}
                />
            </OrderFormRow>
        </OrderForm>
    );
};
