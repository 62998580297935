import React, { CSSProperties } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";

import { isMobileOnly } from 'react-device-detect';
import { ButtonSpinner } from "@plazarre/phoenix-ux-order/lib/esm/ux/atoms/button-spinner";
import { useInvoicePage } from "../../hooks/use-invoice-page";
import { ApprovalActionStatusType, WaveInvoice } from "@plazarre/phoenix.javascript.approval";

export interface InvoiceApprovalPageProps {
}

// To test pass in MTA0OTc0NDAwNDk0MTg4MjQ0ODgyfGExZTRVMDAwMDBFNktoOFFBRnwyOTk5fEFwcHJvdmU=

export const InvoiceRejectPage : React.FC<InvoiceApprovalPageProps> = (props) => {
    const [ comment, setComment ] = useState('')
    const [ isSaveEnabled, setIsSaveEnabled ] = useState(false);
    const { fetchProperty, formOnSubmit, processUpdateResponse } = useInvoicePage();
   
    if (fetchProperty.isLoading) {
        return(
            <>Please wait loading...</>
        );
    }

    const commentsOnChange= (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value);
        if (e.target.value.length > 5) {
            setIsSaveEnabled(true);
        }
    }

    if (fetchProperty.isError) {
        const errorDescription = fetchProperty.errorMessage ?? 'There was an error loading the invoice information. Please try again.';

        return(
            <>{errorDescription}</>
        );
    }

    // The error for this is handled in the useInvoicePage hook with a toast
    if (processUpdateResponse.hasData) {
        return(
            <>The invoice has been rejected.</>
        );
    }

    const property = fetchProperty.data?.propertyDisplayResult;
    if (!property) {
        return(
            <>There was an error loading the invoice information. Please try again.</>
        );
    }

    const status = fetchProperty.data?.approvalUser?.status;
    const isAlreadyRejected = status === ApprovalActionStatusType.Rejected ?? false;
    const isAlreadyAccepted = status === ApprovalActionStatusType.Complete ?? false;

    const style : CSSProperties = isMobileOnly ? { minWidth: '99%' } : { width: '40rem' };
    
    const mapInvoiceToJsx = (invoice: WaveInvoice, index: number) => {
        return (
            <>
            Invoice #: 
            {invoice.invoiceNumber}
            &nbsp;
            [<a href={invoice.pdfUrl} target="_blank" rel="noreferrer">View</a>]
            </>
        )
    }

    return (
        <div>
            {property.fullStreetAddress}
            <br/>
            {property.invoices.map(mapInvoiceToJsx)}
            <br/>
            <br/>
            {isAlreadyRejected && <span style={{ color: 'red' }}>You have already rejected this invoice. You may enter additional comments if you wish.<br/><br/></span>}
            {isAlreadyAccepted && <span style={{ color: 'red' }}>You have already accepted this invoice. Proceeding will change its status to 'Reject'.<br/><br/></span>}
            Please enter comments to reject this invoice:
            <br/>
            <Form onSubmit={(e) => formOnSubmit({ type: ApprovalActionStatusType.Rejected, comment }, e )}>
                <Form.Text as="textarea" id="comments"
                    rows={4} style={ style } 
                    value={comment} onChange={commentsOnChange} 
                    autoFocus={true} 
                    />
                    
                <br/><br/>
                <ButtonSpinner type="submit" variant="danger" waitingIcon={processUpdateResponse.isLoading} disabled={!isSaveEnabled}>Reject</ButtonSpinner>
            </Form>
        </div>
        
    );
}

