import { useContext } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";

import { AppContext } from "@plazarre/phoenix-ux-order/lib/esm/hook/use-application-context";
import { UserSignIn } from '@plazarre/phoenix-ux-order/lib/esm/ux/atoms/sign-in';

import evlogo from '../../../images/ev-logo.png';
import { useLocation } from "react-router";

export const LoginPage : React.FC<{}> = (props) => {
    const appContext = useContext(AppContext);
    const location = useLocation();
    
    const { isUserLoggedIn } = appContext;
    const from = location?.state?.from?.pathname || "/";
    console.log('from', from);

    const height = window.innerHeight;
    console.log('height=', height);
    console.log('available=', window.screen.availHeight);
    console.log('available=', window.innerHeight);
    console.log('isUserLoggedIn', isUserLoggedIn);
    
    return (
        <Container fluid style={{ overflow: 'hidden' }}>
            <Row xs="12" className="justify-content-center">
                <Col xs="12" lg="8" className="text-center align-items-center d-flex" style={{ minHeight: height }}>
                    <div style={{ width: '100%' }}>
                        <img src={evlogo} style={{ width: '50%' }} alt="E&V" />
                        <br />
                        <br />
                        <div>Please sign in.</div>
                        <br />
                        <div className="d-flex justify-content-center">
                            <UserSignIn redirectUri={from} autoSelect={true} />
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </Col>
            </Row>
        </Container>

    );
}

