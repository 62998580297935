import { PropertyDisplayResult, WaveInvoiceStatus } from "@plazarre/phoenix.javascript.approval";
import { ButtonListingKeyClick, PropertyButton, PropertyButtonBase, PropertyButtonBaseParams } from "./property-button-base";


/** Updates an invoice from the marketing order */
export class UpdateInvoiceButton extends PropertyButtonBase implements PropertyButton {
    isMatch = (property: PropertyDisplayResult, isApprovalCompleted: boolean) => {
        return property.listings.length > 0 
            && property.invoices.length > 0 
            && property.orders.length === property.invoices.length 
            && !isApprovalCompleted;
    }

    constructor(onClick: ButtonListingKeyClick, buttonParams: PropertyButtonBaseParams) {
        super('Update Invoice', onClick, {...buttonParams, isModal: true}, 'secondary');
    }
}

/** Creates an invoice from the marketing order */
export class CreateInvoiceButton extends PropertyButtonBase implements PropertyButton {
    isMatch = (property: PropertyDisplayResult, isApprovalCompleted: boolean) => {
        console.log(property.fullStreetAddress, isApprovalCompleted, property);
        return property.listings.length > 0 
            && property.orders.length > property.invoices.length 
            && !isApprovalCompleted;
    }

    constructor(onClick: ButtonListingKeyClick, buttonParams: PropertyButtonBaseParams) {
        super('Create Invoice', onClick, buttonParams, 'danger');
    }
}

/** Send an invoice for approval */
export class SendApprovalInvoiceButton extends PropertyButtonBase implements PropertyButton {
    isMatch = (property: PropertyDisplayResult, isApprovalCompleted: boolean) => { 
        
        const orderInvoiceNumbers = property.orders.flatMap((e) => e.invoiceNumbers);
        return property.listings.length > 0 
            && property.invoices.length > 0 
            && orderInvoiceNumbers.length === property.invoices.length 
            && !isApprovalCompleted;
    }

    constructor(onClick: ButtonListingKeyClick, buttonParams: PropertyButtonBaseParams) {
        super('Send For Approval', onClick, buttonParams, 'danger');
    }
}

/** Send an invoice that is completed to an advisor */
export class SendInvoiceToAdvisorButton extends PropertyButtonBase implements PropertyButton {
    isMatch = (property: PropertyDisplayResult, isApprovalCompleted: boolean) => { 
        return isApprovalCompleted && (property.invoices.filter((e) => (new WaveInvoiceStatus(e.status).isUnsent)).length > 0);
    }

    constructor(onClick: ButtonListingKeyClick, buttonParams: PropertyButtonBaseParams) {
        super('Send to Advisor', onClick, buttonParams, 'danger');
    }
}

/** Without updating (rebuilding) the Wave Invoice from marketing order, uses Wave Invoice to update approval structure */
export class ApprovalInvoiceUpdateButton extends PropertyButtonBase implements PropertyButton {
    isMatch = (property: PropertyDisplayResult, isApprovalCompleted: boolean) => {
        
        const serviceCostsOutstanding = property.orders?.reduce((previousValue, currentValue) => {
            return previousValue + (currentValue.serviceCostsOutstanding ?? 0) - (currentValue?.invoiceOverageCost ?? 0);
        }, 0);
        
        const areOutstandingInvoiceOverages = serviceCostsOutstanding > 0;
        const updatedWaveInvoices = property.invoices.filter((e) => {
            return e.lastSentAt != null && new Date(e.modifiedAt) > new Date(e.lastSentAt);  
            });
        return property.listings.length > 0 
            && property.invoices.length > 0 
            && property.orders.length === property.invoices.length 
            && (!isApprovalCompleted || areOutstandingInvoiceOverages || updatedWaveInvoices.length > 0);
    }

    constructor(onClick: ButtonListingKeyClick, buttonParams: PropertyButtonBaseParams) {
        super('Approval Invoice Update', onClick, {...buttonParams, isModal: true}, 'secondary');
    }
}

/** Create SalesForce listing from MLS Data */
export class CreateSalesForceListingFromMLSButton extends PropertyButtonBase implements PropertyButton {
    isMatch = (property: PropertyDisplayResult, isApprovalCompleted: boolean) => {
        return property.listings.length === 0;
    }

    constructor(onClick: ButtonListingKeyClick, buttonParams: PropertyButtonBaseParams) {
        super('Create SalesForce Listing', onClick, buttonParams, 'secondary');
    }
}


/** Create SalesForce listing from MLS Data */
export class CreateListingMarketingOrderFromListing extends PropertyButtonBase implements PropertyButton {
    isMatch = (property: PropertyDisplayResult, isApprovalCompleted: boolean) => {
        return property.listings.length > 0 && property.orders.length === 0;
    }

    constructor(onClick: ButtonListingKeyClick, buttonParams: PropertyButtonBaseParams) {
        super('Create Marketing Order', onClick, buttonParams, 'secondary');
    }
}