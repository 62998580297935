import { IAddress } from "./iaddress";

export class Contact {
    name: string = '';
    id: string = '';  // resourceName
    addresses : IAddress[] = [];
    selectedAddressIndex: number = 0;

    static uniqueValues = (value: Contact, index: number, array: Contact[]) : boolean => {
        const contactIndex = array.findIndex(e => e.id === value.id) ?? -1;
        return contactIndex === index;
    }

    static sortContactByNameAsc = (a : Contact, b: Contact) => {
        if (a.name < b.name)
           return -1;
        else if (a.name === b.name)
           return 0;
        else
           return 1;
    };

}
