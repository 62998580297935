import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { differenceInDays } from 'date-fns';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { EVHeader } from "@plazarre/phoenix-ux-order/lib/esm/ux/organisms/ev-header";

import { getAdvisors } from "../../api/advisors";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const AdvisorsPage = (): JSX.Element => {
    const [members, setMembers] = useState<any[]>([]);
    // getMembersByOffice
    
    const advisorToJsxMap = (value : any, index : number, values : any[]) => {
        console.log(value);
        const advisorType : Array<any> = value.advisorType;
        let advisorColor = advisorType.find(e => e.apiName === 'Leadership') ? 'blue' : 'black';
        if (value.advisorStartDate) {        
            const days = differenceInDays(Date.now(), new Date(value.advisorStartDate));
            if (days < 365) {
                advisorColor = 'orange';
            }
        }


        let splitColor = value.splitScheduleType.apiName.includes('2021') ? 'gold' : 'green';
        if (value.splitScheduleType.apiName === 'Unknown')
            splitColor = 'red';
            
        const splitAvailable = value.hasSplitData ? 'green' : 'red';
        const advisorJsx = 
         ( 
            <Row>
                <Col xs={3}>
                    <FontAwesomeIcon icon="circle" color={advisorColor} />
                    &nbsp;
                    {value.fullName}
                </Col>
                <Col xs={2}>
                    <FontAwesomeIcon icon="circle" color={splitAvailable} />
                </Col>
                <Col xs={2}>
                    <FontAwesomeIcon icon="circle" color={splitColor} />
                    &nbsp;
                    {value.splitScheduleType.apiName}
                </Col>
                <Col xs={1} style={{ textAlign: 'right' }}>
                    {value.companyDollar.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                </Col>
            </Row>
        );

        return advisorJsx;
    }

    useEffect(() => {
        const getMembersByOfficeCallback = async (officeKey: string) => {
            const result = await getAdvisors();
            if (result.isSuccess) {
                setMembers(result.value as any[]);
                toast.success('Loaded!');
            }
            else {
                toast.error(`Error loading advisors: ${result.errorMessage}.`);
            }
        };

        getMembersByOfficeCallback('MRD6538');
    }, []);

    let advisorsJsx : JSX.Element[] = [];
    if (members.length > 0) {
        advisorsJsx = members.map(advisorToJsxMap);
    }

    const total = members.length > 0 ? members.reduce((a, b) => { return b.companyDollar + (isNaN(a) ? 0 : a) }) : 0;
    return (
        <>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false}
            closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        
        <EVHeader />
        <Container>
            <Row>
                <Col xs={3}>
                    <b>Advisor</b>
                </Col>
                <Col xs={2}>
                    <b>Split Available</b>
                </Col>
                <Col xs={2}>
                    <b>Split Year</b>
                </Col>
                <Col xs={1} style={{ textAlign: 'right' }}>
                    <b>Company Dollars</b>
                </Col>
            </Row>
                {advisorsJsx}
            <Row>
                <Col xs={3}/>
                <Col xs={2}/>
                <Col xs={2}/>
                <Col xs={1} style={{ textAlign: 'right' }}>
                    {total.toLocaleString('en-US', {maximumFractionDigits:2})}
                </Col>
            </Row>
        </Container>
        </>
    );
}
