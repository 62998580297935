// import { AppContext } from "@plazarre/phoenix-ux-order/lib/esm/hook/use-application-context";
// import { useContext } from "react";

interface CommissionsPageProps {
}

export const CommissionsPage : React.FC<CommissionsPageProps> = (props) => {
    //const appContext = useContext(AppContext);

    return (
        <>
            <h1>Commissions</h1>
            <h2>Coming soon!</h2>
        </>
    );

}
