import { UploadFile } from "@plazarre/phoenix.ux.file-upload/lib/esm/ux/molecules/upload-file";
import { CSSProperties } from "react"
import { Col, ProgressBar } from "react-bootstrap";
import { useFileTransfer } from "../../hooks/use-file-transfer";

//import { useScript } from '@plazarre/phoenix-ux-order/lib/esm/hook/use-script';

// Loaded via <script> tag, create shortcut to access PDF.js exports.
// var pdfjsLib : any = window['pdfjs-dist/build/pdf' as any];

export const UploadFloorplan : React.FC = (props) => {
    //useScript('//mozilla.github.io/pdf.js/build/pdf.js');
    const [fileTransfers, handleChange] = useFileTransfer([]);

    const imageStyles : CSSProperties = {maxWidth: '100%', maxHeight: '11rem', minHeight: '11rem', minWidth: 'auto', margin: '0 auto' };
    const imageHolderStyles : CSSProperties = { border: '1px solid black', maxHeight: '11rem', maxWidth: '20rem', 
                                                display: 'none', background: 'lightgray'};

    const imageHolders : JSX.Element[] = fileTransfers.map((fileTransfer, index) => {
            const imageId = `image${index}`;
            const imageHolderId = `imageholder${index}`;
            
            // Be sure startOffset isn't past the length. If so correct.
            const startOffset = fileTransfer.startOffset > fileTransfer.length ? fileTransfer.length : fileTransfer.startOffset;
            const now = Math.round((startOffset / fileTransfer.length)*100);

            const errorJsx = fileTransfer.isError ? <span style={{color:'red'}}>{fileTransfer.errorDescription}</span> : <></>;

            return (
                <Col xxl={3} id={imageHolderId} className='text-center align-items-end' style={imageHolderStyles}>
                    <img id={imageId} alt={fileTransfer.file.name} style={imageStyles} />
                    <ProgressBar now={now} label={`${now}%`} style={{marginTop: '-1rem'}}/>
                    {errorJsx}
                </Col>
            );
        }) ?? [];
        
        
    return (
        <>
        <UploadFile 
            name="form" 
            multiple={false} 
            fileTypes={['pdf', 'png', 'jpg', 'jpeg']} 
            handleChange={handleChange} 
            style={{width: '18.5rem', marginLeft: '-12px', marginRight: '-12px'}}
            maxStringLength={30}
            />
        <br/>
        {imageHolders}
        <canvas id="pdf" style={{display: 'none'}}></canvas>
        </>
        
    )
}