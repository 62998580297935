import { ApprovalProcessInformation, PropertyDisplayResult } from "@plazarre/phoenix.javascript.approval";


export interface IPropertyInvoiceDetailProps {
    selectedProperty: PropertyDisplayResult
}

export const PropertyInvoiceDetail: React.FC<IPropertyInvoiceDetailProps> = (props) => {
    const { selectedProperty } = props;
    
    const historyJsx : JSX.Element[] = [];

    // Build array --- one for each marketing order
    const approvalJsonList = selectedProperty?.approvalProcessInformationJsonList;
    const approvalProcessInformationList = approvalJsonList.map(e => ApprovalProcessInformation.fromJSON(e))
    const approvalDetails = approvalProcessInformationList.map(e => e.getApprovalDetail());
    approvalDetails.forEach((approvalDetail, index) => {
        console.log('selectedApprovalStepActions', approvalDetail.approvalProcessInformation, approvalDetail.approvalStepAction);

        approvalDetail.approvalStepAction?.forEach((approvalStepAction, index) => {
            historyJsx.push(<><b>{approvalStepAction?.person?.name}:</b><br/></>);
            Object.entries(approvalStepAction?.statusHistory)?.forEach((statusHistory, index) => {
                const statusDateTime = statusHistory?.[0] ? new Date(Date.parse(statusHistory?.[0])) : null;
                const dateTimeString = `${statusDateTime?.toLocaleDateString()} ${statusDateTime?.toLocaleTimeString()}`;
                console.log('statusHistory', statusHistory);
                historyJsx.push(<>&nbsp;&nbsp;&nbsp;&nbsp;{dateTimeString}: {statusHistory?.[1]?.status}<br/></>);
            });

            Object.entries(approvalStepAction.comments)?.forEach((comment, index) => {
                const commentDateTime = comment?.[0] ? new Date(Date.parse(comment?.[0])) : null;
                const dateTimeString = `${commentDateTime?.toLocaleDateString()} ${commentDateTime?.toLocaleTimeString()}`;
                historyJsx.push(<>&nbsp;&nbsp;&nbsp;&nbsp;{dateTimeString}: {comment?.[1]}<br/></>);
            });
        });

        console.log('approvalProcessInformation', approvalDetail.approvalProcessInformation);
        approvalDetail.approvalProcessInformation?.[0].approvalDocuments.forEach((document: any, index) => {
            historyJsx.push(<><br/><b>Current Document(s):</b><br/></>);
            const md5String = document.mD5 ? `/${document.mD5.slice(-4)}` : '';
            const documentString = `${document.name} (version ${document?.version}${(md5String)})`;
            historyJsx.push(<>{documentString}<br/></>);
        });

        if ((approvalDetail.approvalProcessInformation?.[0]?.priorDocuments?.length ?? 0) > 0) {
            historyJsx.push(<><br/><b>Prior Document(s):</b><br/></>);
        }

        approvalDetail.approvalProcessInformation?.[0].priorDocuments.forEach((document: any, index) => {
            const md5String = document.mD5 ? `/${document.mD5.slice(-4)}` : '';
            const documentString = `${document.name} (version ${document?.version}${(md5String)})`;
            historyJsx.push(<>{documentString}<br/></>);
        });
    });    

    return (
        <>
            Listing Id: {selectedProperty.listings?.[0]?.id}<br/>
            Document Owner: {approvalDetails[0]?.approvalPerson?.name}<br/>
            <b>Approval History:</b><br/>
            {historyJsx}
        </>
    );
}