import { OrderButtons } from "@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-buttons";
import { OrderForm } from "@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form";
import { OrderFormRow } from "@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form-row";
import { useRef, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/esm/Form";
import { Point } from "../../../core/postcard/domain/point";
import { Media } from "../../atoms/media";
import { FormControlMoney } from "../../molecules/form-control-money";

export const PaperEditTemplate : React.FC = (props) => {
    const [name, setName] = useState('');
    const [mediaSizeX, setMediaSizeX] = useState<string>('');
    const [mediaSizeY, setMediaSizeY] = useState<string>('');
    const [paperCostPer, setPaperCostPer] = useState<string>('');
    const [costPerSheets, setCostPerSheets] = useState<string>('');
    const [taxCostPer, setTaxCostPer] = useState<string>('');
    const [paperCostShipping, setPaperCostShipping] = useState('');
    const [paperProvider, setPaperProvider] = useState('');
    const nameRef = useRef<HTMLInputElement>(null);

    const buttonsJsx = (
        <OrderButtons
            submitLabel="Save"
            submitDisabled={false}
            submitWaitingIcon={false}
            skipLabel=""
            backLabel="Cancel"
            SetOrderFields={() => {}}
        />
    );

    const colLg = 3;

    /*
    export enum PaperFieldsType { tonerCost = "toner_cost", printingLaborCost = 'printing_label_cost', postProductionLaborCost = 'post_production_labor_cost' };

    const fields : IFormControlState<any>[] = [
        { name: PostcardCostTypeFields.tonerCost, value: tonerCost, setValue: setTonerCost},
        { name: PostcardCostTypeFields.printingLaborCost, value: printingLaborCost, setValue: setPrintingLaborCost},
        { name: PostcardCostTypeFields.postProductionLaborCost, value: postProductionLaborCost, setValue: setPostProductionLaborCost},
    ];
    */
    
    const mediaSizeXFloat = parseFloat(mediaSizeX);
    const mediaSizeYFloat = parseFloat(mediaSizeY);
    const mediaSizePoint : Point | undefined = mediaSizeX && mediaSizeY ? { x: mediaSizeXFloat, y: mediaSizeYFloat } : undefined;
    const mediaJsx = <><Media mediaSizeInches={mediaSizePoint} mediaTitle="Paper" /></>;

    return (
        <OrderForm id="paper-edit" name="paper-edit" buttonsJsx={buttonsJsx} SetOrderFields={() => {}} exampleJsx={<>{mediaJsx}</>}>
            <OrderFormRow colLg={colLg} header="Name" value="" >
                <Form.Group as={Col} xxl="8" controlId="validationCustom01">
                    <Form.Control
                        ref={nameRef}
                        name="name"
                        autoComplete="nope"
                        value={name}
                        maxLength={40}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { e.preventDefault(); setName(e.currentTarget.value); }}
                        style={{ width: '80%' }}
                    />
                    <Form.Control.Feedback type="invalid" style={{ display: name.length > 0 ? 'none' : 'inline' }}>
                        Please provide a unique name for the post card.
                    </Form.Control.Feedback>
                </Form.Group>
            </OrderFormRow>

            <OrderFormRow header="Paper Size in Inches (width / height)" value="" colLg={colLg} >
                <Form.Group  as={Col} xxl="8" controlId="validationCustom02">
                    <div className="d-flex">
                    <Form.Control
                        name="width"
                        autoComplete="nope"
                        value={mediaSizeX}
                        maxLength={10}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { e.preventDefault(); setMediaSizeX(e.currentTarget.value); }}
                        style={{ width: '15%' }}
                    />
                    &quot;
                    &nbsp;
                    &nbsp;
                    <Form.Control
                        name="width"
                        autoComplete="nope"
                        value={mediaSizeY}
                        maxLength={10}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { e.preventDefault(); setMediaSizeY(e.currentTarget.value); }}
                        style={{ width: '15%' }}
                    />
                    &quot;
                    </div>
                    <Form.Control.Feedback type="invalid" style={{ display: name.length > 0 ? 'none' : 'inline' }}>
                        Please provide width and height in inches for the paper.
                    </Form.Control.Feedback>
                </Form.Group>
            </OrderFormRow>

            <OrderFormRow colLg={colLg} header="Paper cost (per x sheets)" value="$">
                <FormControlMoney
                    name="paper_cost_per"
                    value={paperCostPer}
                    setValue={setPaperCostPer}
                    validationMessage="Please provide paper costs per x sheets."
                    width="5rem"
                />
                
            </OrderFormRow>

            <OrderFormRow colLg={colLg} header="Paper costs per sheets" value="">
                <FormControlMoney
                    name="paper_cost_per"
                    value={costPerSheets}
                    setValue={setCostPerSheets}
                    validationMessage="Please provide the number of sheets costs are calculated above."
                    width="5rem"
                />
            </OrderFormRow>

            <OrderFormRow colLg={colLg} header="Tax on paper (per x sheets)" value="$">
                <FormControlMoney
                    name="tax_cost_per"
                    value={taxCostPer}
                    setValue={setTaxCostPer}
                    validationMessage="Please provide the tax per x sheets."
                    width="5rem"
                />
            </OrderFormRow>

            <OrderFormRow colLg={colLg} header="Shipping for paper (per x sheets)" value="$">
                <FormControlMoney
                    name="shipping"
                    value={paperCostShipping}
                    setValue={setPaperCostShipping}
                    validationMessage="Please provide the shipping per x sheets."
                    width="5rem"
                />
            </OrderFormRow>

            <OrderFormRow colLg={colLg} header="Paper Provider" value="" >
                <Form.Group as={Col} xxl="8" controlId="validationCustom04">
                    <Form.Control
                        name="paper_provider"
                        autoComplete="nope"
                        value={paperProvider}
                        maxLength={40}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { e.preventDefault(); setPaperProvider(e.currentTarget.value); }}
                        style={{ width: '80%' }}
                    />
                    <Form.Control.Feedback type="invalid" style={{ display: name.length > 0 ? 'none' : 'inline' }}>
                        Please provide the provider name of the paper.
                    </Form.Control.Feedback>
                </Form.Group>
            </OrderFormRow>

       </OrderForm>
    );
}