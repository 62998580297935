import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ReactState } from "../../../hooks/use-invoice-page";
import { PropertyDisplayResult } from "@plazarre/phoenix.javascript.approval";
import { PropertyButtonBase } from "../../atoms/invoices/property-button-base";

export interface IModalDataInvoiceComment {
    property: PropertyDisplayResult,
    comment: string,
}

export interface IModalData<T> {
    shouldShowModal: boolean,
    save: (baseButton: PropertyButtonBase, data: T) => void,
    data: T | null, 

    /** This is used to disable and show is loading cursor. */ 
    // (*) TODO: Ideally, we find a better way to pass along the loading information state to the save function (10/11/2023 - pel)
    baseButton: PropertyButtonBase | null,
}

export interface IModalInvoiceCommentProps {
    modalDataState: [IModalData<IModalDataInvoiceComment>, ReactState<IModalData<IModalDataInvoiceComment>>],
}

export const ModalInvoiceComment: React.FC<IModalInvoiceCommentProps> = (props): JSX.Element | null => {
    const [comment, setComment] = useState('');

    const { modalDataState } = props;
    if (!modalDataState)
        return null;

    const { baseButton, data, shouldShowModal, save } = modalDataState[0];
    
    const handleClose = () => modalDataState[1](prev => ({ ...prev, shouldShowModal: false }));

    const onSaveClick = () => {
        if (data?.property == null)
            throw new Error('Property is null');

        if (baseButton == null)
            throw new Error('Base button is null');

        const outputData: IModalDataInvoiceComment = {
            comment,
            property: data?.property,
        };

        save(baseButton, outputData);
    }

    const onInputRef = (input: HTMLInputElement) => {
        setTimeout(() => {
            input.focus();
        }, 100);
    }

    if (!shouldShowModal)
        return null;

    return (
        <Modal show={true} onHide={handleClose} autoFocus={true}>
            <Modal.Header closeButton>
                <Modal.Title>Update Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Update Reason</Form.Label>
                        <Form.Control
                            ref={onInputRef}
                            type="input"
                            placeholder="Reason for the update"
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={onSaveClick}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};