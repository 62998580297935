

//export const baseUri : string = 'https://amesgroupchicago-phoenixre-core31.azurewebsites.net/';
export const baseUri : string = '/'; //https://localhost:7020/';

/**
 * Returns the bearer header from localstorage
 * @param priorHeader set to a header to build upon it
 * @param contentType the Content-Type - default to application/json
 * @returns a RequestInit
 */
export const getBearerHeader = (priorHeader?: RequestInit, contentType: string = 'application/json') : RequestInit => {
    const idToken =  localStorage.getItem('user_token') ?? '';
    const bearerToken = idToken ? `Bearer ${idToken}` : ''
    const headers = { 'Authorization' : bearerToken, 'Content-Type': contentType };
    return { ...priorHeader, headers };
}