import { baseUri, getBearerHeader } from "./fetch";
import { Result } from "./result";

export const getAdvisors = async () : Promise<Result<Array<any>>> => 
{
    const uri = `${baseUri}api/advisor`;
    const requestInit = getBearerHeader();
    const response = await fetch(uri, requestInit);
    if (!response.ok) {
        const textMessage = await response.text();
        const message = textMessage ?? response.statusText;
        const errorMessageOutput = message ? ` (${message})` : '';
        const errorMessage = `Unable to run ${uri}, status code = ${response.status} ${errorMessageOutput}`;
        return Result.Error(errorMessage);
    }

    const data : Result<Array<any>> = await response.json();
    return Result.Success(data);
}

export const getShops = async () : Promise<Result<Array<any>>> => 
{
    const requestInit = getBearerHeader();
    const uri = `${baseUri}api/advisor/shops`;
    const response = await fetch(uri, requestInit);
    if (!response.ok) {
        const errorMessage = `Unable to run ${uri}, status code = ${response.statusText}`;
        return Result.Error(errorMessage);
    }

    const data : Result<Array<any>> = await response.json();
    return Result.Success(data);
}

export const getSplitSchedules = async () : Promise<Result<Array<any>>> => 
{
    const requestInit = getBearerHeader();
    const uri = `${baseUri}api/advisor/splitschedules`;
    const response = await fetch(uri, requestInit);
    if (!response.ok) {
        const errorMessage = `Unable to run ${uri}, status code = ${response.statusText}`;
        return Result.Error(errorMessage);
    }

    const data : Result<Array<any>> = await response.json();
    return Result.Success(data);
}

export const getSplitMasterSchedule = async () : Promise<Result<Array<any>>> => 
{
    const requestInit = getBearerHeader();
    const uri = `${baseUri}api/advisor/SplitMasterSchedule`;
    const response = await fetch(uri, requestInit);
    if (!response.ok) {
        const errorMessage = `Unable to run ${uri}, status code = ${response.statusText}`;
        return Result.Error(errorMessage);
    }

    const data : Result<Array<any>> = await response.json();
    return Result.Success(data);
}

export const getSplitAnalysis = async () : Promise<Result<Array<any>>> => 
{
    const requestInit = getBearerHeader();
    const uri = `${baseUri}api/advisor/split-analysis`;
    const response = await fetch(uri, requestInit);
    if (!response.ok) {
        const errorMessage = `Unable to run ${uri}, status code = ${response.statusText}`;
        return Result.Error(errorMessage);
    }

    const data : Result<Array<any>> = await response.json();
    return Result.Success(data);
}