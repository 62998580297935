import { IUseFetchOptions, useFetchJSON } from "@plazarre/phoenix.ux.fetch/lib/esm/hook/use-fetch-json";
import { IDropdown } from "../../core/idropdown";

export class CategorizeRequest
{
    salesForceListingId: string;
    
    category: SocialCategory;
    
    files: FileTarget[];
    
    constructor()
    {
        this.salesForceListingId = "";
        this.category = SocialCategory.Unknown;
        this.files = [];
    }
}
//== File: /dtos/webapi.Controllers.SocialCategory.tsx

/** Used to indicate the class of image for social media images */
export class SocialCategory
{
    static Unknown: string = "Unknown";
    static ComingSoon: string = "Coming Soon";
    static NewToMarket: string = "New To Market";
    static PriceImprovement: string = "Price Improvement";
    static OpenHouse: string = "Open House";
    static UnderContract: string = "Under Contract";
    static JustSold: string = "Just Sold";

    static 0: string = SocialCategory.ComingSoon;
    static 1: string = SocialCategory.NewToMarket;
    static 2: string = SocialCategory.PriceImprovement;
    static 3: string = SocialCategory.OpenHouse;
    static 4: string = SocialCategory.UnderContract;
    static 5: string = SocialCategory.JustSold;

    static Dropdowns : IDropdown[] = [
        { index: 0, name: SocialCategory.ComingSoon },
        { index: 1, name: SocialCategory.NewToMarket },
        { index: 2, name: SocialCategory.PriceImprovement },
        { index: 3, name: SocialCategory.OpenHouse },
        { index: 4, name: SocialCategory.UnderContract },
        { index: 5, name: SocialCategory.JustSold }
    ];
}
//== File: /dtos/webapi.Controllers.FileTarget.tsx

export class FileTarget
{
    id?: string;
    
    mimeType?: string;
    
    name: string;
    
    md5Checksum: string;
    
    size?: number | null;
    
    constructor()
    {
        this.id = "";
        this.mimeType = "";
        this.name = "";
        this.md5Checksum = "";
        this.size = null;
    }
}

export interface ListingCategoryCountMap {
    [key: string]: number;
}

export interface GetCountByCategoryResult {
    categoryCounts: ListingCategoryCountMap;
    webViewLink: string;
}

/** Social controller, for managing social media images */
export const useSocial = () => {

    const idToken = localStorage.getItem('user_token') ?? '';
    const optionsPostFetch : IUseFetchOptions = {
        jwtToken: idToken,
        shouldInitializeOnly: true,
        method: 'POST',
        shouldUseCache: false,
        timeoutMilliseconds: 20000 
    };

    const [ categorizeResponse, setCategorizeResponse, doCategorizePost , ,  ] = useFetchJSON<any, CategorizeRequest>("/api/social/categorize", optionsPostFetch);
    const [ categoryCount, setCategoryCount, doCategoryCountPost , ,  ] = useFetchJSON<GetCountByCategoryResult, string>("/api/social/count", optionsPostFetch);

    return { categorizeResponse, setCategorizeResponse, doCategorizePost, categoryCount, setCategoryCount, doCategoryCountPost };
}