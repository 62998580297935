import { PropertyDisplayResult } from "@plazarre/phoenix.javascript.approval";
import { ReactState } from "../../../hooks/use-invoice-page";
import { ButtonSpinner } from "@plazarre/phoenix-ux-order/lib/esm/ux/atoms/button-spinner";
import { ButtonVariant } from "react-bootstrap/esm/types";
import { FetchCall } from "@plazarre/phoenix.ux.fetch/lib/esm/core/domain/fetch-call";

export type ButtonListingKeyClick = (e: any, property: PropertyDisplayResult, buttonBase: PropertyButtonBase) => Promise<void>;
export type DoPropertyLinkFetchRequest = (listingKey: string, shouldForceRefresh: boolean) => Promise<FetchCall<any>>;

/** The property button interface, for use in derived classes */
export interface PropertyButton {
    label: string,
    onClick: ButtonListingKeyClick,
    isMatch: (property: PropertyDisplayResult, isApprovalCompleted: boolean) => boolean,
    jsx: (property: PropertyDisplayResult) => JSX.Element
}

export interface PropertyButtonBaseParams {
    isLoadingState: [boolean, ReactState<boolean>];
    isDisabledState: [boolean, ReactState<boolean>];

    /* Used in listing.tsx to update the property display result and load in new data from the call */
    updateProperty: (property: PropertyDisplayResult) => Promise<void>;

    /** Is the dialog box modal. Used to determine if should run the updateProperty after initial run */
    isModal?: boolean;
}

/** The property button base that set the is loading flag and runs the assigned onClickRun */
export class PropertyButtonBase {
    label: string = '';
    variant?: ButtonVariant;
    onClickRun: ButtonListingKeyClick;
    isLoading: boolean;
    setIsLoading: ReactState<boolean>;
    isDisabled: boolean;
    setIsDisabled: ReactState<boolean>;

    /** Is the dialog box modal. Used to determine if should run the updateProperty after initial run */
    isModal?: boolean;

    /* Used in listing.tsx to update the property display result and load in new data from the call */
    updateProperty: (property: PropertyDisplayResult) => Promise<void>;

    onClick = async (e: any, property: PropertyDisplayResult) => {
        console.log(`running ${this.label} onClick`);
        this.setIsLoading(true);
        await this.onClickRun(e, property, this);

        // Only update here if not modal, otherwise, should wait for modal to update
        if (!this.isModal) {
            await this.updateProperty(property);
        }
        
        this.setIsLoading(false);
    }

    jsx = (property: PropertyDisplayResult) => (
        <>
            <ButtonSpinner 
                disabled={this.isDisabled}
                waitingIcon={this.isLoading} 
                variant={this.variant}
                onClick={(e) => this.onClick(e, property)}
                style={{ marginBottom: '0.25rem', marginTop: '0.25rem'}}
                >
                {this.label}
            </ButtonSpinner>
            &nbsp;
        </>
    );

    constructor(label: string, onClickRun: ButtonListingKeyClick, 
                buttonParams: PropertyButtonBaseParams, variant?: ButtonVariant) {
        const { isLoadingState, isDisabledState, updateProperty } = buttonParams;

        this.label = label;
        this.onClickRun = onClickRun;
        this.isLoading = isLoadingState[0];
        this.setIsLoading = isLoadingState[1];
        this.isDisabled = isDisabledState[0];
        this.setIsDisabled = isDisabledState[1];
        this.variant = variant;
        this.updateProperty = updateProperty;
        if (buttonParams.isModal)
            this.isModal = buttonParams.isModal;
    }
}

