import { Button, Col, Row, Spinner } from "react-bootstrap";
import React, { CSSProperties } from "react";
import { ListingCategoryCountMap } from "../../api/domain/social";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listing } from "@plazarre/phoenix.javascript.approval";

export class InputFileData {
    name: string = '';

    description: string = '';

    isSelected: boolean = false;

    file?: File;

    isUploading: boolean = false;
}

export class InputFileDataListingUpload extends InputFileData {
}

interface ListingUploadSocialItemProps {
    listing: Readonly<Listing>,
    isSelected: boolean,
    isUploading: boolean,
    
    /** Set to true if we are counting the types of social media files */
    isCounting?: boolean,
    selectListing: (id: string) => void,
    socialMap?: ListingCategoryCountMap,
    webViewLinkUrl?: string,
}


export const ListingUploadSocialItem: React.FC<ListingUploadSocialItemProps> = (props) : JSX.Element | null => {
    const { isSelected, isCounting, listing, selectListing, socialMap, webViewLinkUrl } = props;
    if (!listing) {
        return null;
    }

    let brokerName = '';
    // Use lead broker as primary, since lead agent is going to be depreciated
    if (listing.leadBrokerName) {
        brokerName += `(${listing.leadBrokerName})`;
    }
    
/*
    } else if (listing.leadAgent) {
        const advisor = listing.leadAgent.replace(' (At-Large)', '');
        brokerName += `(${advisor})`;
    }
*/

    const shopName = '(Engel & Voelkers Chicago/North Shore)';
    const listingName = listing.name.replace(shopName, '').trim();

    // Turnable doesn't have an input file type because you can't upload to it. It is auto generated from the brochure
    /*
    const inputFileDataPlusTurnable = [...inputFileData,
                                       new InputFileDataListingUpload('turnable', 'Turnable Brochure', 'Brochure_Turnable_URL__c', ListingFileType.TurnableBrochure, NewOrUpdateType.Unknown)];
*/
    let anySocialImages = false;
    var socialMapJsx = socialMap && isCounting === false ? Object.entries(socialMap).map(([socialCategory, count]) => {
        const socialCategoryString = socialCategory.toString();
        if (count > 0)
            anySocialImages = true;

        return (
            <span key={socialCategoryString}>
                {socialCategoryString}:
                &nbsp;
                {count}
                <br />
            </span>
        );
    }) : isCounting ? <><Spinner animation="border" size="sm" /> Counting, please wait...</> : <></>;

    const socialLinkButtonJsx = socialMap && isCounting === false && webViewLinkUrl  
                        ? (<Button style={{ position: 'relative', right: '-14rem', top: '-9rem' }} 
                                   variant="outline-secondary"
                                   onClick={() => { window.open(webViewLinkUrl, `google-drive-${listing.id}`); }}
                                   >
                            <FontAwesomeIcon icon="arrow-up-right-from-square" />
                          </Button>)
                        : <></>;

    let style : CSSProperties = { width: '100%', textAlign: 'left', borderColor: 'gray' };
    if (socialMap) {
        if (!anySocialImages) {
           style = { ...style, borderColor: 'red' };
        } else {
            style = { ...style, borderColor: 'green' };
        }
    }

    return (
        <Row>
            <Col size={9}>
                <Button
                    variant={isSelected ? 'primary' : 'outline-primary'}
                    style={style}
                    onClick={() => { selectListing(listing.id); }}
                >
                    <Row className="align-middle">
                        <Col size={8}>
                            {listingName}
                            <br />
                            {brokerName}
                        </Col>
                        <Col size={4}>
                            { //<ListingUploadItemFiles key={Id} inputFileData={inputFileDataPlusTurnable} listing={listing} isUploading={isUploading} />
                                socialMapJsx
                            }
                            {
                                socialLinkButtonJsx
                            }
                            
                        </Col>
                    </Row>
                </Button>
            </Col>
        </Row>
    );
};

const shouldRender = (prevProps: ListingUploadSocialItemProps, nextProps: ListingUploadSocialItemProps) => {
    if (prevProps.isSelected === nextProps.isSelected && prevProps.isUploading === nextProps.isUploading) {
        return true;
    }

    return false;
};

export const MemorizedListingUploadItem = React.memo(ListingUploadSocialItem, shouldRender);
