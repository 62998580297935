import * as React from 'react';
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { OrderStepStateEnum } from '@plazarre/phoenix-ux-order/lib/esm/core/data-types/order-step-state-enum';
import { OrderButtons } from '@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-buttons';
import { applyStepStateAdjustment, OrderForm } from '@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form';
import { OrderFormSlider } from '@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form-slider';

import { IPostCardCustomOrderProps } from '../pages/order-postcard';
import { OrderFormDropdown } from '@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form-dropdown';
import { IExtraFields, MailingListImportList } from './mailing-list/mailing-list-import-list';
import { MailingListImportSampleRecords } from './mailing-list/mailing-list-import-sample-records';
import { PostcardCustomOrder } from '../../core/postcard/domain/postcard-custom-order';
import { Contact } from '../../core/postcard/domain/contact';
import { OrderFormRow } from '@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form-row';
import { Button, Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../App.css';
import { postcardMailingTypeDropdownValues, PostcardMailingTypeEnum } from '../../data/order-postcard-data';
import { IImportValidation } from '../../core/postcard/interfaces/iimport-validation';
import { MailingListImportGoogle } from './mailing-list/mailing-list-import-google';
import { ContactGroup } from '../../api/google-contacts';
import { Option } from './mailing-list/mailing-list-import-google';
import { FetchCall } from '@plazarre/phoenix.ux.fetch/lib/esm/core/domain/fetch-call';
import { EDDMMessage, EDDMMessageType, MailingListEDDM } from './mailing-list/mailing-list-eddm';
import { EDDMRoute } from '../../core/maps/domain/eddm-route';

interface HeaderWithDeleteButtonProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>
}

export const HeaderWithDeleteButton : React.FC<PropsWithChildren<HeaderWithDeleteButtonProps>> = (props) => {
    const { children, onClick } = props;

    return (
        <Card.Header style={{ backgroundColor: 'lightgray' }}>
            <Container fluid>
                <Row className="align-items-center">
                    <Col xxl={11}>
                        {children}
                    </Col>
                    <Col xxl={1}>
                        <Button
                            variant="outline-dark"
                            style={{ paddingLeft: 4, paddingRight: 4, paddingTop: 0, paddingBottom: 0 }}
                            onClick={(e) => onClick(e)}
                        >
                            <FontAwesomeIcon color="darkgray" className="pull-right" icon="trash-can" />
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Card.Header>
    );
}

export const PostcardOrderMailingList: React.FC<IPostCardCustomOrderProps> = (props: IPostCardCustomOrderProps) => {
    const { order, setOrder, updateSlug, id } = props;

    const [mailingType, setMailingType] = useState(order.mailingType === PostcardMailingTypeEnum.Unknown ? PostcardMailingTypeEnum.MailingList : order.mailingType);
    const [mailingListContacts, setMailingListContacts] = useState<Contact[]>(order.mailingListContacts);
    const [fileName, setFileName] = useState<string>(order.fileName);
    const [fileRecords, setFileRecords] = useState<string[]>(order.fileRecords);
    const [postCardCount, setPostcardCount] = useState(order.postCardCount);
    const [hasImportMailingList, setHasImportMailingList] = useState<boolean>(!!order.mailingListContacts.length);
    const [hasImportGoogleList, setHasImportGoogleList] = useState<boolean>(!!order.googleContacts.length);
    const [mappedFields, setMappedFields] = useState<number[]>(order.mappedFields);
    const [extraFields, setExtraFields] = useState<IExtraFields[]>(order.extraFields);
    const [importValidation, setImportValidation] = useState<IImportValidation | null>(order.importValidation);
    const [isLoadingMailingList, setIsLoadingMailingList] = useState(false);
    
    // (*) TODO: If order.GoogleGroup is null, then not success, but loading? (10/26/2022 - pel)
    const [googleGroups, setGoogleGroups] = useState<FetchCall<ContactGroup[]>>(order.googleGroups);
    const [googleContacts, setGoogleContacts] = useState<Contact[]>(order.googleContacts);
    const [selectedContactGroups, setSelectedContactGroups] = useState<Option[]>(order.selectedGoogleContactGroups);

    const [selectedRoutes, setSelectedRoutes] = useState<EDDMRoute[]>([]);
    const [route, setRoute] = useState<EDDMRoute | null>(null);
    

    const handleMessage = (event: any) => {
        const messageType = event?.data?.messageType ?? '';
        if (messageType !== 'eddm_route_change')
            return;

        const routeData = event?.data?.data ?? '';
        if (routeData) {
            setRoute(routeData);
        }
        
    }

    useEffect(() => {
        window.addEventListener("message", handleMessage, false);
        console.log('Added message listener for eddm_route_update.');

        return () => {
            // clean up the event listener
            console.log('Removing message listener for eddm_route_update.');
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    useEffect(() => {
        // Did we delete the records, then update file records so the right hand import display is removed.
        if (!hasImportMailingList) {
            setFileRecords([]);
        }
    }, [hasImportMailingList])

    const setOrderFields = (stepAdjustment: number, state: OrderStepStateEnum) => {
        const newOrder = JSON.parse(JSON.stringify(order)) as unknown as PostcardCustomOrder;
        newOrder.stepStates[newOrder.step] = state;

        newOrder.step += applyStepStateAdjustment(newOrder.step, stepAdjustment, newOrder.stepStates);
        if ((mailingType === PostcardMailingTypeEnum.PrintedNoPostage && stepAdjustment === 1) 
            || (mailingType === PostcardMailingTypeEnum.EDDM && stepAdjustment === 1)) {
            newOrder.stepStates[newOrder.step] = OrderStepStateEnum.Complete;
            newOrder.step++;
        }

        // If we are skipping this step, then skip over addresses too
        if (state === OrderStepStateEnum.Skipped) {
            newOrder.stepStates[newOrder.step] = OrderStepStateEnum.Skipped;
            newOrder.step++;
        }

        //newOrder.orderEV.requestShopTV = requestShopTV;
        //newOrder.orderEV.headline = requestShopTV ? headline : '';
        //const json = JSON.stringify(newOrder);
        //localStorage.setItem('evc-order', json);


        const uniqueValues = (value: Contact, index: number, array: Contact[]) => {
            const contactIndex = array.findIndex(e => e.id === value.id) ?? -1;
            return contactIndex === index;
        }

        const importMailingListContacts = hasImportMailingList ? mailingListContacts : [];
        
        // Only get distinct items (contacts can be in two groups)
        let importGoogleContacts = hasImportGoogleList ? googleContacts : [];
        importGoogleContacts = importGoogleContacts.filter(uniqueValues);
        
        newOrder.mailingListContacts = importMailingListContacts;
        newOrder.googleGroups = googleGroups;
        newOrder.googleContacts = importGoogleContacts;
        newOrder.contacts = [...importMailingListContacts, ...importGoogleContacts];
        newOrder.fileRecords = fileRecords;
        newOrder.postCardCount = isEddm ? selectedRoutes.reduce((acc, currentValue): number => acc + currentValue.totalCount, 0) : postCardCount;
        newOrder.fileName = fileName;
        newOrder.importValidation = importValidation;
        newOrder.extraFields = extraFields;
        newOrder.mappedFields = mappedFields;
        newOrder.removedContacts = [];
        newOrder.mailingType = mailingType;
        newOrder.selectedGoogleContactGroups = selectedContactGroups;
        setOrder(newOrder);
        updateSlug(id, newOrder);
    };

    /**
     * Updates the contacts to be able to pass into the next step - mapping the addresses
     * Keep this in a useCallback or the MailingListImportList will keep re-rendering because setContacts state exists on this object
     */
    const updateMailingListContacts = useCallback((contactsUpdated: Contact[]) => {
        setMailingListContacts(contactsUpdated);
    }, []);

    /**
     * Updates the contacts to be able to pass into the next step - mapping the addresses
     * Keep this in a useCallback or the MailingListImportList will keep re-rendering because setContacts state exists on this object
     */
     const updateGoogleContacts = useCallback((contactsUpdated: Contact[]) => {

        const distinctContacts = contactsUpdated.filter(Contact.uniqueValues); 
        setGoogleContacts(distinctContacts);
    }, []);

    const updateFileRecords = (fileRecordsUpdated: string[]) => {
        setFileRecords(fileRecordsUpdated);
    };

    const updateMappedFields = (mappedFieldsUpdated: number[]) => {
        setMappedFields(mappedFieldsUpdated);
    }

    const updateExtraFields = (extraFieldsUpdated: IExtraFields[]) => {
        setExtraFields(extraFieldsUpdated);
    };

    const updateImportValidation = (importValidationUpdated: IImportValidation | null) => {
        setImportValidation(importValidationUpdated);
    }

     /**
     * Update the import file name selected
     * Keep this in a useCallback or the MailingListImportList will keep re-rendering because updateFileName is a useEffect dependency in child object
     */

    const updateFileName = useCallback((fileName: string) => {
        setFileName(fileName);
    }, []);

    const colLg = 4;

    

    const isNoAddresses = mailingType === PostcardMailingTypeEnum.PrintedNoPostage;
    const isEddm = mailingType === PostcardMailingTypeEnum.EDDM;

    const noAddressesJsx = (
        <OrderFormRow header="Post Cards Requested" value={postCardCount} colLg={colLg}>
            <OrderFormSlider value={postCardCount} valueMin={10} valueMax={1000} step={10} onChange={setPostcardCount} />
        </OrderFormRow>
    );


    const contactToBeImportedJsx = isLoadingMailingList ? <Spinner size="sm" animation="border" /> : <>{googleContacts.length}</>;
    const primarySphereAccordionJsx = googleContacts.length > 0 ? <><b>Import Google Contacts</b>: {contactToBeImportedJsx} contact(s) to be imported</> : 
                                                                                    <><b>Import Google Contacts</b></>;

    const importMailingListJsx = fileName ? <><b>Import Mailing List</b>: {mailingListContacts.length} contact(s) to be imported [{fileName}]</> : 
                                                                                    <><b>Import Mailing List</b></>;

    const importMailingListInvalid = hasImportMailingList ? (fileName.length === 0 ? true : false) : false;

    const buttonsJsx = (
        <OrderButtons
            submitLabel="Next"
            submitDisabled={importMailingListInvalid || (!hasImportMailingList && !hasImportGoogleList && !isNoAddresses && !isEddm) || (isEddm && selectedRoutes.length === 0)}
            submitWaitingIcon={isLoadingMailingList}
            skipLabel="Skip (for now)"
            backLabel="Back"
            SetOrderFields={setOrderFields}
        />
    );

    /** Adds a route from the route button */
    const addRouteClicked = (route: EDDMRoute) => {
        // Does route already exist?
        var routeCheck = selectedRoutes.find(e => e.zip === route.zip && e.cridId === route.cridId);
        if (routeCheck) {
            return;
        }

        // No, add it.
        setSelectedRoutes((prevRoutes) => {
            const newRoutes = [...prevRoutes];
            const newRoute = {...route, pathPolylines:[]};
            newRoutes.push(newRoute);
            const sortedRoutes = newRoutes.sort(EDDMRoute.sortRoutesByZipThenCridIdAsc);

            // Set the routes selected change
            const message: any = { messageType: 'eddm_routes_selected', data: [ sortedRoutes, null] };
		    window.postMessage(message);

            return sortedRoutes;
        });


        // Turn off the mouseclick
        const message = { messageType: 'eddm_route_mouseclick', data: null };
		window.postMessage(message);
    }

    /** Deletes a route from the trash button */
    const deleteRouteClicked = (route: EDDMRoute) => {
        setSelectedRoutes((prevRoutes) => {
            const newRoutes = [...prevRoutes];
            const deleteRouteIndex = newRoutes.findIndex(e => e.zip === route.zip && e.cridId === route.cridId);
            if (deleteRouteIndex === -1)
                throw new Error('deleteRouteClicked: unable to find index of route.');

            newRoutes.splice(deleteRouteIndex, 1);
            const sortedRoutes = newRoutes.sort(EDDMRoute.sortRoutesByZipThenCridIdAsc);

            // Set the routes selected change
            const message: any = { messageType: 'eddm_routes_selected', data: [ sortedRoutes, route] };
            window.postMessage(message);

            return sortedRoutes;
        });

       
    }

    let exampleJsx = <></>;
    if (isEddm) {
        const selectedRoutesJsx = selectedRoutes.map(e => {
            return (
                <Card className="eddm" 
                    style={{ height: '1.65rem', paddingLeft: '0.5rem' }} 
                    onMouseOver={() => {
                        const message: EDDMMessage = { messageType: EDDMMessageType.eddm_mouseover, data: e };
                        window.postMessage(message);
                    }}
                    onMouseOut={() => {
                        const message: EDDMMessage = { messageType: EDDMMessageType.eddm_routes_selected, data: [ selectedRoutes, null, null ] };
                        window.postMessage(message);
                    }}
                    >
                    <span>
                    {e.zip}-{e.cridId} ({e.totalCount})
                    &nbsp; &nbsp;
                    <FontAwesomeIcon icon="trash-can" onClick={() => deleteRouteClicked(e)} />
                    </span>
                </Card>
            );
        });

        exampleJsx = (
            <>
                <Row style={{ height: '75vh', overflowY: 'auto' }}>
                    <Col md={6} style={{ padding: 0 }}>
                        <b>Route Information:</b>
                        <br />
                        Route Id: {route?.cridId}
                        <br />
                        Business Count: {route?.businessCount}
                        <br />
                        Resident Count: {route?.residentCount}
                        <br />
                        Total Count: {route?.totalCount}
                        <br />
                        Median Income: ${route?.medianIncome.toLocaleString('en-US')}
                        <br />
                        Median Age: {route?.medianAge}
                        <br /><br />
                        {route && <Button variant='outline-danger' onClick={() => addRouteClicked(route)}>Add Route</Button>}
                    </Col>
                    <Col md={6} style={{ padding: 0 }}>
                        <b>Selected Routes:</b>
                        <br />
                        {selectedRoutesJsx}
                        <br />
                        <b>Total Count: </b>
                        {selectedRoutes.reduce((acc, currentValue): number => acc + currentValue.totalCount, 0).toLocaleString('en-US')}
                    </Col>
                </Row>

            </>
        );
    } else {
         exampleJsx = (
            <Row style={{height: '75vh', overflowY: 'auto'}}>
                <MailingListImportSampleRecords fileRecords={fileRecords} maxRecords={10}/>
            </Row>
            );
    }

    const mapJsx = useMemo(() => <MailingListEDDM />, []);
    return (
        <div>
            <OrderForm id="postcard" name="postcard" buttonsJsx={buttonsJsx} exampleJsx={exampleJsx} SetOrderFields={setOrderFields} >
                <OrderFormDropdown
                    label="Mailing List Type(s)"
                    variant="secondary"
                    value={mailingType}
                    dropdownValues={postcardMailingTypeDropdownValues}
                    colLg={colLg}
                    middleColLg={colLg * 2}
                    setValue={setMailingType}
                />

                { !isNoAddresses && !isEddm && !hasImportGoogleList && (
                    <>
                        <Row className="align-items-center" style={{ height: '0.5rem' }}/>
                        <Row className="align-items-center" style={{ height: '3rem' }}>
                            <Col 
                                className="align-items-center d-flex" 
                                xxl={{ offset: 3, span: 4 }} 
                                style={{ border: '2px dotted lightgray', height: '3rem', cursor: 'pointer' }} 
                                onClick={() => {setHasImportGoogleList(true)}}
                                >
                                <div style={{ textAlign: 'center', width: '100%' }} tabIndex={3} >
                                    <FontAwesomeIcon icon="plus" />
                                    &nbsp;
                                    Import Your Google Contacts
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center" style={{ height: '0.5rem' }}/>
                    </>
                    )
                }

                { !isNoAddresses && !isEddm && hasImportGoogleList && (
                    <Card style={{ width: '95%'}} >
                        <HeaderWithDeleteButton onClick={() => {
                            updateGoogleContacts([]);
                            //setGoogleContacts([]);
                            setSelectedContactGroups([]);
                            setHasImportGoogleList(false);
                            
                        }}>
                            {primarySphereAccordionJsx}
                        </HeaderWithDeleteButton>
                        <Card.Body>
                            {/* <MailingListPrimarySphere updateContacts={updatePrimarySphereContacts} /> */
}
                            <MailingListImportGoogle 
                                googleGroups={googleGroups}
                                setGoogleGroups={setGoogleGroups}
                                updateContacts={updateGoogleContacts} 
                                isLoadingMailingList={isLoadingMailingList} 
                                setIsLoadingMailingList={setIsLoadingMailingList} 
                                selectedContactGroups={selectedContactGroups}
                                setSelectedContactGroups={setSelectedContactGroups}
                                />
                        </Card.Body>
                    </Card>
                    ) 
                }

                { !isNoAddresses && !isEddm && !hasImportMailingList && (
                    <>
                    <Row className="align-items-center" style={{ height: '0.5rem' }}/>
                    <Row className="align-items-center" style={{ height: '3rem' }}>
                        <Col 
                            className="align-items-center d-flex" 
                            xxl={{ offset: 3, span: 4 }} 
                            style={{ border: '2px dotted lightgray', height: '3rem', cursor: 'pointer' }} 
                            onClick={() => {setHasImportMailingList(true)}}
                            >
                            <div style={{  textAlign: 'center', width: '100%' }} tabIndex={4}>
                                <FontAwesomeIcon icon="plus" />
                                &nbsp;
                                Import Mailing List
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center" style={{ height: '0.5rem' }}/>
                    </>
                    )
                }

                { !isNoAddresses && !isEddm && hasImportMailingList && (
                    <Card style={{ width: '95%' }} >
                        <HeaderWithDeleteButton onClick={() => {setHasImportMailingList(false); setFileName('');}}>
                            {importMailingListJsx}
                        </HeaderWithDeleteButton>
                        <Card.Body>
                            {<MailingListImportList
                                shouldHideFileUploadAfterUse={true}
                                updateContacts={updateMailingListContacts}
                                updateFileName={updateFileName}
                                updateFileRecords={updateFileRecords}
                                updateMappedFields={updateMappedFields}
                                updateExtraFields={updateExtraFields}
                                updateImportValidation={updateImportValidation}
                                fileRecords={fileRecords}
                                importValidation={importValidation}
                                extraFields={extraFields}
                                mappedFields={mappedFields}
                                fileName={fileName}
                            />}
                        </Card.Body>
                    </Card>
                    ) 
                }

                {isNoAddresses && noAddressesJsx}

                {isEddm && mapJsx}
            </OrderForm>
        </div>
    );
};
