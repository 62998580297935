import * as React from 'react';
import { CSSProperties, useEffect, useRef } from 'react';
import { Col, ProgressBar, Row } from 'react-bootstrap';

import { OrderStepStateEnum } from '@plazarre/phoenix-ux-order/lib/esm/core/data-types/order-step-state-enum';
import { OrderButtons } from '@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-buttons';
import { applyStepStateAdjustment, OrderForm } from '@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form';

import { IPostCardCustomOrderProps } from '../pages/order-postcard';
import { UploadFile } from '@plazarre/phoenix.ux.file-upload/lib/esm/ux/molecules/upload-file';
import { useFileTransfer } from '../../hooks/use-file-transfer';
import { PostcardCustomOrder } from '../../core/postcard/domain/postcard-custom-order';


export const PostcardOrderDesign: React.FC<IPostCardCustomOrderProps> = (props: IPostCardCustomOrderProps) => {
    const { order, setOrder, updateSlug, id } = props;

    const [fileTransfers, handleChange] = useFileTransfer(order.fileTransfers);

    const setOrderFields = (stepAdjustment: number, state: OrderStepStateEnum) => {
        const newOrder = JSON.parse(JSON.stringify(order)) as unknown as PostcardCustomOrder;
        newOrder.stepStates[newOrder.step] = state;

        newOrder.step += applyStepStateAdjustment(newOrder.step, stepAdjustment, newOrder.stepStates);
        //newOrder.orderEV.requestShopTV = requestShopTV;
        //newOrder.orderEV.headline = requestShopTV ? headline : '';
        //const json = JSON.stringify(newOrder);
        //localStorage.setItem('evc-order', json);
        newOrder.fileTransfers = fileTransfers;
        newOrder.fileTransfers.forEach((file, index) => {
            const image : HTMLImageElement | null = document.getElementById(`image${index}`) as HTMLImageElement | null;
            if (image && image.src) {
                newOrder.fileTransfers[index].blobLocalSource = image.src;
                newOrder.fileTransfers[index].fileName = file.file.name;
                newOrder.fileTransfers[index].fileType = file.file.type;
            }
        });

        setOrder(newOrder);
        updateSlug(id, newOrder);
    };

    const isValidated = fileTransfers.filter(e => e.isComplete).length > 0;
    const buttonsJsx = (
        <OrderButtons
            submitLabel="Next"
            submitDisabled={!isValidated}
            skipLabel="Skip"
            backLabel="Back"
            SetOrderFields={setOrderFields}
        />
    );

    // const colLg = 4;
    const examplePostcardJsx = <></>;
    const headlineRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (headlineRef && headlineRef.current) {
            headlineRef.current.focus();
        }
    }, []);

    const fileTypes = ["JPG", "PNG", "GIF", "PDF", "NEF"];
    
    
    const imageStyles : CSSProperties = {maxWidth: '100%', maxHeight: '11rem', minHeight: '11rem', minWidth: 'auto', margin: '0 auto' };
    const imageHolderStyles : CSSProperties = { border: '1px solid black', maxHeight: '11rem', maxWidth: '20rem', 
                                                display: 'none', background: 'lightgray'};

    const imageHolders : JSX.Element[] = fileTransfers.map((fileTransfer, index) => {
            const imageId = `image${index}`;
            const imageHolderId = `imageholder${index}`;
            
            // Be sure startOffset isn't past the length. If so correct.
            const startOffset = fileTransfer.startOffset > fileTransfer.length ? fileTransfer.length : fileTransfer.startOffset;
            const now = Math.round((startOffset / fileTransfer.length)*100);

            const errorJsx = fileTransfer.isError ? <span style={{color:'red'}}>{fileTransfer.errorDescription}</span> : <></>;

            return (
                <Col xxl={3} id={imageHolderId} className='text-center align-items-end' style={imageHolderStyles}>
                    <img id={imageId} alt={fileTransfer.file.name} style={imageStyles} />
                    <ProgressBar now={now} label={`${now}%`} style={{marginTop: '-1rem'}}/>
                    {errorJsx}
                </Col>
            );
        }) ?? [];

    return (
        <OrderForm id="postcard" name="postcard" buttonsJsx={buttonsJsx} exampleJsx={examplePostcardJsx} SetOrderFields={setOrderFields}>
            <Row style={{ height: '2rem'}}>
                <Col>
                    Do you have any design files to upload?
                </Col>
            </Row>
            <Row style={{ height: '8rem' }}>
                <Col xxl={12}>
                    <UploadFile name="postcard" fileTypes={fileTypes} handleChange={handleChange} multiple={true} />
                </Col>
            </Row>

            <Row xxl={10}>
                {imageHolders}
            </Row>
        </OrderForm>
    );
};
