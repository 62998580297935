import { useContext, useEffect, useState } from "react";
// import { RouteComponentProps } from "react-router";
// import { createBrowserHistory } from "history";

import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

import { OrderStepStateEnum } from '@plazarre/phoenix-ux-order/lib/esm/core/data-types/order-step-state-enum';
import { OrderProgressBar } from '@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-progress-bar';
// import { applyStepStateAdjustment } from '@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-form';
// import { OrderButtons } from '@plazarre/phoenix-ux-order/lib/esm/ux/molecules/order-buttons';

import { IUpdateSlugFunction } from "../../core/postcard/interfaces/iupdate-slug-function";
import { OrderNewOrEditEnum } from "@plazarre/phoenix-ux-order/lib/esm/core/data-types/order-new-or-edit-enum";
import { PostcardCustomOrder } from "../../core/postcard/domain/postcard-custom-order";
// import { useLocation, useNavigate, useParams } from "react-router";
import { PostCardOrderStepEnum } from "../../core/postcard/data-types/postcard-order-step-enum";
import { PostcardOrderStepComponent } from "../organisms/postcard-order-step-component";
import { PostcardOrderCopy } from "../templates/order-postcard-copy";
import { PostcardOrderType } from "../templates/order-postcard-type";
import { PostcardOrderDesign } from "../templates/order-postcard-design";
import { PostcardOrderMailingList } from "../templates/order-postcard-mailing-list";
import { TemplateOrderPostCardAddresses } from "../templates/order-postcard-addresses";
import { TemplateOrderPostCardSummary } from "../templates/order-postcard-summary";
import { TemplateOrderPostCardComplete } from "../templates/order-postcard-complete";
import { StepsCompletedEnum } from "@plazarre/phoenix-ux-order/lib/esm/core/data-types/steps-completed-enum";
import { EVHeader } from "@plazarre/phoenix-ux-order/lib/esm/ux/organisms/ev-header";
import { MenuItem } from "@plazarre/phoenix-ux-order/lib/esm/ux/atoms/menu-item";
import { useLocation } from "react-router";
import { AppContext } from "@plazarre/phoenix-ux-order/lib/esm/hook/use-application-context";



// TODO: move to the ux-order library (9/27/2022 - pel)
export interface IOrderProps<T> {
    index: number,
    // key: string,
    id: string,
    // history: History,
    stepSlug: string,
    updateSlug: IUpdateSlugFunction<T>,
    order: T,
    setOrder: React.Dispatch<React.SetStateAction<T>>,
    newOrEdit: OrderNewOrEditEnum,
    isUpdating?: boolean,
}

export interface IPostCardCustomOrderProps extends IOrderProps<PostcardCustomOrder> {
}

export interface IPostcardOrderStepComponent {
    component: React.FC<IPostCardCustomOrderProps>,
    slug: string,
    index: PostCardOrderStepEnum
}

export const PostcardOrderStepComponents: IPostcardOrderStepComponent[] = [
    { component: PostcardOrderType, slug: 'type', index: PostCardOrderStepEnum.Type },
    { component: PostcardOrderCopy, slug: 'copy', index: PostCardOrderStepEnum.Copy },
    { component: PostcardOrderDesign, slug: 'design', index: PostCardOrderStepEnum.Design },
    { component: PostcardOrderMailingList, slug: 'mailing', index: PostCardOrderStepEnum.MailingList },
    { component: TemplateOrderPostCardAddresses, slug: 'addresses', index: PostCardOrderStepEnum.Addresses },
    { component: TemplateOrderPostCardSummary, slug: 'summary', index: PostCardOrderStepEnum.Summary },
    { component: TemplateOrderPostCardComplete, slug: 'complete', index: PostCardOrderStepEnum.Complete },

    //{ component: ListingOrderComplete, slug: 'complete', index: PostcardOrderStepEnum.Complete },
];

export const progressSteps = ['Type', 'Copy', 'Design', 'Mailing List', 'Addresses', 'Summary'];

export interface IOrder {
    stepStates: OrderStepStateEnum[]
    step: number
  };
  
  const stepsCompleted: StepsCompletedEnum[] = [StepsCompletedEnum.No, StepsCompletedEnum.No, StepsCompletedEnum.No, StepsCompletedEnum.No];

  // const stepStates = order ? order.stepStates : [OrderStepStateEnum.Incomplete, OrderStepStateEnum.Incomplete, OrderStepStateEnum.Incomplete,
  //  OrderStepStateEnum.Incomplete];

// const stepStates = [OrderStepStateEnum.Incomplete, OrderStepStateEnum.Incomplete, OrderStepStateEnum.Incomplete, OrderStepStateEnum.Incomplete];

// Incoming parameters are: mlsNumber and price
export const PostcardOrder : React.FC = (props: any) => {
    const [isBrowserButtonPressed, setIsBrowserButtonPressed] = useState(false);
    const [order, setOrder] = useState<PostcardCustomOrder>(new PostcardCustomOrder());
    const context = useContext(AppContext);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();

    // const [position, setPosition ] = useState(0);
    // console.log('position', position);
    // const [orderLoadingState, setOrderLoadingState] = useState(OrderLoadingStateEnum.Start);
    // const contextType = useContext(AuthContext);
    // const params = useParams();
    // const navigate = useNavigate();
    // const match = useMatch();
    // const location = useLocation();

    // const { id, key, index, stepSlug } = params;
    // const { isMlsFrame, isSourceMls } = getSearchParams(location);

    // If address is in the url, stepSlug is a valid param, otherwise it is in id
    // const stepSlugString = (stepSlug != null && stepSlug !== undefined) ? stepSlug : id;
    // const listingAddress = id;

    const { step } = order;
    
    useEffect(() => {
        // this is called whenever new locations come in
        // the action is POP, PUSH, or REPLACE
        console.log('react router createBrowserHistory called!');
    }, [location]);

    // Signal that the forward and back browser buttons, to re-render as needed
    useEffect(() => {
        const onBackButtonEvent = (e: PopStateEvent) => {
            e.preventDefault();
            setIsBrowserButtonPressed(true);
        };

        // (*) TODO: replace other function (9/27/2022 - pel  below)
        // https://reactrouter.com/en/v6.3.0/getting-started/concepts
        // let history = createBrowserHistory();
        //history.listen(({ location, action }) => {
            // this is called whenever new locations come in
            // the action is POP, PUSH, or REPLACE
            //console.log('react router createBrowserHistory called!');
          //});
  
        window.addEventListener('popstate', onBackButtonEvent);

        // Indicate we are ready to start the loading process
        // setOrderLoadingState(OrderLoadingStateEnum.Ready);

        return function cleanup() {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, []);

    useEffect(() => {
        /*
        if (orderLoadingState === OrderLoadingStateEnum.Ready) {
            const newOrder = tryToRetrieveAddressInfoFromParams(order, location, listingAddress);
            setOrder(newOrder);
            setOrderLoadingState(OrderLoadingStateEnum.UrlLoaded);
        }

        if (orderLoadingState === OrderLoadingStateEnum.UrlLoaded) {
            const newOrder = loadDataFromLocalStorage(order, isMlsFrame);

            // Set the state to match the localstorage
            setSelectedPackage(newOrder.packageId);
            setOrder(newOrder);
            setOrderLoadingState(OrderLoadingStateEnum.LoadUserInfo);
        }

        if (orderLoadingState === OrderLoadingStateEnum.LoadUserInfo) {
            // Load the shop information about the user if possible
            if (order.userGoogleId) {
                loadUserInformation();
            } else {
                setOrderLoadingState(OrderLoadingStateEnum.StorageLoaded);
            }
        }

        if (orderLoadingState === OrderLoadingStateEnum.StorageLoaded) {
            const defaultShopId = contextType ? contextType.defaultShopId : undefined;
            const newOrder = initializeOrderData(order, listingAddress, isMlsFrame, isSourceMls, isExtensionV15OrGreater, defaultShopId);
            setOrder(newOrder);
            setOrderLoadingState(OrderLoadingStateEnum.WriteStorage);
        }

        if (orderLoadingState === OrderLoadingStateEnum.WriteStorage) {
            // Store localdata changes and update the url to be handled correctly (goes to address or package)
            localStorage.setItem('evc-order', JSON.stringify(order));
            setOrderLoadingState(OrderLoadingStateEnum.UpdateSlug);
        }

        if (orderLoadingState === OrderLoadingStateEnum.UpdateSlug) {
            // Store localdata changes and update the url to be handled correctly (goes to address or package)
            updateSlug(id, order, isMlsFrame, isSourceMls, history);
            setOrderLoadingState(OrderLoadingStateEnum.UpdateOrderId);
        }
        */
    }, []); // [orderLoadingState]);

    /*
    const loadUserInformation = async (): Promise<void> => {
        const url = `api/phoenix/PhoenixUserGetByGoogleLogin?subject=${order.userGoogleId}`;
        const userResponse = await fetch(url);
        if (userResponse.ok) {
            const user = await userResponse.json();
            if (user) {
                const newOrder = order;
                newOrder.userName = user.userName;
                newOrder.shopOverrideId = user.defaultShopId;
                appThis.setState((prevState) => {
                    const newState = { ...prevState, userName: user.userName };
                    return newState;
                }, () => { }); // setOrderLoadingState(OrderLoadingStateEnum.StorageLoaded));
                setOrder(newOrder);
                return;
            }
        }

        console.error(`Error loadShopInformation: ${userResponse.statusText}`);
        // setOrderLoadingState(OrderLoadingStateEnum.StorageLoaded);
    };
    */
    
    /** Update the user info with the order if and last name if available */
    useEffect(() => {
        // Verify we have an order object state, if not wait
        if (!order) {
            return;
        }

        // Wait until the data is all set before chaning anything.
        /*
         * 
        if (orderLoadingState === OrderLoadingStateEnum.UpdateOrderId) {
            if (isNullUndefinedOrEmptyString(order.evOrderId)
                || (!isNullUndefinedOrEmptyString(order.evOrderId) && order.evOrderId.includes('EV-'))) {
                // Do we have an order id set? This is their last name and the order number
                let lastName = 'EV';
                if (contextType != null && !isNullUndefinedOrEmptyString(contextType.userName)) {
                    const names = contextType.userName.split(' ');
                    if (names.length > 1) {
                        lastName = names[names.length - 1];
                    }
                }

                const newOrder = order;
                newOrder.evOrderId = `${lastName}-${order.orderNumber}`;
                setOrder({ ...newOrder });
            }

            setOrderLoadingState(OrderLoadingStateEnum.Completed);
            */
    
    }, [order]); // (contextType ? contextType.userName : null), (order ? order.orderNumber : null), orderLoadingState]);

    // handle the back/forward buttons on the browser
    useEffect(() => {
        if (order != null) {
            if (order.step == null || order.step === undefined) {
                return;
            }
            /*
            if (ListingOrderStepComponents[order.step].slug !== stepSlugString) {
                const newOrder = JSON.parse(JSON.stringify(order)) as unknown as MarketingPackageOrder;

                if (isBrowserButtonPressed) {
                    let stepIndex = ListingOrderStepEnum.Address;

                    // Allow the back button to work on the browser, but only if not on the complete step
                    // If complete, go to the address screen
                    if (order.step < ListingOrderStepEnum.Complete) {
                        // Go with browser slug
                        stepIndex = ListingOrderStepComponents.findIndex((e) => e.slug === stepSlugString);
                    }

                    newOrder.step = stepIndex;
                    setIsBrowserButtonPressed(false);
                    const json = JSON.stringify(newOrder);
                    localStorage.setItem('evc-order', json);
                    setOrder(newOrder);
                }
            }
            */
        }
    }, [isBrowserButtonPressed, order]);



    let progressBarJsx = (<></>);
    if (order.step !== PostCardOrderStepEnum.Complete) {
        progressBarJsx = (
            <div key="progress-bar-div" style={{ width: '56.5%', marginTop: '0.9em' }}>
                <OrderProgressBar steps={progressSteps} stepStates={order.stepStates} stepsCompleted={stepsCompleted} currentStep={order ? step : 0} />
                <hr style={{ borderTop: '2px solid red', maxWidth: '100%' }} />
            </div>
        );
    }

    /*
    if (orderLoadingState < OrderLoadingStateEnum.Completed) {
        return (
            <div style={{ paddingLeft: '18pt' }}>Last step! Hang on...</div>
        );
    }
    */

   

/*
    const setOrderFields = (stepAdjustment: number, state: OrderStepStateEnum) => {
        const newOrder = JSON.parse(JSON.stringify(order)) as unknown as PostcardCustomOrder;
        newOrder.stepStates[newOrder.step] = state;
        newOrder.step += applyStepStateAdjustment(newOrder.step, stepAdjustment, newOrder.stepStates);
        
        //const json = JSON.stringify(newOrder);
        //localStorage.setItem('postcard-order', json);
        setPosition(newOrder.step);
        setOrder(newOrder);
        // updateSlug(id, newOrder, history);
        };
*/
    // const examplePostcardJsx : JSX.Element = <></>;
    // const buttonsJsx = <OrderButtons submitLabel="Submit" backLabel="Back" skipLabel="Skip" SetOrderFields={setOrderFields} />;
    // const colLg = 3;



    

    
    const stepSlugString = 'type';
    const id = '';
    const key = 'key';

    const updateSlug =  (id: string, newOrder: PostcardCustomOrder) => {};

    const menuJsx = isMenuOpen ? (
        <Col lg={2}>
            <ul>
                <MenuItem name="Postcards Templates" url="/order/postcard/abc"/>
                <MenuItem name="Paper Types" url="/order/postcard/def"/>
            </ul>
        </Col>
    ) : <></>;

    return (
        <>
            <EVHeader 
                shouldShowMenu={context.isUserLoggedIn} 
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                />
            <Row>
            {menuJsx}
            <Col lg={isMenuOpen ? 10 : 12}>
            {progressBarJsx}
            {// <OrderHeader key="listing-order-header-component" order={order} />
            // history={history}
            }
            {!context.isUserLoggedIn && <div style={{ paddingLeft: '1.25rem'}}>Please sign in to start your order.</div>}

            {context.isUserLoggedIn && 
                <PostcardOrderStepComponent
                    id={id}
                    key={key}
                    index={0}
                    order={order}
                    setOrder={setOrder}
                    stepSlug={stepSlugString}
                    updateSlug={updateSlug}
                    newOrEdit={OrderNewOrEditEnum.New}
                />
            }
            </Col>
            </Row>
        </>
    );
};
