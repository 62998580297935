import { IPathPolyline } from "./ipath-polyline";

/** Used to store an eddm route from the USPS */
export class EDDMRoute {
	zip: string = '';
	cridId: string = '';
	medianAge: number = 0;
	medianIncome: number = 0;
	residentCount: number = 0;
	businessCount: number = 0;
	totalCount: number = 0;
	type: string = '';
	pathPolylines: IPathPolyline[] = [];

	/** Sorts the routes */
	static sortRoutesByZipThenCridIdAsc = (a : EDDMRoute,b : EDDMRoute) => {
		if (a.zip < b.zip) {
			return 1;
		}

		if (a.cridId < b.cridId) {
			return 1;
		}

		if (a.zip === b.zip && a.cridId === b.cridId) {
			return 0;
		}

		return -1;
	};
}