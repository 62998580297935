import { CSSProperties, ChangeEventHandler, MouseEventHandler, useCallback, useEffect, useRef, useState } from "react";
import { ListingUploadSocialItem } from "../molecules/listing-upload-social-item";
import { Col, Container, Form, ProgressBar, Row } from "react-bootstrap";
import { UploadFile, UploadFormElement } from "@plazarre/phoenix.ux.file-upload/lib/esm/ux/molecules/upload-file";
import { useFileTransfer } from "../../hooks/use-file-transfer";
import { CategorizeRequest, FileTarget, GetCountByCategoryResult, ListingCategoryCountMap, SocialCategory, useSocial } from "../../api/domain/social";
import { DoFetchRequest } from "@plazarre/phoenix.ux.fetch/lib/esm/hook/use-fetch-json";
import { ButtonSpinner } from "@plazarre/phoenix-ux-order/lib/esm/ux/atoms/button-spinner";
import React from "react";
import useWindowDimensions from "../../hooks/use-window-dimensions";
import { FetchCall } from "@plazarre/phoenix.ux.fetch/lib/esm/core/domain/fetch-call";
import { toast } from "react-toastify";
import { Listing, useListing } from "@plazarre/phoenix.javascript.approval";

export interface SocialMediaUploadProps {
}

export const SocialMediaUploadPage : React.FC<SocialMediaUploadProps> = (props) => {
    const categories = SocialCategory.Dropdowns;
    const uploadRef = useRef<UploadFormElement>(null);
    const windowDimensions = useWindowDimensions();
    
    const [idSelected, setIdSelected] = useState<string>('');
    const [category, setCategory] = useState(-1);
    const [search, setSearch] = useState('');
    const [countByCategory, setCountByCategory] = useState<Map<string, FetchCall<GetCountByCategoryResult>>>(new Map<string, FetchCall<GetCountByCategoryResult>>());

    const [isCategorizeButtonEnabled, setIsCategorizeButtonEnabled] = useState<boolean>(false);
    const [isMoving, setIsMoving] = useState<boolean>(false);

    const [isCountingList, setIsCountingList] = useState<string[]>([]);
    const [isUploadingList, ] = useState<string[]>([]);

    //const [inputFileData, setInputFileData] = useState<any[]>([]);
    // const [isClearingFiles, ] = useState<boolean>(false);

    const { listings, doGetListings } = useListing()
    const { doCategorizePost, doCategoryCountPost } = useSocial();

    const [fileTransfers, handleChange, clearFileTransfers] = useFileTransfer([]);

    const imageStyles : CSSProperties = {maxWidth: '100%', maxHeight: '11rem', minHeight: '11rem', minWidth: 'auto', margin: '0 auto' };
    const imageHolderStyles : CSSProperties = { border: '1px solid black', maxHeight: '11rem', maxWidth: '20rem', 
                                                display: 'none', background: 'lightgray'};
    
    const imageHolders : JSX.Element[] = fileTransfers.map((fileTransfer, index) => {
            const imageId = `image${index}`;
            const imageHolderId = `imageholder${index}`;
            
            // Be sure startOffset isn't past the length. If so correct.
            const startOffset = fileTransfer.startOffset > fileTransfer.length ? fileTransfer.length : fileTransfer.startOffset;
            const now = Math.round((startOffset / fileTransfer.length)*100);
    
            const errorJsx = fileTransfer.isError ? <span style={{color:'red'}}>{fileTransfer.errorDescription}</span> : <></>;
    
            return (
                <Col xxl={3} id={imageHolderId} className='text-center align-items-end' style={imageHolderStyles}>
                    <img id={imageId} alt={fileTransfer.file.name} style={imageStyles} />
                    <ProgressBar now={now} label={`${now}%`} style={{marginTop: '-1rem'}}/>
                    {errorJsx}
                </Col>
            );
        }) ?? [];
    

    useEffect(() => {
        doGetListings();
    }, [doGetListings]);

    /** Do we need to enable the cateogize (assign) button? */
    useEffect(() => {
        const areFileTransfersCompleted = fileTransfers?.every((fileTransfer) => fileTransfer.isComplete) && fileTransfers?.length > 0;
        setIsCategorizeButtonEnabled(!!idSelected && areFileTransfersCompleted && category !== -1);
    }, [idSelected, fileTransfers, category]);

    const getCategoryCountAsync = useCallback(async (salesForceListingId: string) => {
        setIsCountingList((prev) => {
            if (!prev.includes(salesForceListingId)) 
                return [...prev, salesForceListingId];

            return prev;
        });

        // Put in the map, so we know we are running a count
        setCountByCategory((prev) => {
            const newMap = new Map<string, FetchCall<GetCountByCategoryResult>>(prev);
            newMap.set(salesForceListingId, FetchCall.Initialize);
            return newMap;
        });

        try
        {
            const countResponse = await doCategoryCountPost({ urlParams: `?salesForceListingId=${salesForceListingId}`});
            if (countResponse.isError) {
                toast.error(`Error loading social media counts. See console for detailed error.`);
                const errorMessage = `Error loading social media counts for listing ${salesForceListingId}.`;
                console.error(errorMessage, countResponse);
                throw new Error(errorMessage);
            }
            
            setCountByCategory((prev) => {
                const newMap = new Map<string, FetchCall<GetCountByCategoryResult>>(prev);
                
                if (countResponse.hasData) {
                    const listingMap = countResponse.data?.categoryCounts;
                    if (!listingMap) {
                        throw new Error('No listing map returned from category count post');
                    }

                    // Convert the social category name to the description (apiName)
                    const newListingMap : ListingCategoryCountMap = {};
                    Object.entries(listingMap).forEach(([key, value]) => {
                        const socialName = SocialCategory[key as keyof typeof SocialCategory] as string;
                        newListingMap[socialName] = value;
                    });

                    const newCategoryCountData = FetchCall.Success(
                        { categoryCounts: newListingMap, webViewLink: countResponse?.data?.webViewLink ?? '' });
                    newMap.set(salesForceListingId, newCategoryCountData);
                } else {
                    newMap.set(salesForceListingId, FetchCall.Error(countResponse.errorMessage));
                }

                return newMap;
            });
        }
        catch(err)
        {
            // (*) TODO: Show it toast? (6/14/2023 - pel)
            console.error(err);
        }
        finally
        {
            // Remove from the list of listings we are counting
            setIsCountingList((prev) => prev.filter((id) => id !== salesForceListingId));
        }
    }, [doCategoryCountPost]);

    /** When selected, do we need data about the count of categories? */
    useEffect(() => {
        if (!idSelected) 
            return;

        getCategoryCountAsync(idSelected);
    }, [getCategoryCountAsync, idSelected]);

    useEffect(() => {
        if (!fileTransfers || fileTransfers.length === 0) 
            return;
        
        console.log('fileTransfers changed', fileTransfers);
        const allFiles = fileTransfers.filter((fileTransfer) => fileTransfer.isComplete);
        if (fileTransfers.length === allFiles.length) {
            console.log('all files complete');
        }
    }, [fileTransfers]);

    if (listings.isLoading) {
        return (
            <div>
                Loading...
            </div>
        );
    }

    if (listings.isError) {
        return (
            <div>
                Error!
            </div>
        );
    }

   

    // Get the selected listing object and it's index
    let listing: Listing | null = null;
    let listingIndex: number | null = null;
    if (listings.data) {
        listingIndex = listings.data.findIndex((e) => e.id === idSelected);
        listing = listings.data[listingIndex];
    }

    /** Selects the given listing */
    const selectListing = (id: string) => {
        if (!listings.data)
            return;
        
        const index = listings.data.findIndex((e) => e.id === id);
        if (index === -1) {
            return;
        }

        setIdSelected(id);
        listing = listings.data[index];
    };

    /**
    * listingRenderMap
    * @param list The listing to render
    * @param index The index of the listing to render in the array
    */
    const listingRenderMap = (item: Listing): JSX.Element | null => {
        if (search) {
            // If we have a search term, then do not display if does not match (case insensative)
            const searchUpper = search.toUpperCase();
            if (!item.name.toUpperCase().includes(searchUpper)) {
                return null;
            }
        }

        const isCounting =  countByCategory.has(item.id) ? isCountingList.findIndex((e) => e === item.id) >= 0 : undefined;
        const isUploading = isUploadingList.findIndex((e) => e === item.id) >= 0;
        const countByCategoryItem = countByCategory.get(item.id);
        return (
            <ListingUploadSocialItem
                key={item.id}
                //inputFileData={inputFileData}
                isUploading={isUploading}
                isCounting={isCounting}
                listing={item}
                isSelected={item.id === idSelected}
                selectListing={selectListing}
                socialMap={countByCategoryItem?.data?.categoryCounts}
                webViewLinkUrl={countByCategoryItem?.data?.webViewLink}
            />
        );
    };

    const listingsJsx = listings.data?.map(listingRenderMap);
    const filteredJsx = search ? (<span style={{ color: 'red' }}> (Filtered)</span>) : (<></>);
    const listingCountJsx: JSX.Element = (
        <>
            <br/>Engel & Völkers Active Listings (
            {(listings?.data?.length ?? 0) > 0 ? listings?.data?.length : ''}
            ):
            {filteredJsx}
        </>
    );

    const categoryChanged : ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = async (e) => {
        e.preventDefault();
        const category = Number.parseInt(e.target.value);
        console.log('category changed', category);
        setCategory(category);
    }

    const categorizeButtonClicked : MouseEventHandler<HTMLButtonElement> = async (e) => {
        e.preventDefault();
        var files = fileTransfers.map((fileTransfer, index) => { 
            const fileTarget : FileTarget = { name: fileTransfer.fileName, md5Checksum: fileTransfer.md5 };
            return fileTarget;
        });

        const categoryString = categories[category]?.name ?? '';
        if (!categoryString) {
            throw new Error(`Error categorizeButtonClicked: categoryString is not found: ${category}.`);
        }

        const categorizeRequest : DoFetchRequest<CategorizeRequest> = {
            body: {
            files: files,
            salesForceListingId: idSelected,
            category: categoryString
            },
        };

        try
        {
            setIsMoving(true);
            var response = await doCategorizePost(categorizeRequest);
            console.log('response from post', response);
        }
        finally
        {
            setIsMoving(false);
        }

        if (!response.isError) {
            if (!uploadRef.current) 
                throw new Error('Error categorizeButtonClicked: uploadRef.current is null.');

            // Clear the upload control of files (has to be done first, before you can clear the file area, or it will auto add the files back)
            uploadRef.current.clearFiles();
            
            // Then clear the file area
            clearFileTransfers();

            // Then initiate an update of the counts
            getCategoryCountAsync(idSelected);
        }
    }

    return (
        <>
        <Container fluid key="ut1">
            {//errorMessageRender
            }
                <Row>
                    <Col lg={5} md={4} sm={4} >
                        <Form name="search" style={{ display: 'flex', marginTop: '0.5em' }}>
                            <div style={{ marginLeft: '1.5em', marginTop: '0.33em', width: '10em' }}>
                                Address Search:
                            </div>
                            <Form.Control className="hasclear" type="search" placeholder="Enter address to search" value={search} onChange={(e) => setSearch(e.currentTarget.value)} autoFocus={true} />
                        </Form>
                        <p>
                            <b>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {listingCountJsx}
                            </b>
                        </p>
                        <div key="ut3">
                            <ul>
                                {listingsJsx}
                            </ul>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={6} style={{position: 'fixed', right: '10px', top: '6em'}} key="ut4">
   
    <UploadFile 
        ref={uploadRef}
        name="form" 
        multiple={true} 
        fileTypes={['pdf', 'png', 'jpg', 'jpeg']} 
        handleChange={handleChange} 
        maxStringLength={80}
        style={{ width: (windowDimensions.width - 50) / 2 }}
        />
    <br/>
    <Form>
        <Row>
            <Col sm={4}>
                Listing to Assign: 
            </Col>
            <Col>
                {listing?.name ?? '[PLEASE SELECT LISTING]'}
            </Col>
        </Row>
        <Form.Group controlId="formBasicCheckbox">
            <Row>
                <Col sm={4}>
                Category to Assign: 
                </Col>
                <Col sm={4}>
                <Form.Control as="select" style={{width: '14em'}} onChange={categoryChanged}>
                <option value="0">--- SELECT CATEGORY ---</option>
                {
                }
                {categories.map((listing) => {
                    return <option key={listing.index} value={listing.index}>{listing.name}</option>
                })}
            </Form.Control>

                </Col>
                <Col sm={4}>
                    <ButtonSpinner variant="danger" disabled={!isCategorizeButtonEnabled} type="submit" 
                        waitingIcon={isMoving}
                        onClick={(e) => { categorizeButtonClicked(e); }}>
                    Assign
                    </ButtonSpinner>
                </Col>
            </Row>
        </Form.Group>
    </Form>
    <br/>
    <canvas id="pdf" style={{display: 'none'}}></canvas>
    <Container fluid>
        <Row>
            {imageHolders}
        </Row>
    </Container>
    
                    </Col>
                </Row>
               
                {
                /*
                
                <ListingUploadForm
                    key={formKey}
                    listTypeDescription={listTypeName}
                    idSelected={listing ? listing.Id : null}
                    selectedItemName={listing ? listing.Name : null}
                    onUploadClick={onUploadClick}
                    isUploadingList={isUploadingList}
                    isUploadFileSelected={areAnyFilesSelected()}
                    files={inputFileData}
                    setFiles={setInputFileData}
                    shouldNotifyAdvisor={shouldNotifyAdvisor}
                    setShouldNotifyAdvisor={setShouldNotifyAdvisor}
                    setNewOrUpdate={setNewOrUpdate}
                    />

                    <ListingUploadMarketingOrder key={listingId} salesForceListingId={listing ? listing.Id : null}/>
                    */
                }
            </Container>
        </>
    );
};