import { baseUri, getBearerHeader } from "./fetch";
import { Result } from "./result";

export const getMembersByOffice = async (officeKey: string) : Promise<Result<Array<any>>> => 
{
    const requestInit = getBearerHeader();
    const response = await fetch(`${baseUri}api/MLS/mred/members/office/${officeKey}`, requestInit);
    if (!response.ok) {
        const errorMessage = `Unable to run /MLS/mred/members/office/${officeKey}, status code = ${response.statusText}`;
        return Result.Error(errorMessage);
    }

    const data : Result<Array<any>> = await response.json();
    return Result.Success(data);
}