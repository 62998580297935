import { Accordion, Col, Row, Spinner } from "react-bootstrap";

import { PropertyInvoiceDetail } from "../molecules/property-invoice-detail";
import { PropertyInvoiceCard } from "../molecules/property-invoice-card";
import { useListingsPage } from "../../hooks/use-listings-page";
import { FetchCall } from "@plazarre/phoenix.ux.fetch/lib/esm/core/domain/fetch-call";
import { PropertyDisplayResult } from "@plazarre/phoenix.javascript.approval";
import { ModalInvoiceComment } from "../molecules/invoices/modal-invoice-comment";
import { InvoiceBillDetails } from "../molecules/invoices/invoice-bill-details";
import { useContext } from "react";
import { AppContext, IEVApplicationContext } from "@plazarre/phoenix-ux-order/lib/esm/hook/use-application-context";

type Unarray<T> = T extends (infer U)[] ? U : T;

export class DoFetchRequestJsx<RESULT_TYPE> extends FetchCall<RESULT_TYPE> {
    private _appContext: IEVApplicationContext;

    constructor(fetchCall: FetchCall<RESULT_TYPE>, appContext: IEVApplicationContext) {
        super(fetchCall.loadStatus, fetchCall.data, fetchCall.errorMessage, fetchCall.fetchRequest);
        this._appContext = appContext;
    }

    mapJsx = <RESULT_TYPE, >(callbackfn: (value: Unarray<RESULT_TYPE>, index: number, array: RESULT_TYPE[]) => JSX.Element) : JSX.Element => {
        if (this.isError) {
            return <>{this.errorMessage}</>;
        }

        if (!this._appContext.isUserLoggedIn) {
            return <>Please log in to view this screen.</>;
        }

        if (this.isLoading) {
            return <Spinner size="sm" animation="border" />; 
        }

        if (!this.data) {
            console.error('DoFetchRequestJsx: data is null', this);
            return <>There was an unknown error</>;
        }

        if (!Array.isArray(this.data)) {
            console.error('DoFetchRequestJsx: return data is not an array', this);
            return <>Returned data is invalid.</>;
        }

        const jsx = Array.from(this.data).map(callbackfn);
        return <>{jsx}</>;
    }
}

interface IListingsPageProps {
}


export const ListingsPage : React.FC<IListingsPageProps> = (props) => {
    const appContext = useContext(AppContext);
    const { processedMessages, propertyInvoices, propertyInvoicesSetStateSetch,
            selected, setSelected, onClicks, modalDataState, propertyLinkRun, propertyDisplayMLS } = useListingsPage();
    
    const propertyDisplayMLSSetStateFetch = propertyDisplayMLS[1];

    const updateProperty = async (property: PropertyDisplayResult) => {
        const propertyLinkResult = await propertyLinkRun(property.listingKey, true);
        if (propertyLinkResult.isError) {
            console.error(`Error: unable to update property ${property.listingKey}: ${propertyLinkResult.errorMessage}`);
            return;
        }

        const displayResult = await propertyDisplayMLSSetStateFetch.fetch({ urlParams: property.listingKey });
        if (!displayResult.data) {
            console.error(`Error: unable to update property ${property.listingKey}: ${displayResult.errorMessage}`);
            return;
        }

        const data = displayResult.data;
        // update the property using propertyInvoicesSet
        propertyInvoicesSetStateSetch.set((prevData : FetchCall<PropertyDisplayResult[]>) => {
            if (!prevData.data)
                return prevData;
            

            // Insert the PropertyDisplayResult in the same order as it was
            // in the previous data.
            const index = prevData.data.findIndex(e => e.listingKey === property.listingKey);
            if (index === -1) {
                console.error(`Error: unable to find property ${property.listingKey} in previous data.`);
                return prevData;
            }


            const newProperties = [...prevData.data];
            newProperties[index] = data;
            return FetchCall.Success(newProperties);
        });
    };
    
    const mapDataToCard = (property: PropertyDisplayResult, index: number, properties: PropertyDisplayResult[]) => {
            const isSelected = selected === property.listingKey;
            
            // Is the MLS id in another listings MLSData?
            const isDuplicate = properties.find(e => e.listingKey !== property.listingKey 
                                             && e.mlsData.find(e => e === property.listingKey) != null) != null;

            console.log('property', isDuplicate, property);
            if (isDuplicate)
            {
                console.warn('duplicate - should not occur', property.listingKey);
                return <></>;
            }

            return <PropertyInvoiceCard 
                        property={property}
                        updateProperty={updateProperty} 
                        isSelected={isSelected} 
                        setSelected={setSelected}  
                        onClicks={onClicks}
                        />
    };

    const doFetchRequestJsx = new DoFetchRequestJsx(propertyInvoices, appContext);
    const propertiesJsx = doFetchRequestJsx.mapJsx(mapDataToCard);

    let selectedCardJsx = <>No property selected</>;
    const selectedProperty = propertyInvoices.data?.find(e => e.listingKey === selected);
    let onClickResultJsx = <></>;
    const onClickResult = processedMessages.find(e => e.mlsId === selected);
    if (onClickResult) {
        onClickResultJsx = <>{onClickResult.message}</>;
    }

    return (
        <Row style={{height: '100%'}}>
            {modalDataState && 
                <ModalInvoiceComment modalDataState={modalDataState}/>}
            <Col class="vh-100" lg={5} style={{ height: '91vh', overflowY: 'scroll', width: '40%'}}  >
                {propertiesJsx}
            </Col>
            <Col lg={7} style={{position: 'fixed', right: '1rem', marginLeft: '1rem', height: '91vh', overflowY: 'scroll'}}>
                <Accordion defaultActiveKey="0" flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Listing Information</Accordion.Header>
                        <Accordion.Body>
                            {selectedProperty && <PropertyInvoiceDetail selectedProperty={selectedProperty} />}
                            {!selectedProperty && selectedCardJsx}
                        </Accordion.Body>
                    </Accordion.Item>
                        
                    
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            Invoice / Bill Information
                        </Accordion.Header>
                        <Accordion.Body>
                            <InvoiceBillDetails selectedProperty={selectedProperty} />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            Processing Information
                        </Accordion.Header>
                        <Accordion.Body>
                            {onClickResultJsx}
                            {selectedProperty && selectedProperty.errors?.map((value, index) => { return <>{value}<br/></>})}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Col>
        </Row>
    );
}
